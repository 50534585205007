import React, { useState, useCallback, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Avatar,
  notification,
  Tooltip,
  Checkbox,
  Radio,
} from 'antd';
import { UserOutlined, MailOutlined, CheckOutlined } from '@ant-design/icons';
import { useDropzone } from 'react-dropzone';
import NoAvatar from '../../../assets/img/no-avatar.png';
import {
  updateUserApi,
  getSignatureApi,
  uploadSignatureApi,
} from '../../../api/user';
import { getAccessTokenApi } from '../../../api/auth';
import { ROLES } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import './EditUserForm.scss';
import { onUserDataRead } from '../../../actions';
import { getPrescriptionProvincesApi } from '../../../api/prescription';

export default function EditUserForm(props) {
  const { user, setIsVisibleModal, setReloadUsers } = props;
  const [signature, setSignature] = useState(null);
  const [userData, setUserData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [prescription, setPrescription] = useState(false);
  const [provinces, setProvinces] = useState([]);

  const { type } = useSelector((store) => store.userData?.user);
  const [userForm] = Form.useForm();

  useEffect(() => {
    setUserData({
      name: user.name,
      lastname: user.lastname,
      email: user.email,
      role: user.role,
      signature: user.signature,
    });
    setPrescription(user?.licenseType ? true : false);
  }, [user]);

  const dispatch = useDispatch();

  useEffect(() => {
    const getProvinces = async () => {
      if (prescription) {
        const token = await getAccessTokenApi();
        getPrescriptionProvincesApi(token).then((response) => {
          response.code === 200 && setProvinces(response.provinces);
        });
      }
    };

    getProvinces();
  }, [prescription]);

  useEffect(() => {
    userForm.resetFields();
  }, [user]);

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();
      if (user.signature) {
        getSignatureApi(user.signature, token).then((response) => {
          setSignature(response.url);
        });
      } else {
        setSignature(null);
      }
    };
    getData();
  }, [user]);

  useEffect(() => {
    if (signature) {
      setUserData({ ...userData, signature: signature.file });
    }
  }, [signature]);

  const updateUser = async (values) => {
    const token = await getAccessTokenApi();
    let signatureUpdate = userData;
    let userUpdate = values;
    values.medicalSpeciality =
      values.medicalSpeciality === undefined ? '' : values.medicalSpeciality;

    if (typeof signatureUpdate.signature === 'object') {
      uploadSignatureApi(token, signatureUpdate.signature, user._id).then(
        (response) => {
          signatureUpdate.signature = response.signatureName;
          updateUserApi(token, userUpdate, user._id).then((result) => {
            notification['success']({ message: result.message });
            setIsVisibleModal(false);
            setReloadUsers(true);
          });
        }
      );
    } else {
      updateUserApi(token, userUpdate, user._id).then((result) => {
        if (result?.code === 200) {
          dispatch(onUserDataRead());

          notification['success']({ message: result.message });
          setReloadUsers(true);
          setIsVisibleModal(false);
        } else {
          notification['error']({ message: result.message });
        }
      });
    }
  };

  return (
    <div className="edit-user-form">
      <UploadSignature signature={signature} setSignature={setSignature} />
      <EditForm
        user={user}
        updateUser={updateUser}
        userForm={userForm}
        type={type}
        refresh={refresh}
        setRefresh={setRefresh}
        prescription={prescription}
        setPrescription={setPrescription}
        provinces={provinces}
      />
    </div>
  );
}

function UploadSignature(props) {
  const { signature, setSignature } = props;
  const [signatureUrl, setSignatureUrl] = useState(null);

  useEffect(() => {
    if (signature) {
      if (signature.preview) {
        setSignatureUrl(signature.preview);
      } else {
        setSignatureUrl(signature);
      }
    } else {
      setSignatureUrl(null);
    }
  }, [signature]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setSignature({ file, preview: URL.createObjectURL(file) });
    },
    [setSignature]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png',
    noKeyboard: true,
    disabled: true,
    onDrop,
  });

  return (
    <>
      <div style={{ textAlign: 'center', marginBottom: '1%' }}>
        <span>Firma Digital</span>{' '}
      </div>
      <div className="upload-signature" {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Avatar shape="square" size={150} src={NoAvatar} />
        ) : (
          <Avatar
            shape="square"
            size={150}
            src={signatureUrl ? signatureUrl : NoAvatar}
          />
        )}
      </div>
    </>
  );
}

function EditForm(props) {
  const {
    user,
    updateUser,
    userForm,
    type,
    refresh,
    setRefresh,
    prescription,
    setPrescription,
    provinces,
  } = props;
  const { Option } = Select;
  const {
    organizationType,
    medicalSpecialities,
    studies,
    billingOrganizations,
    healthCenterIdRecetario,
    cashRegisters,
  } = useSelector((store) => store.tenantData);

  const options = [
    {
      value: 'Usuarios',
      label: 'Usuarios',
      disabled:
        user?.type === 'Enfermero' && userForm.getFieldsValue().role !== -1
          ? true
          : false,
    },
    {
      value: 'Farmacia',
      label: 'Farmacia',
      disabled:
        user?.type === 'Enfermero' && userForm.getFieldsValue().role !== -1
          ? true
          : false,
    },
  ];

  organizationType === 'hospitalaria' &&
    user?.type !== 'Enfermero' &&
    options.push({
      value: 'Control Internación',
      label: 'Control Internación',
      disabled:
        user?.type === 'Administrativo' || userForm.getFieldsValue().role === 2
          ? false
          : true,
    });

  user?.type === 'Administrativo' &&
    options.push({
      label: 'Ver h.c amb',
      value: 'Ver h.c amb',
      disabled: user?.type === 'Enfermero',
    });

  user?.type !== 'Enfermero' &&
    options.push(
      {
        value: 'Facturacion amb',
        label: 'Facturación amb',
        disabled: user?.type === 'Enfermero' ? true : false,
      },
      {
        value: 'Estadisticas',
        label: 'Estadisticas',
        disabled: user?.type === 'Enfermero' ? true : false,
      }
    );

  return (
    <Form
      form={userForm}
      className="form-edit"
      onFinish={updateUser}
      labelCol={{ sm: 8, lg: 10, xl: 8, xxl: 6 }}
      wrapperCol={{ sm: 16, lg: 14, xl: 16, xxl: 18 }}
    >
      <Row gutter={24}>
        <Col xs={24} lg={12}>
          <Form.Item
            name="name"
            initialValue={user.name}
            rules={[{ required: true, message: 'Debe cargar un nombre' }]}
            label="Nombre"
          >
            <Input prefix={<UserOutlined />} placeholder="Nombre" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name="lastname"
            initialValue={user.lastname}
            rules={[{ required: true, message: 'Debe cargar un apellido' }]}
            label="Apellido"
          >
            <Input prefix={<UserOutlined />} placeholder="Apelldo" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={12}>
          <Form.Item
            name="email"
            initialValue={user.email}
            rules={[{ required: true, message: 'Debe cargar un email' }]}
            label="Email"
            disabled={true}
          >
            <Input prefix={<MailOutlined />} placeholder="Correo electronico" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name="medicalSpeciality"
            initialValue={user.medicalSpeciality}
            label="Especialidad"
            rules={[
              {
                required:
                  prescription && user?.type === 'Medico' ? true : false,
                message: 'Debe cargar el nro de matricula',
              },
            ]}
          >
            <Select
              placeholder="Selecciona una especialidad"
              allowClear={true}
              showSearch
              optionFilterProp="children"
              disabled={user?.type !== 'Medico'}
            >
              {medicalSpecialities?.map((speciality) => (
                <Select.Option value={speciality}>{speciality}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name="mp"
            initialValue={user?.mp}
            label="Matrícula"
            rules={[
              {
                required:
                  prescription && user?.type === 'Medico' ? true : false,
                message: 'Debe cargar el nro de matricula',
              },
            ]}
          >
            <Input
              type="number"
              disabled={user?.type !== 'Medico'}
              placeholder="Matrícula"
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name="role"
            initialValue={user.role}
            rules={[{ required: true, message: 'Debe seleccionar un rol' }]}
            label="Perfil / Permisos"
          >
            <Select
              placeholder="Selecciona un rol"
              onChange={() => setRefresh(!refresh)}
            >
              {user.type === 'Medico' ? (
                <>
                  <Option
                    value={ROLES.OWNER.value}
                    disabled={type === 'Enfermero'}
                  >
                    {ROLES.OWNER.name}
                  </Option>
                  {organizationType === 'hospitalaria' && (
                    <>
                      <Option value={ROLES.ADMIN.value}>
                        {ROLES.ADMIN.name}
                      </Option>
                      <Option value={ROLES.EMPLOYEE.value}>
                        {ROLES.EMPLOYEE.name}
                      </Option>
                    </>
                  )}
                  <Option value={ROLES.VIEW.value}>{ROLES.VIEW.name}</Option>
                </>
              ) : user.type === 'Enfermero' ? (
                <>
                  <Option
                    disabled={type === 'Enfermero'}
                    value={ROLES.ADMININFARMARY.value}
                  >
                    {ROLES.ADMININFARMARY.name}
                  </Option>
                  <Option value={ROLES.INFARMARY.value}>
                    {ROLES.INFARMARY.name}
                  </Option>
                </>
              ) : (
                <>
                  <Option
                    value={ROLES.OWNER.value}
                    disabled={type === 'Enfermero'}
                  >
                    {ROLES.OWNER.name}
                  </Option>
                  <Option value={ROLES.SECRETARY.value}>
                    {ROLES.SECRETARY.name}
                  </Option>
                </>
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name="modulesAccess"
            initialValue={user?.modulesAccess}
            label="Acceso a modulos"
            help={
              (userForm.getFieldsValue().role === 2 &&
                'Acceso completo ya incluye todos estos modulos') ||
              (user?.type === 'Medico' &&
                'Los medicos ven las estadisticas de su propia turnera y estudios que tengan acceso. Si se le quiere dar acceso a todas las estadisticas seleccionar aqui')
            }
          >
            <Checkbox.Group
              disabled={userForm.getFieldsValue().role === 2}
              options={options}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name="viewStudies"
            initialValue={user.viewStudies}
            label="Acceso a estudios"
            help={
              userForm.getFieldsValue().role === 2
                ? 'Acceso completo ya incluye todos estos modulos'
                : 'Este usuario va a poder ver la turnera que aqui se indiquen'
            }
          >
            <Select
              disabled={
                user?.type === 'Enfermero' ||
                userForm.getFieldsValue().role === 2
              }
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccione estudios"
              optionFilterProp="children"
              allowClear
              mode="multiple"
              notFoundContent="No tiene estudios."
            >
              {studies?.map((studie, index) => (
                <Select.Option key={index} value={studie.id}>
                  {studie.name}{' '}
                  {studie?.view && (
                    <Tooltip title="Este turnero es visible por todos los usuarios">
                      <CheckOutlined style={{ color: 'green' }} />
                    </Tooltip>
                  )}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name="viewBillingOrganizations"
            initialValue={user.viewBillingOrganizations}
            label="Prestadores / Cajas"
            tooltip="Acceso a cajas o prestadores."
          >
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccione prestadores o cajas"
              optionFilterProp="children"
              allowClear
              mode="multiple"
              notFoundContent="No tiene este modulo habilitado."
            >
              {billingOrganizations?.map((bo, index) => (
                <Select.Option key={index} value={bo}>
                  {bo}
                </Select.Option>
              ))}
              {cashRegisters?.map((cr, index) => (
                <Select.Option key={index} value={cr}>
                  {cr}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {user?.type === 'Medico' && (
        <Row gutter={24}>
          <Col xs={24} style={{ textAlign: 'center' }}>
            <Tooltip title="Si desea obtener receta electronica contactese a nuestro nro">
              <Button
                type="link"
                onClick={() => setPrescription(!prescription)}
                disabled={!healthCenterIdRecetario}
              >
                {prescription
                  ? 'Deshacer receta electronica'
                  : 'Obtener receta electronica'}
              </Button>
            </Tooltip>
          </Col>
        </Row>
      )}
      {prescription && user?.type === 'Medico' && (
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <Form.Item
              name="licenseType"
              initialValue={user.licenseType}
              rules={[
                { required: true, message: 'Debe cargar tipo de licencia' },
              ]}
              label="Tipo de licencia"
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Seleccione tipo de licencia"
                optionFilterProp="children"
                allowClear
              >
                <Select.Option key={1} value={'nacional'}>
                  Nacional
                </Select.Option>
                <Select.Option key={2} value={'provincial'}>
                  Provincial
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="province"
              initialValue={user.province}
              rules={[{ required: true, message: 'Debe elegir la provincia' }]}
              label="Provincia"
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Seleccione provincia"
                optionFilterProp="children"
                allowClear
              >
                {provinces.map((province) => (
                  <Select.Option key={province.id} value={province.name}>
                    {province.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="documentNumber"
              initialValue={user.documentNumber}
              rules={[{ required: true, message: 'Debe elegir el dni' }]}
              label="DNI"
            >
              <Input placeholder="DNI" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="title"
              initialValue={user.title}
              rules={[{ required: true, message: 'Debe elegir la provincia' }]}
              label="Titulo"
            >
              <Radio.Group>
                <Radio value="Dr">Dr</Radio>
                <Radio value="Dra">Dra</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      )}

      <Form.Item>
        <Button type="default" htmlType="submit" className="button-submit">
          Actualzar Usuario
        </Button>
      </Form.Item>
    </Form>
  );
}
