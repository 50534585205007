import {
  ON_FLOORS_READ,
  ON_FLOORS_READING,
  ON_PRIMARY_READING,
  ON_PRIMARY_READ,
} from './types';
import { getFloors, getPrimaryData } from '../api/tenantConfiguration';
import { getAccessTokenApi } from '../api/auth';
import { notification } from 'antd';

export const onFloorsRead = () => async (dispatch) => {
  dispatch({ type: ON_FLOORS_READING });
  const token = await getAccessTokenApi();
  return await getFloors(token)
    .then((response) => {
      if (response.error) {
      }
      dispatch({
        type: ON_FLOORS_READ,
        payload: {
          floors: response.tenantData?.floors,
          organization: response.tenantData?.name,
          tenant: response.tenantData?.tenant,
          logos: response.tenantData?.logos,
          studies: response.tenantData?.studies,
          organizationType: response.tenantData?.organizationType,
          down: response.tenantData?.down,
          tenantType: response.tenantData?.tenantType,
          tenantId: response.tenantData?.tenantId,
          drugStores: response.tenantData?.drugStores,
          tenantLogo: response.tenantData?.logo,
          organizationPhone: response.tenantData?.phone,
          reportTypes: response.tenantData?.reportTypes,
          quantitySchedules: response.tenantData?.quantitySchedules,
          billingOrganizations: response.tenantData?.billingOrganizations,
          cashRegisters: response.tenantData?.cashRegisters,
          healthCenterId: response.tenantData?.healthCenterId,
          tenantTimezone: response.tenantData?.timezone,
          countryCode: response.tenantData?.countryCode,
          cardTypes: response.tenantData?.cardTypes,
        },
      });
    })
    .catch(() => notification['info']({ message: 'Error' }));
};

export const onPrimaryDataRead = () => async (dispatch) => {
  dispatch({ type: ON_PRIMARY_READING });
  return await getPrimaryData()
    .then((response) => {
      if (response.error) {
      }
      dispatch({
        type: ON_PRIMARY_READ,
        payload: {
          organization: response.tenantData?.name,
          tenant: response.tenantData?.tenant,
          logos: response.tenantData?.logos,
          organizationType: response.tenantData?.organizationType,
          medicalSpecialities: response.tenantData?.medicalSpecialities,
          down: response.tenantData?.down,
          multitenant: response.tenantData?.multitenant,
          tenantTimezone: response.tenantData?.timezone,
          countryCode: response.tenantData?.countryCode,
        },
      });
    })
    .catch(() => notification['info']({ message: 'Error' }));
};
