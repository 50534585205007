import React, { useEffect } from 'react';
import { Form, Row, Col, Input, Button, notification, Radio } from 'antd';
import { useSelector } from 'react-redux';
import { addTemplateApi, updateTemplateApi } from '../../api/template';
import { getAccessTokenApi } from '../../api/auth';

export default function TemplateForm(props) {
  const { setIsVisibleModal, getTemplate, data } = props;
  const [templateForm] = Form.useForm();
  const { organizationType } = useSelector((store) => store.tenantData);

  useEffect(() => {
    templateForm.resetFields();
    templateForm.setFieldsValue(data);
  }, [data]);

  const onFinish = async (values) => {
    let token = await getAccessTokenApi();
    data
      ? updateTemplateApi(data?._id, values, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              getTemplate();
              setIsVisibleModal(false);
              templateForm.resetFields();
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          })
      : addTemplateApi(token, values)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              getTemplate();
              setIsVisibleModal(false);
              templateForm.resetFields();
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
  };

  return (
    <div>
      <Form form={templateForm} onFinish={onFinish}>
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Debe elegir tipo de template.',
                },
              ]}
              labelCol={{ span: 24 }}
              initialValue={'Ambulatorio'}
            >
              <Radio.Group>
                <Radio value={'Ambulatorio'}>Consulta Ambulatoria</Radio>
                {organizationType !== 'ambulatoria' && (
                  <Radio value={'Evolucion'}>Evolución Internado</Radio>
                )}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Debe dar un nombre.' }]}
            >
              <Input placeholder="Nombre (*)" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="description"
              rules={[
                { required: true, message: 'Debe poner una descripción.' },
              ]}
            >
              <Input.TextArea
                autoSize={{ minRows: 3 }}
                placeholder="Descripción template.."
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
