import React, { useEffect, useState } from 'react';
import { getNomenclatorBySocialWork, getNomenclators } from '../../api/billing';
import { getAccessTokenApi } from '../../api/auth';
import Spin from '../../components/Spin';
import { Button, List, message, Table } from 'antd';
import BackButton from '../../components/BackButton';
import { InfoCircleOutlined } from '@ant-design/icons';
import { render } from '@testing-library/react';
import Modal from '../../components/Modal';
// import '../Phones.scss';

export default function Nomenclator() {
  const [nomenclators, setNomenclators] = useState([]);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDataNomenclator = async () => {
      const token = await getAccessTokenApi();

      getNomenclators(token)
        .then((response) => {
          if (response?.code === 200) {
            setNomenclators(response.nomenclators);
          } else {
            message.warning(response.message);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false); // Deshabilitamos loading en caso de error
        });
    };

    getDataNomenclator();
  }, []);

  if (loading) {
    return <Spin />;
  }
  const viewDetail = async (nomenclator) => {
    try {
      const token = await getAccessTokenApi();

      const response = await getNomenclatorBySocialWork({ token, nomenclator });

      if (response?.code === 200) {
        const columns = [
          {
            title: 'Código',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
          },
          {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Precio',
            dataIndex: 'price',
            key: 'price',
            align: 'center',
            render: (price) => (
              <span>
                {price?.toLocaleString('es-AR', {
                  style: 'currency',
                  currency: 'ARS',
                  minimumFractionDigits: 2,
                })}
              </span>
            ),
          },
        ];

        setIsVisibleModal(true);
        setModalTitle(`Detalle ${nomenclator}`);
        setModalContent(
          <div>
            <h3>Cantidad de codigos: {response?.nomenclators.length}</h3>
            <Table
              dataSource={response?.nomenclators}
              columns={columns}
              pagination={{ pageSize: 8, responsive: true, size: 'small' }}
            />
          </div>
        );
      } else {
        return message.error('No hay datos.');
      }
    } catch {
      return message.error('No hay datos.');
    }
  };

  return (
    <div className="phones">
      <div>
        <BackButton />
        <span>Nomencladores Existentes</span>
      </div>
      <List
        className="incomes-list"
        dataSource={nomenclators}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button type="link" onClick={() => viewDetail(item._id)}>
                <InfoCircleOutlined />
              </Button>,
            ]}
          >
            {item._id} {/* Aquí mostramos el nombre del nomenclador */}
          </List.Item>
        )}
        locale={{ emptyText: 'No tiene cargado nomencladores..' }}
      />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width="80%"
      >
        {modalContent}
      </Modal>
    </div>
  );
}
