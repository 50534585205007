import React, { useState } from 'react';
import {
  MoreOutlined,
  DeleteOutlined,
  PlusOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  LoadingOutlined,
  ScheduleOutlined,
  RetweetOutlined,
  EditOutlined,
  InfoCircleOutlined,
  UserAddOutlined,
  CloseCircleOutlined,
  IssuesCloseOutlined,
  StopOutlined,
  CheckOutlined,
  MailOutlined,
  WhatsAppOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import {
  List,
  Dropdown,
  Button,
  Menu,
  Tag,
  Badge,
  Tooltip,
  notification,
  Row,
  Col,
  DatePicker,
  Input,
  Form,
  Divider,
  Anchor,
  message,
} from 'antd';
import moment from 'moment-timezone';
import locale from 'antd/es/date-picker/locale/es_ES';
import './ListTurns.scss';
import Modal from '../../Modal';
import TurnForm from '../TurnForm';
import { useDispatch, useSelector } from 'react-redux';
import { onPatientValueChange } from '../../../actions';
import { getAccessTokenApi } from '../../../api/auth';
import {
  addTurnApi,
  changeScheduleDayApi,
  deleteTurnApi,
  moveTurnApi,
  updateStatusTurnApi,
  updateTurnApi,
} from '../../../api/turn';
import { ROLES } from '../../../utils/constants';
import PatientForm from '../PatientForm/PatientForm';
import TimeSince from '../../Chronometer/TimeSince';
import NewPayment from '../../CashRegister/NewPayment';

export default function ListTurns({
  slots,
  turnUser,
  turns,
  setReload,
  turnId,
  size,
  turnType,
  loading,
  scheduleConfig,
  date,
  scheduleDay,
}) {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);

  const { role, viewBillingOrganizations } = useSelector(
    (store) => store.userData?.user
  );

  const { cashRegisters } = useSelector((store) => store.tenantData);

  const userId = useSelector((store) => store.userData?.user?._id);

  const [deleteForm] = Form.useForm();
  const [changeSchedule] = Form.useForm();

  const dispatch = useDispatch();

  const { tenantTimezone } = useSelector((store) => store.tenantData);

  const addTurn = (turn) => {
    dispatch(onPatientValueChange({ searchPatient: '' }));
    setIsVisibleModal(true);
    setModalTitle(
      `Nuevo Turno ${moment
        .tz(turn?.hourStart, tenantTimezone)
        .format('HH mm')} hs`
    );
    setModalContent(
      <TurnForm
        turn={turn}
        turnId={turnId}
        turnUser={turnUser}
        setReload={setReload}
        setIsVisibleModal={setIsVisibleModal}
        size={size}
        turnType={turnType}
        date={date}
        scheduleConfig={turns?.length > 0 ? false : scheduleConfig}
        percentTurn={scheduleConfig?.percent}
        services={scheduleConfig?.service}
      />
    );
  };

  async function excludeSlot(slot) {
    const token = await getAccessTokenApi();

    turnId
      ? updateTurnApi({
          token,
          turnId,
          dataTurn: {
            hourStart: slot?.hourStart,
            exclude: true,
          },
        })
          .then((response) => {
            if (response?.code === 500) {
              message.error(response.message);
            } else if (response?.code == 404) {
              message.warning(response.message);
              setReload(true);
            } else {
              setReload(true);
              message.success(response.message);
            }
          })
          .catch((err) =>
            notification['error']({ message: 'Error del servidor' })
          )
      : addTurnApi({
          token,
          data: {
            date: date,
            user: turnUser,
            sizeSlot: size,
            dataTurn: {
              hourStart: slot?.hourStart,
              exclude: true,
            },
          },
        })
          .then((response) => {
            if (response?.code === 500) {
              message.error(response.message);
            } else if (response?.code == 404) {
              message.warning(response.message);
              setReload(true);
            } else {
              setReload(true);
              message.success(response.message);
            }
          })
          .catch((err) =>
            notification['error']({ message: 'Error del servidor' })
          );
  }

  async function getMinMaxForDay(config, day, dateMove) {
    // Filtrar los horarios que incluyen el día deseado

    if (
      config?.excludeDays?.filter(
        (e) =>
          moment.tz(e, tenantTimezone).startOf('day').toISOString() ===
          moment.tz(dateMove, tenantTimezone).startOf('day').toISOString()
      ).length === 0
    ) {
      const filteredHours = config?.hours.filter((schedule) =>
        schedule.days.includes(day)
      );

      if (filteredHours.length === 0) {
        // Si no se encuentran horarios para ese día, retornar null o un valor predeterminado
        return null;
      }

      // Obtener el valor más temprano de "start"
      const minStart = filteredHours.reduce((min, current) => {
        return new Date(current.start) < new Date(min.start) ? current : min;
      }).start;

      // Obtener el valor más tardío de "end"
      const maxEnd = filteredHours.reduce((max, current) => {
        return new Date(current.end) > new Date(max.end) ? current : max;
      }).end;

      return {
        minStart: moment(minStart).format('H:mm'),
        maxEnd: moment(maxEnd).format('H:mm'),
      };
    } else return false;
  }

  const moveTurn = (turn) => {
    let date1 = moment(turn?.hourStart);
    let hourStart1;
    dispatch(onPatientValueChange({ searchPatient: '' }));
    setIsVisibleModal(true);
    setModalTitle(
      `Mover Turno de las ${date1.tz(tenantTimezone).format('HH mm')} hs`
    );
    setModalContent(
      <>
        <span>
          Por favor, chequear que el horario al que movamos el turno sea valido
        </span>
        <Row gutter={24} style={{ marginBottom: 5 }}>
          <Col xs={12} md={12} lg={12}>
            <DatePicker
              inputReadOnly
              placeholder="Seleccione fecha de la indicación (*)"
              style={{ width: '100%' }}
              format="DD-MM-YYYY HH:mm"
              showToday={false}
              locale={locale}
              showTime
              minuteStep={5}
              defaultValue={moment.tz(date1, tenantTimezone)}
              onChange={(value) => {
                date1 = moment(value).startOf('day').toISOString();
                hourStart1 = moment(value)
                  .seconds(0)
                  .milliseconds(0)
                  .toISOString();
              }}
            />
          </Col>
          <Col xs={12} md={12} lg={12}>
            <Button
              type="default"
              style={{ width: '100%' }}
              onClick={async () => {
                message.loading('Moviendo el turno..', 0);

                let hourMove = moment(hourStart1).format('H:mm');

                let token = await getAccessTokenApi();

                const result = await getMinMaxForDay(
                  scheduleConfig,
                  moment(hourStart1).format('dd'),
                  hourStart1
                );
                if (result) {
                  let startHour = parseInt(result.minStart.split(':')[0]);
                  let startMin = parseInt(result.minStart.split(':')[1]);
                  let endHour = parseInt(result.maxEnd.split(':')[0]);
                  let endMin = parseInt(result.maxEnd.split(':')[1]);
                  let moveHour = parseInt(hourMove.split(':')[0]);
                  let moveMin = parseInt(hourMove.split(':')[1]);

                  if (
                    (moveHour > startHour && moveHour < endHour - 1) || // Entre horas
                    (moveHour === startHour && moveMin >= startMin) || // Inicio del rango
                    (moveHour === endHour - 1 && moveMin <= endMin) // Fin del rango
                  ) {
                    moveTurnApi({
                      token,
                      turnId,
                      shiftId: turn._id,
                      dataTurn: {
                        hourStart: hourStart1,
                        patient: turn?.patient._id,
                        socialWork: turn?.patient?.socialWork,
                        doctorApplicant: turn?.doctorApplicant,
                        doctorDerivate: turn?.doctorDerivate,
                        studies: turn?.studies,
                        description: turn?.description,
                        portal: turn?.portal,
                        user: turnUser,
                        sizeSlot: size,
                        date: date1,
                        turnType: turnType,
                      },
                    })
                      .then((response) => {
                        if (response?.code !== 200) {
                          message.destroy();

                          notification['error']({ message: response.message });
                        } else {
                          message.destroy();

                          notification['success']({
                            message: response.message,
                          });
                          setReload(true);
                          setIsVisibleModal(false);
                        }
                      })
                      .catch((err) => {
                        message.destroy();

                        notification['error']({
                          message: 'Error del servidor',
                        });
                      });
                  } else {
                    message.destroy();

                    notification['info']({
                      message:
                        'El horario seleccionado no coincide con la agenda',
                    });
                  }
                } else {
                  message.destroy();

                  notification['info']({
                    message: 'El dia seleccionado esta exluido o no trabaja',
                  });
                }
              }}
            >
              Mover
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  const editTurn = (turn) => {
    dispatch(onPatientValueChange({ searchPatient: turn.patient.dni }));
    setIsVisibleModal(true);
    setModalTitle(
      `Editar Turno de las ${moment
        .tz(turn?.hourStart, tenantTimezone)
        .format('HH mm')} hs`
    );
    setModalContent(
      <TurnForm
        turn={turn}
        turnId={turnId}
        turnUser={turnUser}
        setReload={setReload}
        setIsVisibleModal={setIsVisibleModal}
        size={size}
        turnType={turnType}
        edit={true}
        percentTurn={scheduleConfig?.percent}
        services={scheduleConfig?.service}
      />
    );
  };

  const addPaidTurn = (turn) => {
    const commonValues = viewBillingOrganizations?.filter((value) =>
      cashRegisters?.includes(value)
    );

    setIsVisibleModal(true);
    setModalTitle(`Cobrar Turno de ${turn?.patient?.name}`);
    setModalContent(
      commonValues?.length > 0 ? (
        <NewPayment
          turn={turn}
          turnId={turnId}
          shiftId={turn._id}
          setIsVisibleModal={setIsVisibleModal}
          setModalTitle={setModalTitle}
          setModalContent={setModalContent}
          setReload={setReload}
          turnUser={turnUser}
        />
      ) : (
        <span>No posee permisos..</span>
      )
    );
  };

  const viewPatient = (turn) => {
    dispatch(onPatientValueChange({ searchPatient: turn?.patient.dni }));
    setIsVisibleModal(true);
    setModalTitle(
      `Turno de las ${moment
        .tz(turn?.hourStart, tenantTimezone)
        .format('HH:mm')} hs`
    );
    setModalContent(
      <TurnForm turn={turn} turnId={turnId} turnType={turnType} type={'view'} />
    );
  };

  const addPatient = (turn) => {
    setIsVisibleModal(true);
    setModalTitle('Nuevo Paciente');
    setModalContent(
      <PatientForm
        patient={turn}
        turnId={turnId}
        setIsVisibleModal={setIsVisibleModal}
        setReload={setReload}
      />
    );
  };

  const updateStatus = async (shiftId, status, important = '') => {
    const token = await getAccessTokenApi();

    updateStatusTurnApi({
      token,
      turnId,
      shiftId,
      status,
      important,
    })
      .then((response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          setReload(true);
          setIsVisibleModal(false);
        }
      })
      .catch((err) => notification['error']({ message: 'Error del servidor' }));
  };

  const deleteTurn = (shiftId, email, turn, exclude = false) => {
    let confirmDelete = async (notification) => {
      setIsVisibleModal(false);
      message.loading(
        exclude ? 'Habilitando horario..' : 'Cancelando el turno..',
        0
      );
      let { infoDelete } = deleteForm.getFieldsValue();
      const token = await getAccessTokenApi();

      let textUrlEncode = `Hola%20${
        turn?.patient?.name || turn?.name
      }%20te%20informamos%20que%20tu%20turno%20para%20el%20${moment(
        turn.hourStart
      ).format('DD/MM HH:mm')}hs%20FUE%20CANCELADO%0AMotivo%3A%20${
        infoDelete || ''
      }`;

      let number =
        notification == 'msj' && turn?.patient
          ? `${turn?.patient?.notificationPhone?.countryCode}${turn?.patient?.notificationPhone?.regionCode}${turn?.patient?.notificationPhone?.number}`
          : `${turn?.notificationPhone?.countryCode}${turn?.notificationPhone?.regionCode}${turn?.notificationPhone?.number}`;

      deleteTurnApi({
        token,
        turnId,
        shiftId,
        infoDelete,
        email: notification === 'email' ? email : undefined,
        exclude,
      })
        .then((response) => {
          if (response?.code !== 200) {
            message.destroy();
            message.error(response.message);
          } else {
            message.destroy();
            message.success(response.message);

            setReload(true);

            // Enviar el mensaje por WhatsApp automáticamente al abrir el enlace
            if (notification === 'msj' && number) {
              window.open(
                `https://wa.me/${number}?text=${textUrlEncode}`,
                '_blank'
              );
            }
          }
        })
        .catch((err) => message.error('Error `d`el servidor'))
        .finally(() => {});
    };

    if (exclude) {
      confirmDelete(false);
    } else {
      deleteForm.resetFields();
      setIsVisibleModal(true);
      setModalTitle(
        `Cancelar Turno de las ${moment
          .tz(turn?.hourStart, tenantTimezone)
          .format('HH:mm')} hs`
      );
      setModalContent(
        <Form form={deleteForm}>
          <Form.Item
            name="infoDelete"
            label={
              <span>
                <InfoCircleOutlined /> (Opcional) Escriba un mensaje para
                incluir en la notificación al paciente que su turno fue
                cancelado
              </span>
            }
            labelCol={{ span: 24 }}
          >
            <Input.TextArea
              maxLength={200}
              placeholder="Ej: Su turno fue cancelado por una urgencia del doctor, disculpe las molestias. Puede solicitar otro turno"
            />
          </Form.Item>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={8}>
              <Form.Item>
                <Button
                  type="dashed"
                  style={{
                    width: '100%',
                    borderRadius: '10px',
                    borderColor: 'red',
                  }}
                  onClick={() => confirmDelete(false)}
                >
                  Cancelar Turno
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item>
                <Tooltip
                  title={
                    (turn?.patient
                      ? !turn?.patient?.notificationPhone?.number
                      : !turn?.notificationPhone?.number) &&
                    'No tiene nro registrado'
                  }
                >
                  <Button
                    type="danger"
                    style={{
                      width: '100%',
                      borderRadius: '10px',
                      color: '#fff',
                    }}
                    onClick={() => confirmDelete('msj')}
                    disabled={
                      turn?.patient
                        ? !turn?.patient?.notificationPhone?.number
                        : !turn?.notificationPhone?.number
                    }
                  >
                    Cancelar Turno <WhatsAppOutlined />
                  </Button>
                </Tooltip>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item>
                <Tooltip title={!email && 'No tiene email registrado'}>
                  <Button
                    type="danger"
                    style={{
                      width: '100%',
                      borderRadius: '10px',
                      color: '#fff',
                    }}
                    onClick={() => confirmDelete('email')}
                    disabled={!email}
                  >
                    Cancelar Turno <MailOutlined />
                  </Button>
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      );
    }
  };

  const changeScheduleInDay = async () => {
    changeSchedule.resetFields();
    changeSchedule.setFieldsValue({
      start1: moment.tz(scheduleDay?.first[0], tenantTimezone),
      end1: moment.tz(scheduleDay?.first[1], tenantTimezone),
      start2:
        scheduleDay?.second &&
        moment.tz(scheduleDay?.second[0], tenantTimezone),
      end2:
        scheduleDay?.second &&
        moment.tz(scheduleDay?.second[1], tenantTimezone),
    });
    const minutesOfDay = (m) => {
      return m.minutes() + m.hours() * 60;
    };

    const onFinish = async (values) => {
      const token = await getAccessTokenApi();

      let second = await slots.find((slot) => slot.second);

      let firstTurn = turns.filter(
        (turn) =>
          (second
            ? minutesOfDay(moment(turn.hourStart)) <
              minutesOfDay(moment(second.hourStart))
            : true) && !turn?.exclude
      );

      let secondTurn =
        second &&
        turns.filter(
          (turn) =>
            minutesOfDay(moment(turn.hourStart)) >=
              minutesOfDay(moment(second.hourStart)) && !turn?.exclude
        );

      if (
        minutesOfDay(moment(firstTurn[0]?.hourStart || values?.start1)) >=
          minutesOfDay(values?.start1) &&
        (minutesOfDay(
          moment(firstTurn[firstTurn.length - 1]?.hourStart || values?.end1)
        ) <=
          minutesOfDay(values?.end1)) &
          (secondTurn?.length > 0
            ? minutesOfDay(moment(secondTurn[0]?.hourStart)) >=
                minutesOfDay(values?.start2) &&
              minutesOfDay(
                moment(secondTurn[secondTurn.length - 1]?.hourStart)
              ) <= minutesOfDay(values?.end2)
            : true)
      ) {
        values.day = moment(date).format('dd');

        changeScheduleDayApi({ token, turnId, scheduleDay: values })
          .then((response) => {
            if (response?.code !== 200) {
              message.error(response.message);
            } else {
              message.success(response.message);
              setReload(true);
              setIsVisibleModal(false);
            }
          })
          .catch((err) =>
            notification['error']({ message: 'Error del servidor' })
          );
      } else {
        message.info(
          'Revisar los turnos dados que no esten por fuera de los nuevos horarios'
        );
      }
    };

    setIsVisibleModal(true);
    setModalTitle(`Editar agenda para el ${moment(date).format('DD/MM')}`);
    setModalContent(
      <Form form={changeSchedule} onFinish={onFinish}>
        <Row gutter={[24, 24]}>
          <Col xs={4} md={8}>
            <span>1er Horario</span>
          </Col>
          <Col xs={8} md={8}>
            <Form.Item
              valuePropName="date"
              name="start1"
              rules={[
                {
                  required: true,
                  message: 'Debe elegir una hora',
                },
              ]}
              label="Hora inicio:"
              labelCol={{ span: 24 }}
            >
              <DatePicker.TimePicker
                defaultValue={moment(scheduleDay?.first[0])}
                placeholder="Inicio"
                inputReadOnly
                style={{ width: '100%' }}
                showNow={false}
                format="HH:mm"
                showTime={{ format: 'HH:mm' }}
                minuteStep={5}
                allowClear={false}
              />
            </Form.Item>
          </Col>
          <Col xs={8} md={8}>
            <Form.Item
              valuePropName="date"
              name="end1"
              rules={[
                {
                  required: true,
                  message: 'Debe elegir una hora',
                },
              ]}
              label="Hora Fin:"
              labelCol={{ span: 24 }}
            >
              <DatePicker.TimePicker
                defaultValue={moment(scheduleDay?.first[1])}
                placeholder="Fin"
                inputReadOnly
                style={{ width: '100%' }}
                showNow={false}
                format="HH:mm"
                showTime={{ format: 'HH:mm' }}
                minuteStep={5}
                allowClear={false}
              />
            </Form.Item>
          </Col>
        </Row>
        {scheduleDay?.second && (
          <Row gutter={[24, 24]}>
            <Col xs={4} md={8}>
              <span>2do Horario</span>
            </Col>
            <Col xs={8} md={8}>
              <Form.Item
                valuePropName="date"
                name="start2"
                rules={[
                  {
                    required: true,
                    message: 'Debe elegir una hora',
                  },
                ]}
                label="Hora inicio:"
                labelCol={{ span: 24 }}
              >
                <DatePicker.TimePicker
                  defaultValue={moment(scheduleDay?.second[0])}
                  placeholder="Inicio"
                  inputReadOnly
                  style={{ width: '100%' }}
                  showNow={false}
                  format="HH:mm"
                  showTime={{ format: 'HH:mm' }}
                  minuteStep={5}
                  allowClear={false}
                  onChange={(value) => {
                    if (
                      minutesOfDay(value) <
                      minutesOfDay(moment(changeSchedule.getFieldsValue().end1))
                    ) {
                      message.info(
                        'No puede seleccionar un horario menor al cierre del turno 1'
                      );
                      changeSchedule.setFieldsValue({
                        start2: moment(scheduleDay?.second[0]),
                      });
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={8} md={8}>
              <Form.Item
                valuePropName="date"
                name="end2"
                rules={[
                  {
                    required: true,
                    message: 'Debe elegir una hora',
                  },
                ]}
                label="Hora Fin:"
                labelCol={{ span: 24 }}
              >
                <DatePicker.TimePicker
                  defaultValue={moment(scheduleDay?.second[1])}
                  placeholder="Fin"
                  inputReadOnly
                  style={{ width: '100%' }}
                  showNow={false}
                  format="HH:mm"
                  showTime={{ format: 'HH:mm' }}
                  minuteStep={5}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={24}>
            <Button
              type="default"
              htmlType="submit"
              style={{ width: '100%' }}
              disabled={loading}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div className="turns-list">
      <Anchor offsetTop={70}>
        <h3 className="title-date">
          {moment(date).format('dddd')} {moment(date).format('D')} de{' '}
          {moment(date).format('MMMM')} {moment(date).format('YYYY')}
          <Tooltip title="Editar agenda para este día">
            <Button
              type="link"
              onClick={() => changeScheduleInDay()}
              disabled={
                !moment(date).endOf('day').isSameOrAfter(moment()) || !turnId
              }
            >
              <EditOutlined />
            </Button>
          </Tooltip>
        </h3>
      </Anchor>
      <List
        itemLayout="horizontal"
        dataSource={slots}
        size="small"
        renderItem={(slot, index) => (
          <Slot
            slot={slot}
            addTurn={addTurn}
            viewPatient={viewPatient}
            updateStatus={updateStatus}
            deleteTurn={deleteTurn}
            addPatient={addPatient}
            data={turns?.filter(
              (turn) =>
                (turn.hourStart === slot.hourStart ||
                  (moment(turn.hourStart).isBefore(
                    slots[index + 1]?.hourStart
                  ) &&
                    moment(turn.hourStart).isAfter(slot?.hourStart))) &&
                !slot.exclude &&
                !turn?.softDelete
            )}
            size={size}
            moveTurn={moveTurn}
            role={role}
            editTurn={editTurn}
            excludeSlot={excludeSlot}
            userId={userId}
            turnType={turnType}
            tenantTimezone={tenantTimezone}
            addPaidTurn={addPaidTurn}
            cashRegisters={cashRegisters}
          />
        )}
        locale={{ emptyText: 'No hay turnos' }}
        loading={
          loading && {
            style: { width: '100%' },
            size: 'large',
            indicator: <LoadingOutlined />,
          }
        }
      />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={modalTitle === 'Cancelar turno' ? '50%' : '80%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function Slot(props) {
  const {
    slot,
    addTurn,
    viewPatient,
    data,
    updateStatus,
    deleteTurn,
    addPatient,
    size,
    moveTurn,
    role,
    editTurn,
    excludeSlot,
    userId,
    turnType,
    tenantTimezone,
    addPaidTurn,
    cashRegisters,
  } = props;

  let content = (turn = undefined, index = 0) => {
    return !slot.exclude ? (
      <>
        <List.Item
          style={
            moment() < moment(slot?.hourStart).add(size, 'm') &&
            moment() >= moment(slot?.hourStart)
              ? { backgroundColor: '#eef7ff' }
              : !turn
              ? { backgroundColor: '#fff' }
              : turn?.important
              ? { backgroundColor: '#a8d3f4' }
              : { backgroundColor: '#f8f9fa' }
          }
          actions={
            !turn?.exclude
              ? [
                  <Tooltip
                    title={
                      turn
                        ? turn?.patient
                          ? 'Ver Paciente'
                          : 'Agregar Paciente'
                        : 'Nuevo Turno'
                    }
                  >
                    <Button
                      type="link"
                      disabled={
                        !turn &&
                        ((moment()
                          .startOf('day')
                          .isAfter(moment(slot?.hourStart).startOf('day')) &&
                          role !== ROLES.OWNER.value) ||
                          slot?.cancel)
                      }
                      onClick={() =>
                        turn
                          ? turn.patient
                            ? viewPatient(turn)
                            : addPatient(turn)
                          : addTurn(slot)
                      }
                    >
                      {turn ? (
                        turn.patient ? (
                          <EyeOutlined />
                        ) : (
                          <UserAddOutlined />
                        )
                      ) : (
                        <PlusOutlined />
                      )}
                    </Button>
                  </Tooltip>,
                  <div>
                    <Tooltip title="Ausente">
                      <Button
                        type="link"
                        disabled={
                          !turn ||
                          (moment()
                            .startOf('day')
                            .isAfter(moment(slot?.hourStart).startOf('day')) &&
                            role !== ROLES.OWNER.value)
                        }
                        onClick={() => {
                          updateStatus(
                            turn?._id,
                            turn?.status === 'Ausente' ? null : 'Ausente'
                          );
                        }}
                        style={{ padding: 0, margin: 0 }}
                      >
                        <Badge status="error" />
                      </Button>
                    </Tooltip>
                    <Tooltip title="En Espera">
                      <Button
                        type="link"
                        disabled={
                          !turn ||
                          (moment()
                            .startOf('day')
                            .isAfter(moment(slot?.hourStart).startOf('day')) &&
                            role !== ROLES.OWNER.value)
                        }
                        onClick={() => {
                          updateStatus(
                            turn?._id,
                            turn?.status === 'Sala de Espera'
                              ? null
                              : 'Sala de Espera'
                          );
                        }}
                        style={{ padding: 0, margin: 0 }}
                      >
                        <Badge status="warning" />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Atender">
                      <Button
                        type="link"
                        disabled={
                          !turn ||
                          (moment()
                            .startOf('day')
                            .isAfter(moment(slot?.hourStart).startOf('day')) &&
                            role !== ROLES.OWNER.value)
                        }
                        onClick={() => {
                          updateStatus(
                            turn?._id,
                            turn?.status === 'Atendido' ? null : 'Atendido'
                          );
                        }}
                        style={{ padding: 0, margin: 0 }}
                      >
                        <Badge status="success" />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Destacar Turno">
                      <Button
                        type="link"
                        disabled={!turn}
                        onClick={() => {
                          updateStatus(
                            turn?._id,
                            null,
                            turn?.important ? false : true
                          );
                        }}
                        style={{ padding: 0, margin: 0 }}
                      >
                        <Badge color="blue" />
                      </Button>
                    </Tooltip>
                  </div>,
                  <Dropdown
                    trigger="click"
                    overlay={
                      <Menu>
                        {!turn && (
                          <Menu.Item>
                            <Button
                              type="link"
                              disabled={turn}
                              onClick={() => excludeSlot(slot)}
                            >
                              <StopOutlined style={{ color: '#e31b23' }} />
                              <span style={{ color: 'red' }}>
                                Bloquear Horario
                              </span>
                            </Button>
                          </Menu.Item>
                        )}
                        <Menu.Item>
                          <Button
                            type="link"
                            disabled={
                              !turn ||
                              (moment()
                                .startOf('day')
                                .isAfter(
                                  moment(slot?.hourStart).startOf('day')
                                ) &&
                                role !== ROLES.OWNER.value)
                            }
                            onClick={() => addTurn(slot)}
                          >
                            <ScheduleOutlined /> <span>Sobre Turno</span>
                          </Button>
                        </Menu.Item>
                        <Menu.Item>
                          <Button
                            type="link"
                            disabled={
                              !turn ||
                              !turn?.patient ||
                              (moment()
                                .startOf('day')
                                .isAfter(
                                  moment(slot?.hourStart).startOf('day')
                                ) &&
                                role !== ROLES.OWNER.value)
                            }
                            onClick={() => moveTurn(turn)}
                          >
                            <RetweetOutlined /> <span>Mover Turno</span>
                          </Button>
                        </Menu.Item>
                        <Menu.Item>
                          <Button
                            type="link"
                            disabled={
                              !turn ||
                              !turn?.patient ||
                              (moment()
                                .startOf('day')
                                .isAfter(
                                  moment(slot?.hourStart).startOf('day')
                                ) &&
                                role !== ROLES.OWNER.value)
                            }
                            onClick={() => editTurn(turn)}
                          >
                            <EditOutlined />
                            <span>Editar Turno</span>
                          </Button>
                        </Menu.Item>
                        {cashRegisters?.length > 0 && (
                          <Menu.Item>
                            <Button
                              type="link"
                              disabled={
                                !turn ||
                                !turn?.patient ||
                                (moment()
                                  .startOf('day')
                                  .isAfter(
                                    moment(slot?.hourStart).startOf('day')
                                  ) &&
                                  role !== ROLES.OWNER.value)
                              }
                              onClick={() => addPaidTurn(turn)}
                            >
                              <DollarOutlined />
                              <span>Cobrar Turno</span>
                            </Button>
                          </Menu.Item>
                        )}
                        <Menu.Item>
                          <Tooltip
                            title={
                              turn?.paid
                                ? 'No puede cancelar un turno marcado como pagado'
                                : ''
                            }
                          >
                            <Button
                              type="link"
                              disabled={
                                turn?.paid ||
                                !turn ||
                                (moment()
                                  .startOf('day')
                                  .isAfter(
                                    moment(slot?.hourStart).startOf('day')
                                  ) &&
                                  role !== ROLES.OWNER.value)
                              }
                              onClick={() =>
                                deleteTurn(
                                  turn?._id,
                                  turn.portal
                                    ? turn?.email
                                    : turn?.patient?.email,
                                  turn
                                )
                              }
                            >
                              <DeleteOutlined style={{ color: 'red' }} />{' '}
                              <span style={{ color: 'red' }}>
                                Cancelar Turno
                              </span>
                            </Button>
                          </Tooltip>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button type="link">
                      <MoreOutlined style={{ color: '#1764ab' }} />
                    </Button>
                  </Dropdown>,
                ]
              : [
                  <Button
                    type="link"
                    onClick={() =>
                      deleteTurn(turn?._id, turn?.email, turn, true)
                    }
                  >
                    <CheckOutlined style={{ color: 'green' }} />
                    <span style={{ color: 'green' }}>Habilitar</span>
                  </Button>,
                ]
          }
        >
          <List.Item.Meta
            avatar={
              <div
                className="turns-list__date"
                style={
                  index > 0
                    ? { backgroundColor: '#e31b23' }
                    : turn &&
                      !turn?.exclude &&
                      turn?.hourStart !== slot?.hourStart
                    ? { backgroundColor: '#3F80BA' }
                    : {}
                }
              >
                {index > 0 && <span>ST </span>}{' '}
                {turn &&
                !turn?.exclude &&
                turn?.hourStart !== slot?.hourStart ? (
                  <Tooltip title="Turno dado en este horario, antes de modificar agenda del día.">
                    <span>
                      {moment
                        .tz(turn?.hourStart, tenantTimezone)
                        .format('HH:mm')}
                    </span>
                  </Tooltip>
                ) : (
                  <span>
                    {moment.tz(slot?.hourStart, tenantTimezone).format('HH:mm')}
                  </span>
                )}
              </div>
            }
            title={
              <>
                {turn && turn.patient ? (
                  <>
                    {turn.patient?.name}
                    <span>
                      {' '}
                      ({moment().diff(turn.patient?.birthDate, 'years')} años)
                    </span>
                  </>
                ) : turn?.exclude ? (
                  'Horario Bloqueado'
                ) : (
                  <>
                    {turn?.name} {turn?.dni && `- ${turn?.dni}`}
                  </>
                )}
                {turn?.price > 0 &&
                  (role === 2 ||
                    turn?.doctorDerivate?._id === userId ||
                    turnType !== 'studie') && (
                    <span>
                      {' '}
                      <Tag
                        color={
                          cashRegisters?.length > 0
                            ? turn?.paid
                              ? 'green'
                              : 'default'
                            : turn.status === 'Atendido'
                            ? 'green'
                            : turn.status === 'Ausente'
                            ? 'red'
                            : 'default'
                        }
                        icon={
                          (cashRegisters?.length > 0 && turn?.paid) ||
                          (cashRegisters?.length === 0 &&
                            turn.status === 'Atendido') ? (
                            <CheckCircleOutlined />
                          ) : (
                            turn.status === 'Ausente' && <MinusCircleOutlined />
                          )
                        }
                      >
                        {(role === 2 || role === 0
                          ? turn?.price
                          : (turn?.price * turn?.percent) / 100
                        ).toLocaleString('es-AR', {
                          style: 'currency',
                          currency: 'ARS',
                          minimumFractionDigits: 0,
                        })}{' '}
                      </Tag>
                    </span>
                  )}{' '}
                {turn?.record === true && turn?.confirm === undefined ? (
                  <Tooltip title="Recordatorio enviado">
                    <IssuesCloseOutlined />
                  </Tooltip>
                ) : turn?.confirm ? (
                  <Tooltip title="Turno confirmado por paciente">
                    <CheckCircleOutlined style={{ color: '#3f8600' }} />
                  </Tooltip>
                ) : (
                  turn?.record === true && (
                    <Tooltip title="Turno cancelado por paciente">
                      <CloseCircleOutlined style={{ color: '#cf1322' }} />
                    </Tooltip>
                  )
                )}
              </>
            }
            description={
              <>
                {(turn?.status || slot?.cancel) && (
                  <Tag
                    color={
                      turn?.status === 'Sala de Espera'
                        ? '#dad00b'
                        : turn?.status === 'Atendido'
                        ? 'green'
                        : turn?.status === 'Ausente'
                        ? 'red'
                        : 'default'
                    }
                    icon={
                      turn?.status === 'Sala de Espera' ? (
                        <SyncOutlined />
                      ) : turn?.status === 'Atendido' ? (
                        <CheckCircleOutlined />
                      ) : turn?.status === 'Ausente' ? (
                        <MinusCircleOutlined />
                      ) : (
                        <ExclamationCircleOutlined />
                      )
                    }
                  >
                    <>
                      {turn?.status || 'No disponible'}
                      {turn?.status === 'Sala de Espera' && (
                        <TimeSince startDate={moment(turn?.hourStatus?.wait)} />
                      )}
                    </>
                  </Tag>
                )}
                {turn?.socialWork}{' '}
                {turn?.portal && <Tag color="orange">Portal</Tag>}{' '}
                {turn?.studies.map((studie) => (
                  <Tag>{studie?.name}</Tag>
                ))}
                {turn?.doctorDerivate && (
                  <p>
                    Efector: {turn?.doctorDerivate?.name}{' '}
                    {turn?.doctorDerivate?.lastname}
                  </p>
                )}
                <p>{turn?.description}</p>
              </>
            }
          />
        </List.Item>
      </>
    ) : (
      <List.Item>
        <List.Item.Meta
          title={
            <Divider style={{ backgroundColor: '#f8f9fa', padding: '10px' }}>
              {slot.second ? 'Segundo Turno' : 'Día Excluído'}
            </Divider>
          }
        />
      </List.Item>
    );
  };

  if (!data || data?.length === 0) {
    return content();
  } else {
    return data?.map((turn, index) => content(turn, index));
  }
}
