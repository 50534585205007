import { basePath, apiVersion } from './config';

export async function getOpenCashRegisterApi({ token, type = 'cash' }) {
  const url = `${basePath}/${apiVersion}/cash-register/open?type=${type}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function getCloseCashRegisterApi({ token, organizations }) {
  const url = `${basePath}/${apiVersion}/cash-register/close?cashRegisters=${organizations}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function getCashRegisterApi(token, id) {
  const url = `${basePath}/${apiVersion}/cash-register/${id}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function getPaidTurnsApi({
  token,
  cashRegister,
  userId,
  paymentMethod,
  cardType,
}) {
  const url = `${basePath}/${apiVersion}/get-vouchers-details/?cashRegister=${cashRegister}&userId=${userId}&paymentMethod=${paymentMethod}&cardType=${cardType}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function openCashRegisterShiftApi({ token, data }) {
  const url = `${basePath}/${apiVersion}/open-shift`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function closeCashRegisterShiftApi({ token, id }) {
  const url = `${basePath}/${apiVersion}/close-shift/${id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function editCashRegisterShiftApi({ token, data, id }) {
  const url = `${basePath}/${apiVersion}/edit-shift/${id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}
