import {
  ON_BANK_ACCOUNT_READ,
  ON_BANK_ACCOUNT_READING,
} from '../actions/types';

const INITIAL_STATE = { bankAccounts: [], loading: false };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_BANK_ACCOUNT_READING:
      return { ...state, loading: true };
    case ON_BANK_ACCOUNT_READ:
      return { ...state, bankAccounts: action.payload, loading: false };
    default:
      return state;
  }
};
