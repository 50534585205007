// User types
export const ON_LOGOUT_FINISHED = 'on_logout_finished';
export const ON_USER_READ = 'on_user_read';
export const ON_USER_READING = 'on_user_reading';
export const ON_USER_VALUE_CHANGE = 'on_user_value_change';

// Bed types
export const ON_BED_READ = 'ON_BED_READ';
export const ON_BED_READING = 'ON_BED_READING';
export const ON_BED_STATS_READ = 'ON_BED_STATS_READ';
export const ON_BED_STATS_READING = 'ON_BED_STATS_READING';

// SocialWork types
export const ON_SOCIAL_WORK_READ = 'ON_SOCIAL_WORK_READ';
export const ON_SOCIAL_WORK_READING = 'ON_SOCIAL_WORK_READING';

// Tenant types
export const ON_FLOORS_READ = 'ON_FLOORS_READ';
export const ON_FLOORS_READING = 'ON_FLOORS_READING';
export const ON_PRIMARY_READ = 'ON_PRIMARY_READ';
export const ON_PRIMARY_READING = 'ON_PRIMARY_READING';

// City types
export const ON_CITY_READ = 'ON_CITY_READ';
export const ON_CITY_READING = 'ON_CITY_READING';

// Income types
export const ON_INCOME_ACTIVE_READ = 'ON_INCOME_ACTIVE_READ';
export const ON_INCOME_ACTIVE_READING = 'ON_INCOME_ACTIVE_READING';
export const ON_INCOME_HISTORY_READ = 'ON_INCOME_HISTORY_READ';
export const ON_INCOME_HISTORY_READING = 'ON_INCOME_HISTORY_READING';
export const ON_INCOME_HISTORY_SEARCH_READ = 'ON_INCOME_HISTORY_SEARCH_READ';
export const ON_INCOME_HISTORY_SEARCH_READING =
  'ON_INCOME_HISTORY_SEARCH_READING';
export const ON_INCOME_VALUE_CHANGE = 'ON_INCOME_VALUE_CHANGE';

// Phone types
export const ON_PHONE_READ = 'ON_PHONE_READ';
export const ON_PHONE_READING = 'ON_PHONE_READING';

// Message types
export const ON_MESSAGE_READ = 'ON_MESSAGE_READ';
export const ON_MESSAGE_READING = 'ON_MESSAGE_READING';

// Diagnostic types
export const ON_DIAGNOSTIC_READ = 'ON_DIAGNOSTIC_READ';
export const ON_DIAGNOSTIC_READING = 'ON_DIAGNOSTIC_READING';

// Patientt types
export const ON_PATIENT_VALUE_CHANGE = 'ON_PATIENT_VALUE_CHANGE';
export const ON_PATIENT_READ = 'ON_PATIENT_READ';
export const ON_FAVOURITE_PATIENT_READ = 'ON_FAVOURITE_PATIENT_READ';
export const ON_PATIENT_READING = 'ON_PATIENT_READING';

// Pharmacy types
export const ON_PHARMACY_VALUE_CHANGE = 'ON_PHARMACY_VALUE_CHANGE';
export const ON_PHARMACY_READ = 'ON_PHARMACY_READ';
export const ON_PHARMACY_READING = 'ON_PHARMACY_READING';
export const ON_PHARMACY_SEARCH_READ = 'ON_PHARMACY_SEARCH_READ';
export const ON_PHARMACY_SEARCH_READING = 'ON_PHARMACY_SEARCH_READING';

export const ON_SEARCH_VALUE_CHANGE = 'ON_SEARCH_VALUE_CHANGE';

// Bank accounts types
export const ON_BANK_ACCOUNT_READ = 'ON_BANK_ACCOUNT_READ';
export const ON_BANK_ACCOUNT_READING = 'ON_BANK_ACCOUNT_READING';
