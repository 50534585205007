import React, { useState } from 'react';
import { List, Card, Button, Modal as ModalAntd, message, Tooltip } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Error404 from '../Error404';
import Int from '../../assets/img/billing-int.png';
import Nom from '../../assets/img/nomenclator.png';

import { useSelector } from 'react-redux';
import Modal from '../../components/Modal';
import { deleteBillingApi, getBillings } from '../../api/billing';
import { getAccessTokenApi } from '../../api/auth';
import moment from 'moment';

export default function Billing() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);

  const { modulesAccess, role, viewBillingOrganizations } = useSelector(
    (store) => store.userData?.user
  );

  const data = [
    {
      title: 'Liquidación Ambulatoria',
      actions: [
        <Link to={{ pathname: `/new-batch-billing` }}>
          <span>
            <PlusOutlined /> Nueva Liquidación
          </span>
        </Link>,
        <a
          onClick={async () => {
            const token = await getAccessTokenApi();
            let billingsPre;

            await getBillings({
              token,
              search: `?organization=${viewBillingOrganizations}&status=Pre - Liquidación`,
            })
              .then((response) => {
                billingsPre = response?.billings;
              })
              .then(() => {
                getPreLiquid(billingsPre);
              });
          }}
        >
          <EditOutlined /> Pre - Liquidaciones
        </a>,
        <Link to={`/billing-batch-details/month`}>
          <EyeOutlined /> Ver Liquidaciones
        </Link>,
      ],
      description:
        'Realizar nuevas liquidaciones ambulatorias y poder observar las liquidaciones mensuales y sus estados',
      image: Int,
    },
    {
      title: 'Nomencladores',
      actions: [
        <Link to={`/nomenclators`}>
          <EyeOutlined /> Ver nomencladores
        </Link>,
      ],
      description: 'Ver codigos de nomenclador cargados',
      image: Nom,
    },
  ];

  const getPreLiquid = async (billingsPre) => {
    const deleteBilling = async (id) => {
      const token = await getAccessTokenApi();
      ModalAntd.confirm({
        title: 'Eliminar pre-liquidación',
        content: `¿ Estas seguro que quieres eliminar la pre-liquidación ?`,
        okText: 'Aceptar',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk() {
          deleteBillingApi({ token, id })
            .then((response) => {
              if (response?.code !== 200) {
                message.error(response.message);
              } else {
                message.success(response.message);
                setIsVisibleModal(false);
              }
            })
            .catch(() => {
              message.error('Error del servidor, intente mas tarde.');
            });
        },
      });
    };

    setIsVisibleModal(true);
    setModalTitle('Ver Pre-Liquidaciones');
    setModalContent(
      <List
        dataSource={billingsPre}
        renderItem={(billing) => {
          return (
            <List.Item
              actions={[
                <Tooltip title="Eliminar pre-liquidación">
                  <Button
                    type="link"
                    style={{
                      color: 'red',
                    }}
                    onClick={() => deleteBilling(billing._id)}
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>,
                <Tooltip title="Continuar editando">
                  <Button
                    type="link"
                    onClick={() => {
                      setIsVisibleModal(false);
                      window.location.href = `/#new-batch-billing?${billing._id}`;
                    }}
                  >
                    <span>
                      <EditOutlined />
                    </span>
                  </Button>
                </Tooltip>,
              ]}
            >
              <List.Item.Meta
                title={`${billing.organization} - ${billing.socialWork} - ${billing.turnType}`}
                description={
                  <ul>
                    <li>
                      <b>
                        Periodo:{' '}
                        {moment(billing.period?.start).format('DD/MM/YY')} al{' '}
                        {moment(billing.period?.end).format('DD/MM/YY')}
                      </b>
                    </li>
                    <li>
                      Responsable: {billing.user} -{' '}
                      {moment(billing.billingDate).format('DD/MM/YY')}
                    </li>
                  </ul>
                }
              />
            </List.Item>
          );
        }}
        locale={{
          emptyText: 'No hay pre-liquidaciones...',
        }}
      />
    );
  };

  if (modulesAccess?.includes('Facturacion amb') || role === 2) {
    return (
      <div>
        <List
          className="cards"
          grid={{ gutter: 16, column: 2, xs: 1, sm: 1, md: 2 }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Card
                cover={<img alt="example" src={item.image} />}
                actions={item.actions}
                hoverable
              >
                <Card.Meta title={item.title} description={item.description} />
              </Card>
            </List.Item>
          )}
        />
        <Modal
          title={modalTitle}
          isVisible={isVisibleModal}
          setIsVisible={setIsVisibleModal}
          width="50%"
        >
          {modalContent}
        </Modal>
      </div>
    );
  } else {
    return (
      <Error404 subTitle="No posee permisos para ingresar a este módulo" />
    );
  }
}
