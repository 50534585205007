import { basePath, apiVersion } from './config';

export async function getAllBankAccountsApi(token, view = 'summary') {
  const url = `${basePath}/${apiVersion}/bank-accounts?view=${view}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function updateBankAccountApi({ token, id, data }) {
  const url = `${basePath}/${apiVersion}/bank-account/${id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function addlBankAccountApi({ token, data }) {
  const url = `${basePath}/${apiVersion}/bank-account`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export async function getBankAccountTransactionsApi({
  token,
  bankAccount,
  page,
  limit,
}) {
  const url = `${basePath}/${apiVersion}/bank-account-transactions/${bankAccount}?page=${page}&limit=${limit}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}
