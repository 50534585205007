import { message } from 'antd';
import { getAccessTokenApi } from '../../api/auth';
import { getPrescriptionApi } from '../../api/prescription';

export const newPresciption = async (patientId, type) => {
  let token = await getAccessTokenApi();
  const newWindow = window.open('', '_blank');

  getPrescriptionApi({ token, patientId }).then((response) => {
    if (response?.code !== 200) {
      newWindow.close();
      message.error(response.message, 8);
    } else {
      message.success(response.message);
      newWindow.location.href =
        type === 'order' ? response.ordersLink : response.prescriptionsLink;
    }
  });
};
