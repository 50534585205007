import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Col,
  Row,
  message,
} from 'antd';
import { getAccessTokenApi } from '../../api/auth';
import { getUsersActiveApi } from '../../api/user';
import {
  addlBankAccountApi,
  updateBankAccountApi,
} from '../../api/bankAccount';
import { onBankAccountRead } from '../../actions';
import { useDispatch } from 'react-redux';

const { Option } = Select;

const BankAccountForm = ({ accountBankData, setIsVisibleModal }) => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);

  const {
    accountHolderName,
    bankName,
    accountNumber,
    accountStatus,
    cbu_iban,
    alias,
    accountType,
    currency,
    balance,
    managmentUsers,
    defaultUsers,
  } = accountBankData;

  const isDisabled = Boolean(Object.keys(accountBankData).length);

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      getUsersActiveApi(token, true).then((response) => {
        setUsers(response.users);
      });
    };

    isDisabled
      ? form.setFieldsValue({
          accountHolderName,
          bankName,
          accountNumber,
          accountStatus,
          cbu_iban,
          alias,
          accountType,
          currency,
          balance,
          managmentUsers,
          defaultUsers,
        })
      : form.resetFields();

    getData();
  }, [accountBankData]);

  const handleFinish = async (values) => {
    let token = await getAccessTokenApi();

    const response = isDisabled
      ? await updateBankAccountApi({
          token,
          id: accountBankData?._id,
          data: values,
        })
      : await addlBankAccountApi({ token, data: values });

    if (response.code === 200) {
      message.success(response.message);
      dispatch(onBankAccountRead('detailed'));
      setIsVisibleModal(false);
    } else {
      message.error(response.message);
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      layout="vertical"
      initialValues={{
        accountStatus: 'activa',
        currency: 'ARS',
        balance: 0,
      }}
    >
      <Row gutter={[24, 24]}>
        <Col xs={24} md={8}>
          <Form.Item
            name="accountHolderName"
            label="Nombre del titular"
            rules={[
              {
                required: true,
                message: 'Por favor ingresa el nombre del titular',
              },
            ]}
          >
            <Input placeholder="Juan Perez" disabled={isDisabled} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name="bankName"
            label="Nombre del banco"
            rules={[
              {
                required: true,
                message: 'Por favor ingresa el nombre del banco',
              },
            ]}
          >
            <Input placeholder="BBVA" disabled={isDisabled} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name="accountNumber"
            label="Número de cuenta"
            rules={[
              {
                required: true,
                message: 'Por favor ingresa el número de cuenta',
              },
            ]}
          >
            <Input placeholder="4023013-8 130-9" disabled={isDisabled} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col xs={24} md={8}>
          <Form.Item
            name="cbu_iban"
            label="CBU/IBAN"
            rules={[
              { required: true, message: 'Por favor ingresa el CBU o IBAN' },
            ]}
          >
            <Input placeholder="12328312-2193912312" disabled={isDisabled} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item name="alias" label="Alias">
            <Input placeholder="jperez.bbva" />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name="accountType"
            label="Tipo de cuenta"
            rules={[
              {
                required: true,
                message: 'Por favor selecciona el tipo de cuenta',
              },
            ]}
          >
            <Select
              placeholder="Selecciona el tipo de cuenta"
              disabled={isDisabled}
            >
              <Option value="corriente">Corriente</Option>
              <Option value="ahorro">Ahorro</Option>
              <Option value="otro">Otro</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col xs={24} md={8}>
          <Form.Item
            name="currency"
            label="Moneda"
            rules={[
              { required: true, message: 'Por favor selecciona la moneda' },
            ]}
          >
            <Select placeholder="Selecciona la moneda" disabled={isDisabled}>
              <Option value="ARS">ARS</Option>
              <Option value="USD">USD</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name="accountStatus"
            label="Estado de la cuenta"
            rules={[
              {
                required: true,
                message: 'Por favor selecciona el estado de la cuenta',
              },
            ]}
          >
            <Select placeholder="Selecciona el estado de la cuenta">
              <Option value="activa">Activa</Option>
              <Option value="inactiva">Inactiva</Option>
              <Option value="suspendida">Suspendida</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name="balance"
            label="Saldo inicial"
            rules={[
              {
                required: true,
                message: 'Por favor selecciona el estado de la cuenta',
              },
            ]}
          >
            <InputNumber
              disabled={isDisabled}
              min={0}
              style={{ width: '100%' }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12}>
          <Form.Item
            name="defaultUsers"
            label="Usuarios predeterminados"
            help="Usuarios médicos que en su turnera van a tener predeterminada esta cuenta"
          >
            <Select
              mode="multiple"
              placeholder="Selecciona los usuarios predeterminados"
            >
              {users?.map((user, index) => {
                return (
                  user?.type === 'Medico' && (
                    <Select.Option key={index} value={user._id}>
                      {user.name} {user.lastname}
                    </Select.Option>
                  )
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="managmentUsers"
            label="Usuarios de gestión"
            help="Usuarios que van a poder visualizar los movimientos de la cuenta"
          >
            <Select
              mode="multiple"
              placeholder="Selecciona los usuarios de gestión"
            >
              {users?.map((user, index) => {
                return (
                  (user?.type === 'Medico' ||
                    user?.type === 'Administrativo') && (
                    <Select.Option key={index} value={user._id}>
                      {user.name} {user.lastname}
                    </Select.Option>
                  )
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
          {`${isDisabled ? 'Actualizar' : 'Crear'} cuenta bancaria`}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BankAccountForm;
