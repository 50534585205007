import React, { useState, useEffect } from 'react';
import {
  Button,
  DatePicker,
  Row,
  Col,
  Select,
  Tooltip,
  message,
  Badge,
  Divider,
  Statistic,
  Progress,
  notification,
  Calendar,
  Anchor,
} from 'antd';
import { getUsersTypeApi, updateExcludeDayScheduleApi } from '../../api/user';
import { getAccessTokenApi } from '../../api/auth';
import locale from 'antd/es/date-picker/locale/es_ES';
import ListTurns from '../../components/Ambulatory/ListTurns';
import {
  CalendarOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  PlusOutlined,
  PrinterOutlined,
  ScheduleOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { getMonthTurnsApi, getTurnApi } from '../../api/turn';
import { useSelector, useDispatch } from 'react-redux';
import Spin from '../../components/Spin';
import Modal from '../../components/Modal';
import ScheduleConfiguration from '../../components/Ambulatory/ScheduleConfigurationForm';
import { onFloorsRead, onMessageRead, onUserDataRead } from '../../actions';
import MessageList from '../../components/Message/MessageList';
import MessageForm from '../../components/Message/MessageForm';
import { pdf, Document } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { PdfTurn } from '../../components/PdfReports';
import { feriados } from '../../utils/constants';
import '../Calendar/Calendar.scss';
import { onSearchValueChange } from '../../actions/BillingSearchActions';
import { TableChart } from '../../components/Charts/TableChart';

export default function Turns() {
  const userId = useSelector((store) => store.userData.user._id);
  const { type, name, lastname, defaultSizeSlot, viewStudies, role } =
    useSelector((store) => store.userData?.user);

  const user = useSelector((store) => store.userData?.user);
  const { messages } = useSelector((store) => store.messageData);
  const { studies, cashRegisters, tenantTimezone } = useSelector(
    (store) => store.tenantData
  );

  const { turnUser, turnType } = useSelector((store) => store.searchData);

  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [slots, setSlots] = useState();
  const [date, setDate] = useState(
    moment().tz(tenantTimezone).format('YYYY-MM-DDTHH:mm:ss')
  );
  const [turn, setTurn] = useState([]);
  const [reload, setReload] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [size, setSize] = useState();
  const [statsTurns, setStatsTurns] = useState({
    quantity: 0,
    quantityCheck: 0,
    price: 0,
    priceCheck: 0,
    pricePercent: 0,
    pricePercentCheck: 0,
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [viewCalendar, setViewCalendar] = useState(false);
  const [monthData, setMonthData] = useState([]);
  const [scheduleDay, setScheduleDay] = useState();

  const scheduleConfiguration = async () => {
    let dataSizeSlot =
      turnType === 'studie'
        ? await studies.find((studie) => {
            if (studie.id === turnUser) return studie.defaultSizeSlot;
          })
        : await users.find((user) => user._id === turnUser);

    setIsVisibleModal(true);
    setModalTitle('Configuracion de Agenda');
    setModalContent(
      <ScheduleConfiguration
        sizeSlot={dataSizeSlot?.defaultSizeSlot}
        userId={turnUser}
        setIsVisibleModal={setIsVisibleModal}
        setReload={setReload}
        turnType={turnType}
        setSize={setSize}
        scheduleConfig={data?.scheduleConfig}
        emailNotification={data?.emailNotification}
      />
    );
  };

  useEffect(() => {
    turnUser === null &&
      dispatch(
        onSearchValueChange({
          turnUser: type === 'Medico' ? userId : null,
          turnType: type === 'Medico' ? 'doctor' : null,
        })
      );
  }, []);

  useEffect(() => {
    const getDataTurn = async () => {
      const token = await getAccessTokenApi();

      await getUsersTypeApi(token, true, 'Medico')
        .then(async (response) => {
          await setUsers(response.users);
          return response.users;
        })
        .then(async (result) => {
          if (turnUser) {
            let dataSizeSlot =
              turnType === 'studie'
                ? await studies.find((studie) => {
                    if (studie.id === turnUser) return studie.defaultSizeSlot;
                  })
                : await result.find((user) => {
                    if (user._id === turnUser) return user;
                  });

            await getTurnApi(token, turnUser, date).then(async (response) => {
              let quantity = await response?.turns?.shifts?.filter(
                (a) => !a.exclude && !a.softDelete
              );

              let count = await response?.turns?.shifts?.filter(
                (a) => a.status === 'Atendido' && !a.softDelete
              );

              let paid =
                cashRegisters?.length > 0 &&
                (await response?.turns?.shifts?.filter((a) => a?.paid));

              let sumPriceCheck = 0;
              let sumPricePercentCheck = 0;

              await (paid ? paid : count)?.map((a) => {
                if (a.price) {
                  sumPriceCheck += a.price;
                  sumPricePercentCheck += (a?.price * a?.percent) / 100;
                }
              });

              let sumPrice = 0;
              let sumPricePercent = 0;
              await response?.turns?.shifts?.map((a) => {
                if (a.price && !a.softDelete) {
                  sumPrice += a?.price;
                  sumPricePercent +=
                    (turnType !== 'studie' ||
                      (turnType === 'studie' &&
                        a?.doctorDerivate?._id === userId)) &&
                    (a?.price * a?.percent) / 100;
                }
              });

              setStatsTurns({
                quantity: quantity?.length || 0,
                quantityCheck: count?.length || 0,
                price: sumPrice,
                priceCheck: sumPriceCheck,
                pricePercent: sumPricePercent,
                pricePercentCheck: sumPricePercentCheck,
              });

              setTurn(response?.turns);

              await generateSlots(
                response?.turns?.shifts.length > 0
                  ? response?.turns?.sizeSlot
                  : dataSizeSlot?.defaultSizeSlot,
                response?.turns,
                result
              );
            });

            setReload(false);
          }
        });
    };

    type && turnUser && dispatch(onMessageRead(type, turnUser));

    !viewCalendar && getDataTurn();
  }, [date, turnUser, reload, isVisibleModal, viewCalendar]);

  useEffect(() => {
    const getDataMonth = async () => {
      setLoading(true);
      let token = await getAccessTokenApi();

      await getUsersTypeApi(token, true, 'Medico').then(async (response) => {
        await setUsers(response.users);
      });

      let userData =
        turnType === 'studie'
          ? await studies.find((studie) => {
              if (studie.id === turnUser) return studie;
            })
          : await users.find((user) => user._id === turnUser);

      setData(userData);

      let monthTurns = await getMonthTurnsApi(token, turnUser, date);

      if (monthTurns?.code === 200) {
        setMonthData(monthTurns?.turns);
      } else setMonthData([]);
      setLoading(false);
      setReload(false);
    };

    viewCalendar && getDataMonth();
  }, [viewCalendar, turnUser, reload]);

  const generateSlots = async (sizeSlot, turns, users) => {
    let generate = [];
    let minutesOfHour = 60;

    let configSchedule = {
      excludeDays: [],
      lu: {},
      ma: {},
      mi: {},
      ju: {},
      vi: {},
      sá: {},
      do: {},
    };

    let data =
      turnType === 'studie'
        ? await studies.find((studie) => {
            if (studie.id === turnUser) return studie;
          })
        : await users.find((user) => {
            return user._id == turnUser;
          });
    setData(data);

    if (turns?.scheduleConfig && turns?.shifts?.length > 0) {
      await turns?.scheduleConfig?.hours.map((h, index) => {
        h.days.map((e) => {
          configSchedule[e] =
            Object.entries(configSchedule[e]).length === 0
              ? {
                  first: [
                    moment(turns.scheduleConfig?.hours[index]?.start),
                    moment(turns.scheduleConfig?.hours[index]?.end),
                  ],
                }
              : Object.entries(configSchedule[e]).length === 1
              ? {
                  ...configSchedule[e],
                  second: [
                    moment(turns.scheduleConfig?.hours[index]?.start),
                    moment(turns.scheduleConfig?.hours[index]?.end),
                  ],
                }
              : { ...configSchedule[e] };
        });
      });
    } else {
      await data.scheduleConfig?.hours.map((h, index) => {
        h.days.map((e) => {
          configSchedule[e] =
            Object.entries(configSchedule[e]).length === 0
              ? {
                  first: [
                    moment(data.scheduleConfig?.hours[index]?.start),
                    moment(data.scheduleConfig?.hours[index]?.end),
                  ],
                }
              : Object.entries(configSchedule[e]).length === 1
              ? {
                  ...configSchedule[e],
                  second: [
                    moment(data.scheduleConfig?.hours[index]?.start),
                    moment(data.scheduleConfig?.hours[index]?.end),
                  ],
                }
              : { ...configSchedule[e] };
        });
      });
    }

    setScheduleDay(configSchedule[`${moment(date).format('dd')}`]);

    let duration =
      configSchedule[`${moment(date).format('dd')}`]?.first !== undefined &&
      moment.duration({
        hours:
          configSchedule[`${moment(date).format('dd')}`]?.first[0].format('HH'),
        minutes:
          configSchedule[`${moment(date).format('dd')}`]?.first[0].format('mm'),
      });

    let hourStart =
      configSchedule[`${moment(date).format('dd')}`]?.first !== undefined &&
      moment(date).startOf('day').add(duration);

    let res1 =
      hourStart &&
      configSchedule[`${hourStart.format('dd')}`]?.first !== undefined
        ? configSchedule[`${hourStart.format('dd')}`]?.first[1].diff(
            configSchedule[`${hourStart.format('dd')}`]?.first[0],
            'minutes'
          ) / minutesOfHour
        : 0;

    let q1 = (minutesOfHour / sizeSlot) * res1;

    let res2 =
      hourStart &&
      configSchedule[`${hourStart.format('dd')}`]?.second !== undefined
        ? configSchedule[`${hourStart.format('dd')}`]?.second[1].diff(
            configSchedule[`${hourStart.format('dd')}`]?.second[0],
            'minutes'
          ) / minutesOfHour
        : 0;

    let q2 = (minutesOfHour / sizeSlot) * res2 + (res2 !== 0 ? 1 : 0);

    if (
      data.scheduleConfig?.excludeDays.filter(
        (e) =>
          moment.tz(e, tenantTimezone).startOf('day').toISOString() ===
          moment.tz(date, tenantTimezone).startOf('day').toISOString()
      ).length === 0
    ) {
      for (let i = 0; i < q1 + q2; i++) {
        generate[i] = {
          hourStart: hourStart.toISOString(),
        };
        if (i == Math.round(q1)) {
          if (
            configSchedule[`${hourStart.format('dd')}`]?.second !== undefined
          ) {
            let duration2 = moment.duration({
              hours:
                configSchedule[
                  `${moment(date).format('dd')}`
                ]?.second[0].format('HH'),
              minutes:
                configSchedule[
                  `${moment(date).format('dd')}`
                ]?.second[0].format('mm'),
            });
            hourStart = moment(date).startOf('day').add(duration2);
            generate[i] = {
              hourStart: hourStart.toISOString(),
              exclude: true,
              second: true,
            };
            continue;
          }
        }
        hourStart.add(sizeSlot, 'm');
      }
    } else {
      generate[0] = {
        exclude: true,
      };
    }

    setSlots(generate);

    setSize(sizeSlot);
  };

  if (turnUser && !slots) return <Spin />;

  const showMessages = () => {
    setIsVisibleModal(true);
    setModalTitle('Mensajes');
    setModalContent(
      <>
        <div style={{ position: 'float', textAlign: 'right' }}>
          <Button type="link" onClick={() => addMessage()}>
            Nuevo Mensaje <PlusOutlined />
          </Button>
        </div>

        <MessageList turn={turnUser} />
      </>
    );
  };

  const addMessage = () => {
    setIsVisibleModal(true);
    setModalTitle('Nuevo mensaje');
    setModalContent(
      <>
        <InfoCircleOutlined />{' '}
        <span>
          Mensajes internos entre medico y secretaria. Son solo visibles por
          usuarios con el rol Administrativo.
        </span>
        <Divider />
        <MessageForm
          setIsVisibleModal={setIsVisibleModal}
          type="Medico"
          turn={turnUser}
        />
      </>
    );
  };

  const printTurns = async () => {
    message.loading('Generando documento. Por favor espere...', 0);

    let { name, lastname } =
      turnType === 'studie'
        ? await studies.find((studie) => studie.id === turnUser)
        : await users.find((user) => user._id === turnUser);

    let data = await turn?.shifts?.sort(
      (a, b) => moment(a.hourStart).unix() - moment(b.hourStart).unix()
    );

    const doc = (
      <Document>
        <PdfTurn
          turn={data}
          name={turnType === 'studie' ? name : `${name} ${lastname}`}
          type={turnType}
          date={moment(date).format('DD/MM/YYYY')}
          statsTurns={statsTurns}
        />
      </Document>
    );

    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(
      blob,
      `Turnero - ${
        turnType === 'studie' ? name : `${name} ${lastname}`
      } - ${moment(date).format('DD/MM/YYYY')}`
    );
    message
      .success('El documento se ha generado con exito.', 1)
      .then(() => message.destroy());
  };

  function getListData(value) {
    let listData;

    const day = monthData?.find(
      (turn) =>
        moment(value).format('DD') == turn.day &&
        moment(value).format('MM') == turn.month &&
        moment(value).format('YYYY') == turn.year
    );
    if (day) {
      listData = day.shifts
        .filter((day) => !day.exclude && !day.softDelete)
        .sort(
          (a, b) => moment(a.hourStart).unix() - moment(b.hourStart).unix()
        );
    }

    return listData || [];
  }

  let config = data?.scheduleConfig?.hours.map((h) => [].concat(h?.days));

  const dateCellRender = (value) => {
    const listData = getListData(value);

    const work = config?.flat().includes(moment(value).format('dd'));

    const exclude = data.scheduleConfig?.excludeDays?.some(
      (e) =>
        moment.tz(e, tenantTimezone).startOf('day').toISOString() ===
        moment.tz(value, tenantTimezone).startOf('day').toISOString()
    );

    let feriado = feriados?.filter(
      (feriado) =>
        feriado.mes == moment(value).format('M') && feriado.dia === value.date()
    );

    return (
      <div style={{ width: '100%' }}>
        <Tooltip title="Ir al día">
          <Button
            type="link"
            size="small"
            style={
              listData.length > 0
                ? {
                    textAlign: 'center',
                    backgroundColor: '#1764ab',
                    borderRadius: '2px',
                    color: '#fff',
                    width: '100%',
                  }
                : {
                    textAlign: 'center',
                    borderRadius: '2px',
                    width: '100%',
                    backgroundColor: '#f8f9fa',
                    color: 'grey',
                  }
            }
            onClick={() => {
              setDate(
                moment
                  .tz(value._d, tenantTimezone)
                  .format('YYYY-MM-DDTHH:mm:ss')
              );
              if (date) {
                moment(date).diff(moment(), 'month') > 4 &&
                  message.warning(
                    'Revise la fecha seleccionada (4 meses mayor al dia de hoy).'
                  );
              }
              setViewCalendar(false);
            }}
          >
            {listData.length > 0
              ? `${listData?.length} Turnos`
              : !exclude && work && 'Libre'}
          </Button>
        </Tooltip>
        <h1
          style={
            exclude
              ? {
                  color: '#fff',
                  textAlign: 'center',
                  backgroundColor: '#f9cb9c',
                }
              : !work
              ? {
                  color: '#fff',
                  textAlign: 'center',
                  backgroundColor: '#ea9999',
                }
              : {}
          }
        >
          {listData.length === 0 &&
            (exclude ? 'Excluido' : !work && 'No trabaja')}
          {feriado?.length === 1 && `Feriado - ${feriado[0].motivo}`}
        </h1>
        <ul>
          {listData?.map((item) => (
            <>
              <li key={item} style={{ marginBottom: '4%' }}>
                <span
                  style={{
                    backgroundColor: '#e8e8e8',
                    padding: '2px',
                    borderRadius: '2px',
                  }}
                >
                  {moment(item.hourStart).format('HH:mm')}
                </span>
                <span style={{ textTransform: 'capitalize' }}>
                  {' '}
                  {item?.patient?.name || item?.name}
                </span>
              </li>
            </>
          ))}
        </ul>
      </div>
    );
  };

  const viewCancelTurns = async () => {
    let cancelTurns = await turn?.shifts
      ?.filter((t) => t?.softDelete)
      .sort(
        (a, b) => moment(a?.hourStart).unix() - moment(b?.hourStart).unix()
      );

    setIsVisibleModal(true);
    setModalTitle(`Turnos cancelados ${moment(date).format('DD/MM/YY')}`);
    setModalContent(
      <>
        <h3>{`Cantidad: ${cancelTurns?.length || 0}`}</h3>
        {cancelTurns?.length > 0 && (
          <TableChart
            generate={cancelTurns}
            data={{ dataChart: cancelTurns }}
            sendColumns={[
              {
                title: 'Hora',
                dataIndex: 'hourStart',
                key: 'hourStart',
                align: 'center',
                render: (hourStart) => (
                  <span>
                    {moment.tz(hourStart, tenantTimezone).format('HH:mm')}
                  </span>
                ),
                sorter: (a, b) =>
                  moment(a.hourStart).unix() - moment(b.hourStart).unix(),
              },
              {
                title: 'DNI',
                dataIndex: 'dni',
                key: 'dni',
                align: 'center',
                render: (text, record, index) =>
                  record.patient?.dni ? record.patient?.dni : record?.dni,
              },
              {
                title: 'Paciente',
                dataIndex: 'patientName',
                key: 'patientName',
                align: 'center',
                render: (text, record, index) => (
                  <span style={{ textTransform: 'capitalize' }}>
                    {record.patient?.name ? record.patient?.name : record?.name}
                  </span>
                ),
              },
            ]}
            pageSize={8}
          />
        )}
      </>
    );
  };

  return (
    <>
      <div style={{ textAlign: 'center', marginBottom: 5 }}>
        <Statistic
          size="small"
          value={`Atendidos ${statsTurns?.quantityCheck} de ${statsTurns?.quantity}`}
          valueStyle={
            ((statsTurns?.quantityCheck * 100) / statsTurns?.quantity).toFixed(
              0
            ) >= 60
              ? { color: '#3f8600', fontSize: '10pt' }
              : (
                  (statsTurns?.quantityCheck * 100) /
                  statsTurns?.quantity
                ).toFixed(0) >= 50
              ? { color: '#cdb900', fontSize: '10pt' }
              : { color: '#cf1322', fontSize: '10pt' }
          }
          prefix={
            <h1
              style={{
                fontSize: '1.6em',
                marginBottom: 0,
              }}
            >
              Mis Turnos <ScheduleOutlined />
            </h1>
          }
          suffix={
            <Progress
              percent={
                statsTurns?.quantity &&
                (
                  (statsTurns?.quantityCheck * 100) /
                  statsTurns?.quantity
                ).toFixed(0)
              }
              strokeColor={
                (
                  (statsTurns?.quantityCheck * 100) /
                  statsTurns?.quantity
                ).toFixed(0) >= 60
                  ? '#3f8600'
                  : (
                      (statsTurns?.quantityCheck * 100) /
                      statsTurns?.quantity
                    ).toFixed(0) >= 50
                  ? '#cdb900'
                  : '#cf1322'
              }
              status="normal"
              size={30}
              steps={5}
            />
          }
        />
        {(role === 2 ||
          role === 0 ||
          statsTurns.price === statsTurns.pricePercent) && (
          <>
            <span style={{ color: 'gray', fontSize: '10pt' }}>
              Liquidacion Diaria <strong>Total</strong>:{' '}
              {statsTurns.price.toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
              })}
            </span>
            <Divider type="vertical" />
            <span style={{ color: 'gray', fontSize: '10pt' }}>
              Liquidacion Diaria{' '}
              <strong>
                {cashRegisters?.length > 0 ? 'Cobrada' : 'Atendidos'}
              </strong>
              :{' '}
              {statsTurns.priceCheck.toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
              })}
            </span>
            <br />
          </>
        )}
        {statsTurns.price !== statsTurns.pricePercent && (
          <>
            <Tooltip
              title={
                turnType === 'studie' &&
                'Total de ingreso para este usuario por los estudios que efectuo'
              }
            >
              <span style={{ color: 'gray', fontSize: '10pt' }}>
                Liquidacion Diaria <strong>Profesional Total</strong>:{' '}
                {statsTurns.pricePercent.toLocaleString('es-AR', {
                  style: 'currency',
                  currency: 'ARS',
                  minimumFractionDigits: 2,
                })}
              </span>
            </Tooltip>
            <Divider type="vertical" />
            <span style={{ color: 'gray', fontSize: '10pt' }}>
              Liquidacion Diaria <strong>Profesional Atendidos</strong>:{' '}
              {statsTurns.pricePercentCheck.toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
              })}
            </span>
          </>
        )}
      </div>
      <Row gutter={[24, 24]} style={{ marginBottom: 5 }}>
        <Col xs={24} sm={6} md={9}>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Seleccione un medico.. (*)"
            optionFilterProp="children"
            onChange={(value, options) => {
              options?.key === 'doctor'
                ? dispatch(onSearchValueChange({ turnType: options.key }))
                : options?.key
                ? dispatch(onSearchValueChange({ turnType: 'studie' }))
                : dispatch(onSearchValueChange({ turnType: null }));

              dispatch(onSearchValueChange({ turnUser: value }));
            }}
            defaultValue={turnUser}
            allowClear
          >
            {type === 'Medico' && (
              <Select.Option key="doctor" value={userId}>
                {name} {lastname}
              </Select.Option>
            )}
            {studies?.map((studie, index) => {
              if (
                viewStudies?.find((e) => e === studie.id) ||
                role === 2 ||
                studie?.view
              ) {
                return (
                  <Select.Option key={index} value={studie.id}>
                    Estudio - {studie.name}
                  </Select.Option>
                );
              }
            })}
            {(type === 'Administrativo' || role === 2) &&
              users?.map((user, index) => {
                return (
                  user._id !== userId && (
                    <Select.Option value={user._id}>
                      {user.name} {user.lastname}
                    </Select.Option>
                  )
                );
              })}
          </Select>
        </Col>
        <Col xs={24} sm={6} md={7}>
          <DatePicker
            dateRender={(current) => {
              const style = {};
              let feriado = feriados?.filter(
                (feriado) =>
                  feriado.mes == moment(current).format('M') &&
                  feriado.dia === current.date()
              );
              if (feriado.length === 1) {
                style.border = '1px solid #c70000';
                style.borderRadius = '50%';
              }
              return (
                <Tooltip title={feriado.length === 1 && `${feriado[0].motivo}`}>
                  <div className="ant-picker-cell-inner" style={style}>
                    {current.date()}
                  </div>
                </Tooltip>
              );
            }}
            inputReadOnly
            style={{ width: '100%' }}
            format={'DD-MM-YYYY'}
            placeholder="Seleccione una fecha.. (*)"
            value={moment.tz(date, tenantTimezone)}
            onChange={(value) => {
              setDate(
                moment.tz(value, tenantTimezone).format('YYYY-MM-DDTHH:mm:ss')
              );
              if (value) {
                moment(value).diff(moment(), 'month') > 4 &&
                  message.warning(
                    'Revise la fecha seleccionada (4 meses mayor al dia de hoy).'
                  );
              }
            }}
            locale={locale}
            showToday={true}
            allowClear={false}
            disabled={viewCalendar}
          />
        </Col>
        <Col xs={3} sm={2} md={1}>
          <Tooltip title="Mensajes Internos / Notificaciones turnos online">
            <Badge count={messages?.length} size="small">
              <Button
                disabled={!turnUser}
                type="link"
                onClick={() => {
                  messages?.length > 0 ? showMessages() : addMessage();
                }}
              >
                <MessageOutlined />
              </Button>
            </Badge>
          </Tooltip>
        </Col>
        <Col xs={3} sm={2} md={1}>
          <Tooltip title="Imprimir Turnero">
            <Button
              type="link"
              disabled={!turn || !turnUser}
              onClick={() => {
                printTurns();
              }}
            >
              <PrinterOutlined />
            </Button>
          </Tooltip>
        </Col>
        <Col xs={3} sm={2} md={1}>
          <Tooltip
            title={
              statsTurns.quantity > 0
                ? 'No puede excluir un dia que tiene turnos asignados'
                : 'Excluir / Habilitar dia'
            }
          >
            <Button
              type="link"
              disabled={
                !turnUser ||
                (!viewCalendar &&
                  statsTurns.quantity > 0 &&
                  data.scheduleConfig?.excludeDays.filter(
                    (e) =>
                      moment
                        .tz(e, tenantTimezone)
                        .startOf('day')
                        .toISOString() ===
                      moment
                        .tz(date, tenantTimezone)
                        .startOf('day')
                        .toISOString()
                  ).length === 0) ||
                (viewCalendar &&
                  monthData.filter(
                    (e) =>
                      moment
                        .tz(e.date, tenantTimezone)
                        .startOf('day')
                        .toISOString() ===
                      moment
                        .tz(date, tenantTimezone)
                        .startOf('day')
                        .toISOString()
                  ).length > 0) ||
                !config?.flat().includes(moment(date).format('dd'))
              }
              onClick={async () => {
                let token = await getAccessTokenApi();
                let data =
                  turnType === 'studie'
                    ? await studies.find((studie) => {
                        if (studie.id === turnUser) return studie;
                      })
                    : await users.find((user) => user._id === turnUser);

                await updateExcludeDayScheduleApi({
                  token,
                  data: {
                    day: moment(date).toISOString(),
                    type:
                      data.scheduleConfig?.excludeDays.filter(
                        (e) =>
                          moment
                            .tz(e, tenantTimezone)
                            .startOf('day')
                            .toISOString() ===
                          moment
                            .tz(date, tenantTimezone)
                            .startOf('day')
                            .toISOString()
                      ).length === 0 && 'add',
                  },
                  userId: turnUser,
                }).then(async (response) => {
                  if (response?.code !== 200) {
                    notification['error']({ message: response.message });
                    setReload(true);
                  } else {
                    notification['success']({ message: response.message });
                    setReload(true);
                    turnType === 'doctor' && dispatch(onUserDataRead());
                    turnType === 'studie' && dispatch(onFloorsRead());
                  }
                });
              }}
            >
              <ExclamationCircleOutlined />
            </Button>
          </Tooltip>
        </Col>
        <Col xs={3} sm={2} md={1}>
          <Tooltip title="Configurar Agenda">
            <Badge count={data?.scheduleConfig?.hours?.length === 0 ? 1 : 0}>
              <Button
                type="link"
                disabled={!turnUser}
                onClick={() => {
                  scheduleConfiguration();
                }}
              >
                <SettingOutlined />
              </Button>
            </Badge>
          </Tooltip>
        </Col>
        <Col xs={3} sm={2} md={1}>
          <Tooltip
            title={viewCalendar ? 'Ver listado por dia' : 'Ver como calendario'}
          >
            <Button
              type="link"
              disabled={!turnUser}
              onClick={async () => {
                setViewCalendar(!viewCalendar);
              }}
            >
              {viewCalendar ? <UnorderedListOutlined /> : <CalendarOutlined />}
            </Button>
          </Tooltip>
        </Col>
        <Col xs={3} sm={2} md={1}>
          <Tooltip title="Turnos cancelados hoy">
            <Badge
              count={turn?.shifts?.filter((t) => t.softDelete)?.length}
              size="small"
            >
              <Button
                disabled={viewCalendar || !turnUser || !turn}
                type="link"
                onClick={() => {
                  viewCancelTurns();
                }}
              >
                <WarningOutlined />
              </Button>
            </Badge>
          </Tooltip>
        </Col>
      </Row>

      {viewCalendar ? (
        loading ? (
          <Spin />
        ) : (
          <div className="site-calendar-demo-card">
            <Anchor offsetTop={70}>
              <h3 className="title-date">
                {moment(date).format('MMMM')} {moment(date).format('YYYY')}
              </h3>
            </Anchor>
            <Calendar
              dateCellRender={dateCellRender}
              locale={locale}
              value={moment(date)}
              onSelect={(value) => {
                setDate(
                  moment.tz(value, tenantTimezone).format('YYYY-MM-DDTHH:mm:ss')
                );
              }}
              onPanelChange={(value) => {
                setDate(
                  moment.tz(value, tenantTimezone).format('YYYY-MM-DDTHH:mm:ss')
                );
                setReload(true);
              }}
            />
          </div>
        )
      ) : null}

      {turnUser && !viewCalendar && (
        <ListTurns
          slots={slots}
          turnId={turn?._id}
          turns={turn?.shifts.sort(
            (a, b) => moment(a?.hourStart).unix() - moment(b?.hourStart).unix()
          )}
          turnUser={turnUser}
          setReload={setReload}
          size={size}
          turnType={turnType}
          loading={loading}
          scheduleConfig={data?.scheduleConfig}
          date={date}
          scheduleDay={scheduleDay}
        />
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'80%'}
      >
        {modalContent}
      </Modal>
    </>
  );
}
