import { ON_BANK_ACCOUNT_READ, ON_BANK_ACCOUNT_READING } from './types';
import { getAllBankAccountsApi } from '../api/bankAccount';
import { getAccessTokenApi } from '../api/auth';

export const onBankAccountRead =
  (view = 'summary') =>
  async (dispatch) => {
    let token = await getAccessTokenApi();

    dispatch({ type: ON_BANK_ACCOUNT_READING });

    return getAllBankAccountsApi(token, view).then(async (response) => {
      await dispatch({
        type: ON_BANK_ACCOUNT_READ,
        payload: response.bankAccounts,
      });
    });
  };
