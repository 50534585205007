import { basePath, apiVersion } from './config';

export async function getTransactionApi(token, cashRegister) {
  const url = `${basePath}/${apiVersion}/expense/${cashRegister}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function addTransactionApi(token, expenseData) {
  const url = `${basePath}/${apiVersion}/expense`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(expenseData),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}

export async function transferBetweenCashRegistersApi(token, data) {
  const url = `${basePath}/${apiVersion}/transfer-cash`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err;
  }
}
