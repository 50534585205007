import React, { useState, useEffect } from 'react';
import {
  Button,
  Tooltip,
  PageHeader,
  notification,
  Modal as ModalAntd,
  message,
} from 'antd';
import './Evolution.scss';
import EvolutionForm from '../../components/EvolutionForm';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal';
import { getEvolutionApi, deleteEvolutionApi } from '../../api/evolution';
import './Evolution.scss';
import PermissionsAsigner from '../../components/PermissionsAsigner';
import { ROLES } from '../../utils/constants';
import {
  PlusSquareOutlined,
  MedicineBoxOutlined,
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  CommentOutlined,
  FilePdfOutlined,
  EyeOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import NewsForm from '../../components/NewsForm';
import moment from 'moment';
import { getAccessTokenApi } from '../../api/auth';
import {
  ListDates,
  ViewInfo,
  PageListAndView,
} from '../../components/ListAndDetail';
import Spin from '../../components/Spin';
import { PdfConsultation } from '../../components/PdfReports';
import { getSignatureApi } from '../../api/user';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

export default function Evolution(props) {
  const { id } = props.match.params;
  const { path } = props.match;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [data, setData] = useState(null);
  const [dataAll, setDataAll] = useState(null);
  const [evolutions, setEvolutions] = useState();
  const [reload, setReload] = useState(false);
  const [order, setOrder] = useState('desc');
  const [newEvolution, setNewEvolution] = useState(false);

  const { incomesActive, incomesHistory } = useSelector(
    (store) => store.incomeData
  );

  const { organization, tenant } = useSelector((store) => store.tenantData);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      setNewEvolution(false);
      getEvolutionApi(id, order, token)
        .then((response) => {
          setEvolutions(response.evolutions);
          response.evolutions.length > 0
            ? setData({
                _id: response.evolutions[0]._id,
                description: response.evolutions[0].description,
                user: response.evolutions[0].user,
                date: response.evolutions[0].date,
              })
            : setData();
        })
        .catch((err) => {});

      setReload(false);
    };
    getData();
  }, [reload, order]);

  const viewEvolution = (evolution) => {
    setData({
      _id: evolution._id,
      description: evolution.description,
      user: evolution.user,
      date: evolution.date,
    });
    window.scroll({
      top: '500',
      behavior: 'instant',
    });
  };

  const newsIncomeModal = (income) => {
    setIsVisibleModal(true);
    setModalTitle(`Agregar novedad a ${income.name}`);
    setModalContent(
      <NewsForm income={income} setIsVisibleModal={setIsVisibleModal} />
    );
  };

  var income = {};
  if (path === '/evolution-history/:id') {
    incomesHistory.find((i) => {
      if (i._id === id) {
        income = i;
      }
    });
  } else {
    incomesActive.find((i) => {
      if (i._id === id) {
        income = i;
      }
    });
  }

  const viewAll = () => {
    const data = [];

    evolutions &&
      evolutions.map((evolution) =>
        data.push({
          description: evolution.description,
          user: evolution.user,
          date: evolution.date,
        })
      );
    setData(null);
    setDataAll(data);
    window.scroll({
      top: '500',
      behavior: 'instant',
    });
  };

  const printEvolution = async () => {
    const getProps = async () => {
      try {
        const token = await getAccessTokenApi();
        let consultations = dataAll ? dataAll : [data];
        let signatures = Array(consultations?.length);
        await Promise.all(
          consultations?.map(async (data, index) => {
            data.user?.signature &&
              (await getSignatureApi(data.user.signature, token)
                .then((response) => {
                  if (response?.status !== 200) {
                    signatures[index] = null;
                  } else {
                    signatures[index] = response.url;
                  }
                })
                .catch((err) => console.log(err)));
          })
        );

        return {
          dataConsultations: { consultations, signatures: signatures },
          organization,
          tenant,
          patient: income,
          title: 'Detalle evolución',
        };
      } catch {
        message.error('Error al generar documento. Intente nuevamente');
      }
    };

    message.loading(
      'Generando documento. Por favor espere... Esta acción puede tardar...',
      0
    );
    const props = await getProps();

    const doc = <PdfConsultation {...props} />;

    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Evoluciones - ${income.name}`);
    message
      .success('El documento se ha generado con exito.', 1)
      .then(() => message.destroy());
  };

  if (!evolutions) {
    return <Spin />;
  }

  return (
    <div className="evolution">
      <div className="evolution-header">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={income?.bed?.name}
          subTitle={
            <Tooltip
              title={`${income?.bed?.name} ${income?.name} - ${
                income?.age
              } años | Fecha Ingreso: ${moment(income.date).format(
                'DD/MM/YYYY'
              )}`}
            >
              {income?.name} - {income?.age} años | Fecha Ingreso:{' '}
              {moment(income?.date).format('DD/MM/YYYY')}
            </Tooltip>
          }
          extra={
            path === '/evolution/:id' && [
              <Tooltip title="Informes">
                <Link to={`/pdf-report/${income?._id}`}>
                  <Button type="link">
                    <FilePdfOutlined />
                  </Button>
                </Link>
              </Tooltip>,
              <Tooltip title="Indicaciones">
                <Link
                  to={{
                    pathname: `/medicine/${income?._id}`,
                    hash: props.location.hash,
                  }}
                >
                  <Button type="link">
                    <MedicineBoxOutlined />
                  </Button>
                </Link>
              </Tooltip>,
              <Tooltip title="Enfermería">
                <Link
                  to={
                    props.location.hash === '#Dialisis'
                      ? `/dialisis-controls/${income._id}/view`
                      : `/view-infarmary/${income?._id}`
                  }
                >
                  <Button
                    type="link"
                    style={{
                      color: '#8e0d1a',
                    }}
                  >
                    <PlusSquareOutlined />
                  </Button>
                </Link>
              </Tooltip>,
              <PermissionsAsigner type="Medico">
                <Tooltip title="Ver Ingreso">
                  <Link
                    to={{
                      pathname: `/view-income/${income?._id}`,
                      hash: props.location.hash,
                    }}
                  >
                    <Button type="link">
                      <EyeOutlined />
                    </Button>
                  </Link>
                </Tooltip>
              </PermissionsAsigner>,
            ]
          }
        />
      </div>

      <PageListAndView
        datesTitle="Evoluciones"
        order={order}
        setOrder={setOrder}
        viewAll={viewAll}
        componentDate={<ListDates data={evolutions} viewData={viewEvolution} />}
        detailTitle="Detalle evolución"
        data={data}
        dataAll={
          dataAll && (
            <div className="view-info">
              {newEvolution && (
                <EvolutionForm
                  incomeId={id}
                  setIsVisibleModal={setIsVisibleModal}
                  setReload={setReload}
                  dischargeDate={evolutions[0]?.income?.dischargeDate}
                  date={evolutions[0]?.income?.date}
                  incomeType={income?.type}
                  bedType={props.location.hash}
                />
              )}
              {dataAll?.map((data, index) => (
                <>
                  <ViewInfo data={data} key={index} />
                </>
              ))}
            </div>
          )
        }
        componentDetail={
          <div>
            {newEvolution && (
              <EvolutionForm
                incomeId={id}
                setIsVisibleModal={setIsVisibleModal}
                setReload={setReload}
                dischargeDate={income?.dischargeDate}
                date={income?.date}
                incomeType={income?.type}
                bedType={props.location.hash}
              />
            )}
            <ViewInfo data={data} />
          </div>
        }
        extra={
          <>
            {!newEvolution && (
              <PermissionsAsigner
                requiredRole={ROLES.OWNER}
                visible={path === '/evolution-history/:id' && ROLES.SECRETARY}
              >
                <DeleteEvoltion data={data} setReload={setReload} />
              </PermissionsAsigner>
            )}
            {path === '/evolution/:id' ? (
              <>
                <Tooltip title="Imprimir Evolución">
                  <Button
                    type="link"
                    onClick={() => {
                      printEvolution();
                    }}
                    disabled={!data && !dataAll}
                  >
                    <PrinterOutlined />
                  </Button>
                </Tooltip>
                <PermissionsAsigner requiredRole={ROLES.ADMIN}>
                  <Tooltip title="Agregar Novedad">
                    <Button type="link" onClick={() => newsIncomeModal(income)}>
                      <CommentOutlined />
                    </Button>
                  </Tooltip>
                </PermissionsAsigner>
                <PermissionsAsigner
                  requiredRole={ROLES.ADMIN}
                  visible={ROLES.EMPLOYEE}
                >
                  <Tooltip
                    title={
                      newEvolution ? 'Deshacer Evolución' : 'Nueva Evolución'
                    }
                  >
                    <Button
                      type="link"
                      onClick={() => setNewEvolution(!newEvolution)}
                    >
                      {newEvolution ? (
                        <MinusCircleOutlined />
                      ) : (
                        <PlusOutlined />
                      )}
                    </Button>
                  </Tooltip>
                </PermissionsAsigner>
              </>
            ) : (
              <PermissionsAsigner
                requiredRole={ROLES.ADMIN}
                visible={ROLES.SECRETARY}
              >
                <Tooltip
                  title={
                    newEvolution ? 'Deshacer Evolución' : 'Nueva Evolución'
                  }
                >
                  <Button
                    type="link"
                    onClick={() => setNewEvolution(!newEvolution)}
                  >
                    {newEvolution ? <MinusCircleOutlined /> : <PlusOutlined />}
                  </Button>
                </Tooltip>
              </PermissionsAsigner>
            )}
          </>
        }
      />
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'50%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function DeleteEvoltion(props) {
  const { data, setReload } = props;

  const deleteEvolution = () => {
    ModalAntd.confirm({
      title: 'Eliminar evolución',
      content: `¿ Estas seguro que quieres eliminar la evolución del ${moment(
        data.date
      ).format('DD-MM HH:mm')}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        const token = await getAccessTokenApi();
        deleteEvolutionApi(data._id, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              setReload(true);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
      },
    });
  };

  return (
    <Tooltip title="Eliminar">
      <Button type="link" onClick={() => deleteEvolution()} disabled={!data}>
        <DeleteOutlined style={data && { color: 'red' }} />
      </Button>
    </Tooltip>
  );
}
