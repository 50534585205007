import {
  ON_FLOORS_READ,
  ON_FLOORS_READING,
  ON_PRIMARY_READING,
  ON_PRIMARY_READ,
} from '../actions/types';

const INITIAL_STATE = {
  tenant: '',
  organization: '',
  organizationType: '',
  logos: 1,
  organizationPhone: '',
  tenantLogo: 'logo',
  floors: [],
  studies: [],
  medicalSpecialities: [],
  down: false,
  multitenant: false,
  tenantType: '',
  tenantId: '',
  drugStores: [],
  reportTypes: [],
  billingOrganizations: [],
  cashRegisters: [],
  cardTypes: [],
  healthCenterIdRecetario: '',
  tenantTimezone: 'America/Argentina/Buenos_Aires',
  countryCode: '+54',
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_FLOORS_READING:
      return { ...state, loading: true };
    case ON_FLOORS_READ:
      return {
        ...state,
        floors: action.payload.floors,
        organization: action.payload.organization,
        tenant: action.payload.tenant,
        logos: action.payload.logos,
        studies: action.payload.studies,
        organizationType: action.payload.organizationType,
        down: action.payload.down || false,
        tenantType: action.payload.tenantType,
        tenantId: action.payload.tenantId,
        drugStores: action.payload.drugStores,
        tenantLogo: action.payload.tenantLogo,
        organizationPhone: action.payload.organizationPhone,
        reportTypes: action.payload?.reportTypes,
        quantitySchedules: action.payload?.quantitySchedules,
        billingOrganizations: action.payload?.billingOrganizations,
        cashRegisters: action.payload?.cashRegisters,
        healthCenterIdRecetario: action.payload?.healthCenterId,
        tenantTimezone: action.payload?.tenantTimezone,
        countryCode: action.payload?.countryCode,

        cardTypes: action.payload?.cardTypes,
        loading: false,
      };
    case ON_PRIMARY_READING:
      return { ...state, loading: true };
    case ON_PRIMARY_READ:
      return {
        ...state,
        organization: action.payload.organization,
        tenant: action.payload.tenant,
        logos: action.payload.logos,
        organizationType: action.payload.organizationType,
        medicalSpecialities: action.payload.medicalSpecialities,
        down: action.payload.down || false,
        multitenant: action.payload.multitenant || false,
        tenantTimezone: action.payload?.tenantTimezone,
        countryCode: action.payload?.countryCode,

        loading: false,
      };
    default:
      return state;
  }
};
