import React from 'react';
import { Text, Image, View } from '@react-pdf/renderer';
import { styleBody } from './styles';

export function PdfSignature({
  signature,
  name,
  lastname,
  medicalSpeciality,
  mp,
  licenseType,
  userType,
}) {
  return (
    <View wrap={false} style={styleBody.signature}>
      <View
        render={() =>
          signature && (
            <Image src={signature} style={styleBody.signatureImage} />
          )
        }
      />
      <Text style={styleBody.signatureName}>
        {name} {lastname}
      </Text>

      <View
        render={() => (
          <View style={styleBody.signature}>
            <Text style={styleBody.signatureDescription}>
              {medicalSpeciality && userType}
            </Text>
          </View>
        )}
      />
      <View
        render={() => (
          <View>
            <Text style={styleBody.signatureDescription}>
              {medicalSpeciality && `ESP en ${medicalSpeciality}`}
            </Text>
          </View>
        )}
      />
      <View
        render={() => (
          <View>
            <Text style={styleBody.signatureDescription}>
              {mp &&
                medicalSpeciality &&
                `${licenseType === 'nacional' ? 'MN' : 'MP'} ${mp}`}
            </Text>
          </View>
        )}
      />
    </View>
  );
}
