import React, { useState } from 'react';
import { Button, Descriptions, Tag, Tooltip } from 'antd';
import moment from 'moment';
import './PatientDescription.scss';
import { Link } from 'react-router-dom';
import {
  MailOutlined,
  PhoneOutlined,
  FilePdfOutlined,
  HistoryOutlined,
  ScheduleOutlined,
  FileTextOutlined,
  FileAddOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  CloseOutlined,
  CiCircleFilled,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { newPresciption } from '../../Prescriptions/NewPrescription';
import { getAccessTokenApi } from '../../../api/auth';
import { getHistoryTurnsPatientApi } from '../../../api/turn';
import { TableChart } from '../../Charts/TableChart';
import Modal from '../../Modal';
import { ROLES } from '../../../utils/constants';

export default function PatientDescription({ patient, urlHc }) {
  const { organizationType, studies, healthCenterIdRecetario, tenantTimezone } =
    useSelector((store) => store.tenantData);
  const { role, modulesAccess, licenseType } = useSelector(
    (store) => store.userData?.user
  );
  const userId = useSelector((store) => store.userData?.user?._id);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);

  const viewHistoryTurns = async () => {
    const token = await getAccessTokenApi();
    let historyTurns = await getHistoryTurnsPatientApi({
      token,
      patient: patient._id,
    });

    setIsVisibleModal(true);
    setModalTitle(
      <span style={{ textTransform: 'capitalize' }}>
        Turnos: {patient?.name}
      </span>
    );
    setModalContent(
      <TableChart
        generate={patient._id}
        data={historyTurns}
        sendColumns={[
          {
            title: 'Fecha',
            dataIndex: 'hourStart',
            key: 'hourStart',
            align: 'center',
            render: (hourStart) => (
              <span>
                {moment.tz(hourStart, tenantTimezone).format('DD/MM/YY HH:mm')}
              </span>
            ),
            sorter: (a, b) =>
              moment(a.hourStart).unix() - moment(b.hourStart).unix(),
          },
          {
            title: 'Efector',
            dataIndex: 'medicoEfector',
            key: 'medicoEfector',
            render: (text, record, index) =>
              record.medicoEfector
                ? record.medicoEfector
                : record.medicoTurnera,
            sorter: (a, b) =>
              a?.record?.medicoEfector?.localeCompare(b?.record?.medicoEfector),
          },
          {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text, record, index) =>
              (record.status ||
                moment(record.hourStart).unix() >= moment().unix()) && (
                <Tag
                  color={
                    record?.softDelete
                      ? ''
                      : record?.status === 'Sala de Espera'
                      ? 'yellow'
                      : record?.status === 'Atendido'
                      ? 'green'
                      : record?.status === 'Ausente'
                      ? 'red'
                      : 'orange'
                  }
                  icon={
                    record?.softDelete ? (
                      <CloseCircleOutlined />
                    ) : record?.status === 'Sala de Espera' ? (
                      <SyncOutlined />
                    ) : record?.status === 'Atendido' ? (
                      <CheckCircleOutlined />
                    ) : record?.status === 'Ausente' ? (
                      <MinusCircleOutlined />
                    ) : (
                      <ExclamationCircleOutlined />
                    )
                  }
                >
                  {record?.softDelete
                    ? 'Cancelado'
                    : record?.status || 'Pendiente'}
                </Tag>
              ),
          },
          {
            title: 'Valor',
            dataIndex: 'dataChart',
            key: 'price',
            align: 'center',
            render: (text, record) =>
              role === 2 || role === 0 ? (
                <span>
                  {record.price?.toLocaleString('es-AR', {
                    style: 'currency',
                    currency: 'ARS',
                    minimumFractionDigits: 2,
                  })}
                </span>
              ) : (
                record.medicoEfectorId === userId && (
                  <span>
                    {record.pricePercent?.toLocaleString('es-AR', {
                      style: 'currency',
                      currency: 'ARS',
                      minimumFractionDigits: 2,
                    })}
                  </span>
                )
              ),
          },
          {
            title: 'Estudio',
            dataIndex: 'studies',
            key: 'studies',
            align: 'center',
            render: (text, record, index) => {
              let { name } = (!record?.medicoTurnera &&
                studies.find((studie) => record.user === studie.id)) || {
                name: '',
              };
              return <span>{record.studies?.name || name}</span>;
            },
            sorter: (a, b) =>
              a?.record?.studies?.name.localeCompare(b?.record?.studies?.name),
          },
          {
            title: 'Observación',
            dataIndex: 'description',
            key: 'description',
          },
        ]}
        pageSize={5}
      />
    );
  };

  return (
    <div>
      <div className="patient-actions">
        <Tooltip title="Nueva orden">
          <Button
            type="link"
            onClick={() => newPresciption(patient._id, 'order')}
            disabled={!healthCenterIdRecetario || !licenseType}
          >
            <span className="patient-actions_title">Nueva Orden</span>
            <FileAddOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="Nueva receta">
          <Button
            type="link"
            onClick={() => newPresciption(patient._id, 'prescription')}
            disabled={!healthCenterIdRecetario || !licenseType}
          >
            <span className="patient-actions_title">Nueva Receta</span>
            <FileTextOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="Historial de turnos">
          <Button type="link" onClick={() => viewHistoryTurns()}>
            <span className="patient-actions_title">Turnos</span>
            <ScheduleOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="Ver Estudios">
          <Link
            to={{
              pathname: `/patient-studies/${patient._id}/${patient.name}`,
              search: patient.notificationPhone?.number
                ? `${patient.notificationPhone?.countryCode}${patient.notificationPhone?.regionCode}${patient.notificationPhone?.number}`
                : '',
            }}
          >
            <Button type="link">
              <span className="patient-actions_title">Estudios</span>
              <FilePdfOutlined />
            </Button>
          </Link>
        </Tooltip>
        {urlHc &&
          (role !== ROLES.SECRETARY.value ||
            modulesAccess?.includes('Ver h.c amb')) && (
            <Link to={urlHc}>
              <Tooltip title="Informacion Paciente">
                <Button type="link">
                  <span className="patient-actions_title">Consultas</span>
                  <InfoCircleOutlined />
                </Button>
              </Tooltip>
            </Link>
          )}
        {organizationType === 'hospitalaria' && (
          <Tooltip title="Ver Internaciones">
            <Link
              to={`/incomes-history-patient/${patient.dni}/${patient.name}`}
            >
              <Button type="link">
                <span className="patient-actions_title">Internaciones</span>
                <HistoryOutlined />
              </Button>
            </Link>
          </Tooltip>
        )}
      </div>
      <Descriptions
        column={{ xs: 1, sm: 1, md: 2 }}
        title={`DNI: ${patient.dni}`}
      >
        <Descriptions.Item label="Obra Social Actual">
          {patient.socialWork}
        </Descriptions.Item>
        <Descriptions.Item label="Afiliado">
          {patient.affiliate}
        </Descriptions.Item>
        <Descriptions.Item label="Fecha de Nacimiento">
          {moment(patient.birthDate).format('DD/MM/YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label="Sexo">{patient.sex}</Descriptions.Item>
        <Descriptions.Item
          label="Ciudad de Origen"
          style={{ textTransform: 'capitalize' }}
        >
          {patient.city}
        </Descriptions.Item>
        <Descriptions.Item label="Contacto">
          <span>
            <PhoneOutlined />{' '}
            {patient?.notificationPhone?.number
              ? `${patient?.notificationPhone?.countryCode} - ${patient?.notificationPhone?.regionCode} - ${patient?.notificationPhone?.number}`
              : patient?.phone}
            <br />
            <MailOutlined /> {patient.email}
          </span>
        </Descriptions.Item>
        <Descriptions.Item
          label="Otros"
          style={{ textTransform: 'capitalize' }}
        >
          {patient?.others}
        </Descriptions.Item>
      </Descriptions>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'80%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
