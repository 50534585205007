import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  Alert,
  Col,
  Row,
  Tooltip,
  message,
} from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { addPaidTurnApi, cancelPaymentApi } from '../../api/turn';
import { getAccessTokenApi } from '../../api/auth';
import { getOpenCashRegisterApi } from '../../api/cashRegister';
import { paymentMethods } from '../../utils/constants';
import { onBankAccountRead } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';

const { Option } = Select;

const AddPaymentForm = ({
  turnId,
  shiftId,
  turn,
  setIsVisibleModal,
  setReload,
  turnUser,
  setModalTitle,
  setModalContent,
}) => {
  const [form] = Form.useForm();
  const [totalPayment, setTotalPayment] = useState(turn?.price || 0);
  const [cashRegisters, setCashRegisters] = useState([]);

  const dispatch = useDispatch();

  const { bankAccounts } = useSelector((store) => store.bankAccountData);
  const { cardTypes } = useSelector((store) => store.tenantData);

  const initialValues = {
    paymentMethods: [{ paymentMethod: undefined, amount: turn?.price || 0 }],
  };

  // Calcular la suma de los montos ingresados en cada método de pago
  const updateTotalPayment = () => {
    const payments = form.getFieldValue('paymentMethods') || [];
    const sum = payments.reduce(
      (acc, payment) => acc + (parseFloat(payment?.amount) || 0),
      0
    );
    setTotalPayment(sum);
  };

  useEffect(() => {
    turn?.paid ? form.setFieldsValue(turn) : form.resetFields();
  }, [shiftId]);

  useEffect(() => {
    // Cuando se monta el componente, actualiza el total por si ya hay un valor inicial
    updateTotalPayment();

    const getData = async () => {
      const token = await getAccessTokenApi();
      const response = await getOpenCashRegisterApi({ token });

      setCashRegisters(response?.data);

      dispatch(onBankAccountRead());

      // Asignar la caja por defecto si solo hay una
      if (response?.data.length === 1) {
        form.setFieldsValue({
          cashRegisterId: response.data[0]._id,
        });
      }
    };

    getData();
  }, [turn.paid]);

  const handleSubmit = async (values) => {
    try {
      const token = await getAccessTokenApi();

      const cancelPayment = async () => {
        const response = await cancelPaymentApi({
          token,
          turnId,
          shiftId,
          data: values,
        });
        if (response.code === 200) {
          message.success(response.message);
          setReload(true);
          setIsVisibleModal(false);
        } else {
          message.error(response.message);
        }
      };

      const processPayment = async () => {
        const response = await addPaidTurnApi({
          token,
          turnId,
          shiftId,
          paidData: values,
        });
        if (response.code === 200) {
          message.success(response.message);
          setReload(true);
          setIsVisibleModal(false);
        } else {
          message.error(response.message);
        }
      };

      if (turn?.paid) {
        setIsVisibleModal(true);
        setModalTitle('Anular pago');
        setModalContent(
          <CancelPaymentForm
            onCancel={cancelPayment}
            setReason={(reason) => (values.reason = reason)}
          />
        );
      } else {
        await processPayment();
      }
    } catch (error) {
      console.log('🚀 ~ handleSubmit ~ error:', error);
      message.error('Error del servidor, intente mas tarde.');
    }
  };

  // Componente separado para el formulario de anulación
  const CancelPaymentForm = ({ onCancel, setReason }) => (
    <div>
      <Col xs={24}>
        <Form.Item
          name="reason"
          rules={[{ required: true, message: 'Debe ingresar la consulta' }]}
        >
          <Input.TextArea
            placeholder={'Motivo de la anulación...'}
            autoSize={{ minRows: 2 }}
            onChange={(e) => setReason(e.target.value)}
          />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Button
          type="primary"
          style={{
            width: '100%',
            backgroundColor: 'red',
          }}
          onClick={onCancel}
        >
          Anular Cobro
        </Button>
      </Col>
    </div>
  );

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onValuesChange={updateTotalPayment} // Actualiza el total en tiempo real
      onFinish={handleSubmit}
    >
      <Form.Item
        label="Caja"
        name="cashRegisterId"
        rules={[
          { required: true, message: 'Por favor, debe seleccionar una caja.' },
        ]}
      >
        <Select
          placeholder="Selecciona una caja"
          disabled={turn?.paid}
          notFoundContent="No hay cajas abiertas para este usuario"
        >
          {cashRegisters?.map((register) => (
            <Option key={register._id} value={register._id}>
              {register.cashRegister} - Turno {register.shift}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Monto Total"
        name="paymentAmount"
        rules={[
          { required: true, message: 'Por favor, ingresa el monto total' },
        ]}
        initialValue={turn?.price} // Usar el precio de la variable turn
      >
        <Input type="number" disabled />
      </Form.Item>

      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          JSON.stringify(prevValues.paymentMethods) !==
          JSON.stringify(curValues.paymentMethods)
        }
      >
        {({ getFieldValue }) => {
          const paymentMethods = getFieldValue('paymentMethods') || [];

          // Buscar si alguno de los métodos de pago es "creditCard", "debitCard", o "transfer"
          const hasRelevantPaymentMethod = paymentMethods.find(
            (method) =>
              method?.method === 'creditCard' ||
              method?.method === 'debitCard' ||
              method?.method === 'transfer'
          );

          let defaultAccount = bankAccounts.find((account) =>
            account?.defaultUsers?.find((user) => user === turnUser)
          );

          return (
            hasRelevantPaymentMethod && (
              <Form.Item
                name="bankAccount"
                label="Cuenta de banco"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, selecciona la cuenta',
                  },
                ]}
                initialValue={defaultAccount?._id || null}
              >
                <Select
                  placeholder="Selecciona la cuenta de banco"
                  disabled={turn?.paid}
                >
                  {bankAccounts?.map((ba) => (
                    <Option value={ba?._id}>{ba?.bankName}</Option>
                  ))}
                </Select>
              </Form.Item>
            )
          );
        }}
      </Form.Item>

      <Form.List name="paymentMethods">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Row gutter={[24, 24]}>
                <Col xs={24} md={8}>
                  <Form.Item
                    {...restField}
                    name={[name, 'method']}
                    fieldKey={[fieldKey, 'method']}
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, selecciona un método de pago',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecciona un método de pago"
                      disabled={turn?.paid}
                    >
                      <Option value="cash">{paymentMethods.cash}</Option>
                      <Option value="creditCard">
                        {paymentMethods.creditCard}
                      </Option>
                      <Option value="debitCard">
                        {paymentMethods.debitCard}
                      </Option>
                      <Option value="transfer">
                        {paymentMethods.transfer}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    {...restField}
                    name={[name, 'amount']}
                    fieldKey={[fieldKey, 'amount']}
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, ingresa el monto',
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="Monto"
                      disabled={turn?.paid}
                      defaultValue={key === 0 ? turn?.price : undefined} // Asigna el monto total en el primer campo
                    />
                  </Form.Item>
                </Col>
                {/* Campo condicional para el tipo de tarjeta */}
                <Col xs={24} md={8}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.paymentMethods?.[name]?.method !==
                      curValues.paymentMethods?.[name]?.method
                    }
                  >
                    {({ getFieldValue }) =>
                      (getFieldValue(['paymentMethods', name, 'method']) ===
                        'creditCard' ||
                        getFieldValue(['paymentMethods', name, 'method']) ===
                          'debitCard') && (
                        <Form.Item
                          {...restField}
                          name={[name, 'cardType']}
                          fieldKey={[fieldKey, 'cardType']}
                          rules={[
                            {
                              required: true,
                              message:
                                'Por favor, selecciona el tipo de tarjeta',
                            },
                          ]}
                        >
                          <Select
                            placeholder="Selecciona el tipo de tarjeta"
                            disabled={turn?.paid}
                          >
                            {cardTypes.map((card) => (
                              <Option value={card.name}>{card.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )
                    }
                  </Form.Item>
                </Col>
                <Col xs={24} md={2}>
                  <Tooltip title="Borrar este metodo">
                    <Button
                      disabled={turn?.paid}
                      type="link"
                      onClick={() => remove(name)}
                    >
                      <MinusOutlined />
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                disabled={turn?.paid}
                type="dashed"
                onClick={() => add()}
                block
              >
                Añadir Método de Pago
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      {/* Advertencia si la suma de los métodos de pago no coincide con el total */}
      {totalPayment !== turn?.price && (
        <Alert
          message={`La suma de los métodos de pago ($${totalPayment}) no coincide con el monto total ($${turn?.price}).`}
          type="warning"
          showIcon
          style={{ marginBottom: 16 }}
        />
      )}

      <Col xs={24}>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={totalPayment !== turn?.price}
            style={{ width: '100%', backgroundColor: turn?.paid ? 'red' : '' }}
          >
            {turn?.paid ? 'Anular Cobro' : 'Guardar Cobro'}
          </Button>
        </Form.Item>
      </Col>
    </Form>
  );
};

export default AddPaymentForm;
