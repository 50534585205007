import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, message, Row, Col, Checkbox } from 'antd';
import { getUsersActiveApi } from '../../api/user';
import { getAccessTokenApi } from '../../api/auth';
import {
  addTransactionApi,
  transferBetweenCashRegistersApi,
} from '../../api/transaction';
import { useDispatch, useSelector } from 'react-redux';
import { paymentMethods } from '../../utils/constants';
import { onBankAccountRead } from '../../actions';
import { getTransactionTypesApi } from '../../api/transactionType';
import { getOpenCashRegisterApi } from '../../api/cashRegister';

const { Option } = Select;

const TransactionForm = ({ setIsVisibleModal, type }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [cashRegisters, setCashRegisters] = useState([]);
  const [transferCash, setTransferCash] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const { cashRegister } = useSelector((store) => store.searchData);

  const { bankAccounts } = useSelector((store) => store.bankAccountData);

  const { cardTypes } = useSelector((store) => store.tenantData);

  const dispatch = useDispatch();

  const handleFormChange = (changedValues, allValues) => {
    const method = form.getFieldValue(['paymentMethod', 'method']);
    setSelectedPaymentMethod(method); // Actualizamos el estado cuando cambia el método de pago
  };

  // Cargar opciones para los profesionales y las cajas
  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      getUsersActiveApi(token, true).then((response) => {
        setUsers(response.users);
      });

      getTransactionTypesApi(token)
        .then((response) => {
          setTransactionTypes(response.transactionsTypes);
        })
        .catch(() => {});
    };

    dispatch(onBankAccountRead());

    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      const response = await getOpenCashRegisterApi({ token, type: 'view' });
      setCashRegisters(response.data);
      form.setFieldsValue({
        paymentMethod: { method: 'cash' },
      });
    };

    transferCash && getData();
  }, [transferCash]);

  const onFinish = async (values) => {
    setLoading(true);

    values.cashRegister = cashRegister;
    values.type = type;

    try {
      const token = await getAccessTokenApi();

      const response = transferCash
        ? await transferBetweenCashRegistersApi(token, values)
        : await addTransactionApi(token, values);

      if (response.code === 200) {
        message.success(response.message);
        form.resetFields();
        setLoading(false);
        setIsVisibleModal(false);
      } else {
        message.error(response.message);

        setLoading(false);
      }
    } catch (error) {
      message.error('Error del servidor');
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onValuesChange={handleFormChange} // Detecta cambios en los valores del formulario
      layout="vertical"
    >
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12}>
          <Form.Item
            label={`Tipo de ${type === 'expense' ? 'egreso' : 'ingreso'}`}
            name="transactionType"
            rules={[
              { required: true, message: 'Seleccione el tipo de transacción' },
            ]}
            help={
              type === 'expense' && (
                <Checkbox
                  onClick={() => {
                    setTransferCash(!transferCash);
                  }}
                >
                  Transferir a otra caja
                </Checkbox>
              )
            }
          >
            <Select
              placeholder={`Seleccione el tipo de ${
                type === 'expense' ? 'egreso' : 'ingreso'
              } `}
            >
              {transactionTypes.map(
                (t) =>
                  t.type === type && <Option value={t.name}>{t.name}</Option>
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          {transferCash ? (
            <Form.Item
              label="Caja"
              name="destinationCashRegister"
              rules={[
                {
                  required: true,
                  message: 'Seleccione la caja a transferir.',
                },
              ]}
            >
              <Select
                placeholder="Selecciona una caja"
                allowClear
                notFoundContent="No hay cajas disponibles abiertas"
              >
                {cashRegisters?.map(
                  (register) =>
                    register._id !== cashRegister && (
                      <Option key={register._id} value={register._id}>
                        {register.cashRegister} - Turno {register.shift}
                      </Option>
                    )
                )}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item label="Profesional" name="linkedUser">
              <Select
                placeholder="Seleccione un profesional (opcional)"
                allowClear
              >
                {users.map((user) => (
                  <Option key={user._id} value={user._id}>
                    {user.name} {user.lastname}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Col>
        <Col xs={24}>
          <Form.Item
            label="Descripción"
            name="description"
            rules={[
              { required: true, message: 'Ingrese la descripción del egreso' },
            ]}
          >
            <Input.TextArea placeholder="Ingrese una breve descripción del egreso" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 24]}>
        <Col xs={24} md={8}>
          <Form.Item
            label="Método de Pago"
            name={['paymentMethod', 'method']}
            rules={[
              {
                required: true,
                message: 'Por favor, selecciona un método de pago',
              },
            ]}
          >
            <Select
              placeholder="Selecciona un método de pago"
              disabled={transferCash}
            >
              <Option value="cash">{paymentMethods.cash}</Option>
              <Option value="creditCard">{paymentMethods.creditCard}</Option>
              <Option value="debitCard">{paymentMethods.debitCard}</Option>
              <Option value="transfer">{paymentMethods.transfer}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item
            label="Monto"
            name={['paymentMethod', 'amount']}
            rules={[
              {
                required: true,
                message: 'Por favor, ingresa el monto',
              },
            ]}
          >
            <Input type="number" placeholder="Monto" />
          </Form.Item>
        </Col>
        {selectedPaymentMethod === 'creditCard' ||
        selectedPaymentMethod === 'debitCard' ? (
          <Col xs={24} md={8}>
            <Form.Item
              name={['paymentMethod', 'cardType']}
              label="Tarjeta"
              rules={[
                {
                  required: true,
                  message: 'Por favor, selecciona el tipo de tarjeta',
                },
              ]}
            >
              <Select placeholder="Selecciona el tipo de tarjeta">
                {cardTypes.map((card) => (
                  <Option value={card.name}>{card.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        ) : null}
      </Row>
      {(selectedPaymentMethod === 'creditCard' ||
        selectedPaymentMethod === 'debitCard' ||
        selectedPaymentMethod === 'transfer') && (
        <Form.Item
          name="bankAccount"
          label="Cuenta de banco"
          rules={[
            {
              required: true,
              message: 'Por favor, selecciona la cuenta',
            },
          ]}
        >
          <Select placeholder="Selecciona la cuenta de banco">
            {bankAccounts?.map((ba) => (
              <Option value={ba?._id}>{ba?.bankName}</Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{ width: '100%' }}
        >
          {type === 'expense' ? 'Registrar Egreso' : 'Registrar ingreso'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TransactionForm;
