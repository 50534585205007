import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { styleBody } from './styles';
import { PdfHeader } from './PdfHeader';
import { paymentMethods } from '../../utils/constants';

export function PdfCloseShift({
  cashRegisterData,
  tenant,
  tenantLogo,
  organization,
}) {
  return (
    <Document>
      <Page style={styleBody.body}>
        <PdfHeader
          organization={`${organization} - Cierre de caja`}
          tenant={tenant}
          tenantLogo={tenantLogo}
        />
        <Text style={styleBody.title}>
          Ciere {cashRegisterData.cashRegister} - Turno {cashRegisterData.shift}
        </Text>
        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Apertura:</Text>
          <Text style={styleBody.text}>
            {new Date(cashRegisterData.openTime).toLocaleString()}
          </Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Cierre:</Text>
          <Text style={styleBody.text}>{new Date().toLocaleString()}</Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Responsable:</Text>
          <Text style={styleBody.text}>{cashRegisterData.responsibleName}</Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Saldo apertura de caja:</Text>
          <Text style={styleBody.text}>
            {cashRegisterData.totalCash.toLocaleString('es-AR', {
              style: 'currency',
              currency: 'ARS',
              minimumFractionDigits: 0,
            }) || '$0'}
          </Text>
        </View>
        <View style={styleBody.subtitle}>
          <Text style={styleBody.textTitle}>Ingresos</Text>
        </View>

        {cashRegisterData.breakdown.map((breakdown) => (
          <View style={styleBody.data}>
            <Text style={styleBody.textTitle}>
              {`${paymentMethods[breakdown.method]}:`}
            </Text>
            <Text style={styleBody.text}>
              {breakdown.amount.toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 0,
              }) || '$0'}
            </Text>
          </View>
        ))}

        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Subtotal ingresos:</Text>
          <Text style={styleBody.text}>
            {cashRegisterData.totalCollected.toLocaleString('es-AR', {
              style: 'currency',
              currency: 'ARS',
              minimumFractionDigits: 0,
            }) || '$0'}
          </Text>
        </View>

        <View style={styleBody.subtitle}>
          <Text style={styleBody.textTitle}>Egresos</Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Efectivo:</Text>
          <Text style={styleBody.text}>
            {(cashRegisterData?.totalCashExpenses * -1)?.toLocaleString(
              'es-AR',
              {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 0,
              }
            ) || '$0'}
          </Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Banco:</Text>
          <Text style={styleBody.text}>
            {(
              (cashRegisterData?.totalExpenses -
                cashRegisterData?.totalCashExpenses) *
              -1
            )?.toLocaleString('es-AR', {
              style: 'currency',
              currency: 'ARS',
              minimumFractionDigits: 0,
            }) || '$0'}
          </Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Subtotal egresos:</Text>
          <Text style={styleBody.text}>
            {(cashRegisterData?.totalExpenses * -1)?.toLocaleString('es-AR', {
              style: 'currency',
              currency: 'ARS',
              minimumFractionDigits: 0,
            }) || '$0'}
          </Text>
        </View>
        <View style={styleBody.subtitle}>
          <Text style={styleBody.textTitle}>
            Saldo de caja:{' '}
            {(
              (cashRegisterData?.totalCash || 0) +
              (cashRegisterData?.totalCollected || 0) -
              (cashRegisterData.totalExpenses || 0)
            )?.toLocaleString('es-AR', {
              style: 'currency',
              currency: 'ARS',
              minimumFractionDigits: 0,
            }) || '$0'}
          </Text>
        </View>

        {/* Espacio para las firmas */}
        <View style={styleBody.signatureContainer}>
          {/* Firma del responsable de generar la transacción (a la izquierda) */}
          <View style={styleBody.signatureLeft}>
            <Text style={styleBody.signatureText}>Firma Responsable</Text>
            <View style={styleBody.signatureLine}></View>
            <Text style={styleBody.signatureNote}>
              (Firma quien genera la transacción)
            </Text>
          </View>

          {/* Firma del responsable de recibir la transacción (a la derecha) */}
          <View style={styleBody.signatureRight}>
            <Text style={styleBody.signatureText}>Firma Receptor</Text>
            <View style={styleBody.signatureLine}></View>
            <Text style={styleBody.signatureNote}>
              (Firma quien recibe el monto)
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
