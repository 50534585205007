import React, { useEffect, useState } from 'react';
import {
  Form,
  Row,
  Col,
  Button,
  InputNumber,
  notification,
  Checkbox,
  Divider,
  Switch,
  DatePicker,
  Tooltip,
  Select,
  Input,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessTokenApi } from '../../../api/auth';
import {
  changeStatusScheduleApi,
  updateConfigScheduleApi,
} from '../../../api/user';
import { onFloorsRead, onUserDataRead } from '../../../actions';
import {
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import moment from 'moment-timezone';
import { getServicesApi } from '../../../api/service';
export default function ScheduleConfigurationForm({
  setIsVisibleModal,
  setReload,
  sizeSlot,
  userId,
  turnType,
  scheduleConfig,
}) {
  const [scheduleForm] = Form.useForm();
  const [status, setStatus] = useState();
  const [servicesData, setServicesData] = useState([]);

  const dispatch = useDispatch();
  const { role } = useSelector((store) => store.userData?.user);

  const { tenantTimezone } = useSelector((store) => store.tenantData);

  useEffect(() => {
    scheduleForm.resetFields();
    scheduleForm.setFieldsValue(scheduleConfig);
    scheduleForm.setFieldsValue({
      defaultSizeSlot: sizeSlot,
    });

    setStatus(scheduleConfig?.public);
  }, [sizeSlot, userId, scheduleConfig]);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();

      getServicesApi(token).then((response) => {
        setServicesData(response?.services);
      });
    };

    getData();
  }, []);

  const onFinish = async (values) => {
    await values.hours.sort(
      (a, b) => moment(a.start).format('HH') - moment(b.start).format('HH')
    );

    let token = await getAccessTokenApi();

    await updateConfigScheduleApi({ token, data: values, userId }).then(
      async (response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          turnType === 'doctor' && dispatch(onUserDataRead());
          turnType === 'studie' && dispatch(onFloorsRead());
          setIsVisibleModal(false);
          setReload(true);
        }
      }
    );
  };

  return (
    <div>
      <Form form={scheduleForm} onFinish={onFinish}>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item
              name="defaultSizeSlot"
              label="Turnos de (minutos):"
              tooltip="Tiempo de los turnos. En minutos"
              initialValue={sizeSlot}
              rules={[{ required: true, message: 'Debe cargar el dato.' }]}
              labelCol={{ span: 24 }}
            >
              <InputNumber
                min={10}
                step={5}
                inputMode="numeric"
                placeholder="Tamaño de turnos (*)"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          {(role === 2 || role === 0) && turnType !== 'studie' && (
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                name="percent"
                label="Porcentaje a medico"
                tooltip="Indicar cuanto cobra cada médico como porcentaje"
                rules={[{ required: true, message: 'Debe cargar el dato.' }]}
                help="Si trabajas a porcentaje indicarlo aqui, sino dejarlo al 100%"
                labelCol={{ span: 24 }}
              >
                <InputNumber
                  min={0}
                  max={100}
                  step={1}
                  placeholder="Porcentaje Medico"
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                />
              </Form.Item>
            </Col>
          )}

          <Col xs={24} sm={12} md={6}>
            <Form.Item
              name="status"
              tooltip="En caso de contar con el servicio de turnera online y/o portal de pacientes, indica si al medico o estudio aparece disponible para que le puedan sacar un turno."
              initialValue={status}
              label="Publico en turnera online"
              labelCol={{ span: 24 }}
            >
              <div className="list-users__header-switch">
                <Switch
                  checked={status}
                  onChange={async () => {
                    let token = await getAccessTokenApi();
                    changeStatusScheduleApi({
                      token,
                      userId,
                      data: { status: !status },
                    }).then(async (response) => {
                      if (response?.code !== 200) {
                        notification['error']({ message: response.message });
                      } else {
                        notification['success']({ message: response.message });
                        setStatus(!status);
                      }
                    });
                  }}
                />
                <span>{status ? 'Visible' : 'No visible'}</span>
              </div>
            </Form.Item>
          </Col>

          {status && (
            <>
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  name="quantityDaysPortal"
                  tooltip="Es el rango de tiempo en días en que te podran sacar turnos por la portal"
                  label="Cantidad de días habilitados"
                  labelCol={{ span: 24 }}
                >
                  <InputNumber min={7} step={1} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="description"
                  tooltip=""
                  label="Descripción (visible en portal)"
                  labelCol={{ span: 24 }}
                >
                  <Input.TextArea placeholder="Esta descripcion podran ver sus pacientes a la hora de solcitar un turnos" />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>

        <Row gutter={[24, 24]}>
          <Col xs={24} sm={12} md={18}>
            <Form.Item name="service" label="Servicios" labelCol={{ span: 24 }}>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Seleccione servicios"
                optionFilterProp="children"
                allowClear
              >
                {servicesData?.map((service) => (
                  <Select.Option value={service?._id}>
                    {service?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Divider>Horarios</Divider>
          <p>
            <InfoCircleOutlined /> Las nuevas modificaciones de horarios seran
            visibles en los dias en los que su agenda no tenga turnos asignados.
            Si tiene turnos asignados se respetara la agenda que tenia en el
            momento de dar el turno.
          </p>
          {scheduleConfig.hours.length === 0 && (
            <span>
              Debe configurar los horarios de la agenda. Agregar sus horarios
              con el boton
            </span>
          )}
          <Form.List name="hours">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Row gutter={[24, 24]} key={index}>
                      <Col xs={12} md={6}>
                        <Form.Item
                          {...field}
                          valuePropName="date"
                          name={[field.name, 'start']}
                          rules={[
                            {
                              required: true,
                              message: 'Debe elegir una hora',
                            },
                          ]}
                          label="Hora inicio:"
                          labelCol={{ span: 24 }}
                        >
                          <DatePicker.TimePicker
                            defaultValue={
                              scheduleConfig?.hours.length >= index + 1 &&
                              moment.tz(
                                scheduleForm?.getFieldValue().hours[index]
                                  ?.start,
                                tenantTimezone
                              )
                            }
                            placeholder="Inicio"
                            inputReadOnly
                            style={{ width: '100%' }}
                            showNow={false}
                            format="HH:mm"
                            showTime={{ format: 'HH:mm' }}
                            minuteStep={5}
                            allowClear={false}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Item
                          {...field}
                          valuePropName="date"
                          name={[field.name, 'end']}
                          rules={[
                            {
                              required: true,
                              message: 'Debe elegir una hora',
                            },
                          ]}
                          label="Hora Fin:"
                          labelCol={{ span: 24 }}
                        >
                          <DatePicker.TimePicker
                            defaultValue={
                              scheduleConfig?.hours.length >= index + 1 &&
                              moment.tz(
                                scheduleForm?.getFieldValue().hours[index]?.end,
                                tenantTimezone
                              )
                            }
                            placeholder="Fin"
                            inputReadOnly
                            style={{ width: '100%' }}
                            showNow={false}
                            format="HH:mm"
                            showTime={{ format: 'HH:mm' }}
                            minuteStep={5}
                            allowClear={false}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={20} md={11}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'days']}
                          rules={[
                            {
                              required: true,
                              message: 'Debe elegir dias',
                            },
                          ]}
                          label="Dias:"
                          labelCol={{ span: 24 }}
                          tooltip="Solo puede designar 2 horarios para un mismos dias, en caso de poner mas se tomaran solo los dos primeros"
                        >
                          <Checkbox.Group
                            options={[
                              {
                                label: 'L',
                                value: 'lu',
                              },
                              { label: 'M', value: 'ma' },
                              { label: 'M', value: 'mi' },
                              { label: 'J', value: 'ju' },
                              { label: 'V', value: 'vi' },
                              { label: 'S', value: 'sá' },
                              { label: 'D', value: 'do' },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1} style={{ paddingLeft: '0' }}>
                        <Button
                          type="link"
                          size="large"
                          onClick={() => {
                            remove(field.name);
                          }}
                        >
                          <Tooltip title="Quitar horario">
                            <MinusCircleOutlined />
                          </Tooltip>
                        </Button>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item style={{ textAlign: 'right' }}>
                    <Button
                      type="default"
                      shape="circle"
                      onClick={() => {
                        add();
                      }}
                      size="large"
                    >
                      <Tooltip title="Agregar horario">
                        <PlusOutlined />
                      </Tooltip>
                    </Button>
                  </Form.Item>
                  <Col xs={24}>
                    <Form.Item>
                      <Button
                        type="default"
                        htmlType="submit"
                        style={{ width: '100%' }}
                      >
                        Guardar
                      </Button>
                    </Form.Item>
                  </Col>
                </div>
              );
            }}
          </Form.List>
        </Row>
      </Form>
    </div>
  );
}
