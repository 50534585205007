import React, { useState, useEffect } from 'react';
import {
  List,
  Tooltip,
  Modal as ModalAntd,
  Button,
  Collapse,
  Input,
  Switch,
  notification,
  Tag,
  Checkbox,
  message,
} from 'antd';
import Spin from '../../components/Spin';
import { useSelector, useDispatch } from 'react-redux';
import {
  onCityRead,
  onSocialWorkRead,
  onSocialWorkDelete,
  onCityDelete,
  onDiagnosticRead,
  onDiagnosticDelete,
} from '../../actions';
import Modal from '../../components/Modal';
import SocialWorkForm from '../../components/SocialWorkForm';
import CityForm from '../../components/CityForm';
import DiagnosticForm from '../../components/DischargeForm/DiagnosticForm';
import DrugForm from '../../components/DrugForm';
import './Configuration.scss';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { ROLES } from '../../utils/constants';
import { deleteDrugApi, getDrugApi } from '../../api/drug';
import { deleteInfusionApi, getInfusionApi } from '../../api/infusion';
import PermissionsAsigner from '../../components/PermissionsAsigner';
import {
  deleteActionInfarmaryApi,
  getActionInfarmaryApi,
} from '../../api/actionInfarmary';
import { getAccessTokenApi } from '../../api/auth';
import { getAllBedsApi, updateBedStatus } from '../../api/bed';
import { deleteTemplateApi, getTemplatesApi } from '../../api/template';
import TemplateForm from '../../components/TemplateForm';
import { deleteInsumoApi, getInsumoApi } from '../../api/insumo';
import StockForm from '../../components/StockForm/StockForm';
import ProviderForm from '../../components/ProviderForm/ProviderForm';
import ServiceForm from '../../components/ServiceForm/ServiceForm';

import { deleteProvideryApi, getProvidersApi } from '../../api/provider';
import InsumoIndicationForm from '../../components/Infarmary/InsumoIndicationForm';
import { deleteServiceApi, getServicesApi } from '../../api/service';
import {
  deleteTransactionTypeApi,
  getTransactionTypesApi,
} from '../../api/transactionType';
import TransactionTypeForm from '../../components/CashRegister/TransactionType';

export default function Configuration(props) {
  const [modalTitle, setModalTitle] = useState('');
  const [isVisibleModalForm, setIsVisibleModalForm] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [drugs, setDrugs] = useState([]);
  const [infusiones, setInfusiones] = useState([]);
  const [actions, setActions] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [services, setServices] = useState([]);
  const [insumos, setInsumos] = useState();
  const [providers, setProviders] = useState([]);
  const [beds, setBeds] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [searchDrugs, setSearchDrugs] = useState('');
  const [searchInfusiones, setSearchInfusiones] = useState('');
  const [searchInsumos, setSearchInsumos] = useState('');
  const [searchSocialWorks, setSearchSocialWorks] = useState('');
  const [searchBeds, setSearchBeds] = useState('');

  const { socialWorks } = useSelector((store) => store.socialWorkData);
  const { diagnostics } = useSelector((store) => store.diagnosticData);
  const { cities, loading } = useSelector((store) => store.cityData);
  const { role } = useSelector((store) => store.userData?.user);
  const userId = useSelector((store) => store.userData?.user._id);

  const { organizationType, drugStores, cashRegisters } = useSelector(
    (store) => store.tenantData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (role === ROLES.EMPLOYEE.value) {
      props.history.push('/');
      notification['error']({ message: 'No posee permisos para acceder' });
    }

    const getData = async () => {
      let token = await getAccessTokenApi();

      dispatch(onSocialWorkRead());
      dispatch(onDiagnosticRead());
      dispatch(onCityRead(token));

      getTemplatesApi({ token }).then((response) => {
        setTemplates(response.templates);
      });

      getServicesApi(token).then((response) => {
        setServices(response.services);
      });

      getInsumoApi(token).then((response) => {
        setInsumos(response.insumos);
      });

      getDrugApi(token)
        .then((response) => {
          setDrugs(response.drugs);
        })
        .catch(() => {});
      getInfusionApi(token)
        .then((response) => {
          setInfusiones(response.infusiones);
        })
        .catch(() => {});
      getActionInfarmaryApi(token)
        .then((response) => {
          setActions(response.actions);
        })
        .catch(() => {});

      getProvidersApi(token).then((response) => {
        setProviders(response.providers);
      });

      getAllBedsApi(token, false)
        .then((response) => {
          setBeds(response.beds);
        })
        .catch(() => {});

      getTransactionTypesApi(token)
        .then((response) => {
          setTransactionTypes(response.transactionsTypes);
        })
        .catch(() => {});
    };
    getData();
  }, []);

  if (searchInfusiones) {
    var infusionesFilters = infusiones.filter((infusion) =>
      infusion.name
        .toLowerCase()
        .trim()
        .includes(searchInfusiones.toLowerCase().trim())
    );
  }
  if (searchDrugs) {
    var drugsFilters = drugs.filter((drug) =>
      drug.name.toLowerCase().trim().includes(searchDrugs.toLowerCase().trim())
    );
  }
  if (searchSocialWorks) {
    var socialWorksFilters = socialWorks.filter((socialWork) =>
      socialWork.name
        .toLowerCase()
        .trim()
        .includes(searchSocialWorks.toLowerCase().trim())
    );
  }
  if (searchBeds) {
    var bedFilters = beds.filter(
      (bed) =>
        bed.name
          .toLowerCase()
          .trim()
          .includes(searchBeds.toLowerCase().trim()) ||
        bed.type.toLowerCase().trim().includes(searchBeds.toLowerCase().trim())
    );
  }
  if (searchInsumos) {
    var insumosFilters = insumos.filter((insumo) =>
      insumo.name
        .toLowerCase()
        .trim()
        .includes(searchInsumos.toLowerCase().trim())
    );
  }

  const addSocialWork = (e, editData) => {
    e.stopPropagation();
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nueva obra social');
    setModalContent(
      <SocialWorkForm
        setIsVisibleModal={setIsVisibleModalForm}
        data={editData}
      />
    );
  };

  const addTemplate = (e, editData) => {
    !editData && e.stopPropagation();
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nuevo template');
    setModalContent(
      <TemplateForm
        setIsVisibleModal={setIsVisibleModalForm}
        getTemplate={getTemplate}
        data={editData}
      />
    );
  };

  const addService = (e, editData) => {
    !editData && e.stopPropagation();
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nuevo servicio');
    setModalContent(
      <ServiceForm
        setIsVisibleModal={setIsVisibleModalForm}
        getService={getService}
        data={editData}
      />
    );
  };

  const addDiagnostic = (e) => {
    e.stopPropagation();
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nuevo diagnostico de alta');
    setModalContent(
      <DiagnosticForm setIsVisibleModal={setIsVisibleModalForm} />
    );
  };

  const addCity = (e) => {
    e.stopPropagation();
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nueva ciudad');
    setModalContent(<CityForm setIsVisibleModal={setIsVisibleModalForm} />);
  };

  const addTransactionType = (e) => {
    e.stopPropagation();
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nuevo tipo de transacción');
    setModalContent(
      <TransactionTypeForm
        setIsVisibleModal={setIsVisibleModalForm}
        getTransactionTypes={getTransactionTypes}
      />
    );
  };

  const addDrug = (e) => {
    e.stopPropagation();
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nueva droga');
    setModalContent(
      <DrugForm setIsVisibleModal={setIsVisibleModalForm} getDrug={getDrug} />
    );
  };

  const addInfusion = (e) => {
    e.stopPropagation();
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nueva infusión');
    setModalContent(
      <DrugForm
        setIsVisibleModal={setIsVisibleModalForm}
        getDrug={getInfusion}
        infusion={true}
      />
    );
  };

  const addInsumo = (e, data = undefined) => {
    e.stopPropagation();
    setIsVisibleModalForm(true);
    setModalTitle(data ? `Editando ${data.name}` : 'Cargando nuevo insumo');
    setModalContent(
      <StockForm
        setIsVisibleModal={setIsVisibleModalForm}
        getInsumo={getInsumo}
        data={data}
      />
    );
  };

  const addAction = (e) => {
    e.stopPropagation();
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nueva acción de enfermería');
    setModalContent(
      <DrugForm
        setIsVisibleModal={setIsVisibleModalForm}
        getDrug={getAction}
        action={true}
      />
    );
  };

  const deleteSocialWork = (socialWork) => {
    ModalAntd.confirm({
      title: 'Eliminar obra social',
      content: `¿ Estas seguro que quieres eliminar ${socialWork.name}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        dispatch(onSocialWorkDelete(socialWork._id));
      },
    });
  };

  const deleteTemplate = (template) => {
    ModalAntd.confirm({
      title: 'Eliminar template',
      content: `¿ Estas seguro que quieres eliminar ${template?.name}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        let token = await getAccessTokenApi();
        deleteTemplateApi(template._id, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              getTemplate();
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
      },
    });
  };

  const deleteService = (service) => {
    ModalAntd.confirm({
      title: 'Eliminar servicio',
      content: `Recuerde que al eliminar el servicio no va a poder usar este filtro en estadisticas ¿ Estas seguro que quieres eliminar ${service?.name} ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        let token = await getAccessTokenApi();
        deleteServiceApi(service._id, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              getService();
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
      },
    });
  };

  const deleteDiagnostic = (diagnostic) => {
    ModalAntd.confirm({
      title: 'Eliminar diagnostico de alta',
      content: `¿ Estas seguro que quieres eliminar ${diagnostic.name}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        dispatch(onDiagnosticDelete(diagnostic._id));
      },
    });
  };

  const deleteCity = (city) => {
    ModalAntd.confirm({
      title: 'Eliminar ciudad',
      content: `¿ Estas seguro que quieres eliminar ${city.name}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        dispatch(onCityDelete(city._id));
      },
    });
  };

  const deleteTransactionType = (transactionType) => {
    ModalAntd.confirm({
      title: 'Eliminar tipo de transacción',
      content: `¿ Estas seguro que quieres eliminar ${transactionType?.name}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        let token = await getAccessTokenApi();
        const response = await deleteTransactionTypeApi(
          token,
          transactionType._id
        );
        if (response?.code === 200) {
          message.success(response.message);
          getTransactionTypes();
        } else {
          message.error(response.message);
        }
      },
    });
  };

  const deleteDrug = (drug) => {
    ModalAntd.confirm({
      title: 'Eliminar droga',
      content: `¿ Estas seguro que quieres eliminar ${drug.name}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        let token = await getAccessTokenApi();
        deleteDrugApi(drug._id, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              getDrug();
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
      },
    });
  };

  const deleteInsumo = (insumo) => {
    ModalAntd.confirm({
      title: 'Eliminar insumo',
      content: `¿ Estas seguro que quieres eliminar ${insumo.name}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        let token = await getAccessTokenApi();
        deleteInsumoApi(insumo._id, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              getInsumo();
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
      },
    });
  };

  const deleteInfusion = (infusion) => {
    ModalAntd.confirm({
      title: 'Eliminar infusión',
      content: `¿ Estas seguro que quieres eliminar ${infusion.name}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        let token = await getAccessTokenApi();
        deleteInfusionApi(infusion._id, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              getInfusion();
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
      },
    });
  };

  const deleteAction = (action) => {
    ModalAntd.confirm({
      title: 'Eliminar acción de enfermería',
      content: `¿ Estas seguro que quieres eliminar ${action.name}  ?`,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        let token = await getAccessTokenApi();

        deleteActionInfarmaryApi(action._id, token)
          .then((response) => {
            if (response?.code !== 200) {
              notification['error']({ message: response.message });
            } else {
              notification['success']({ message: response.message });
              getAction();
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
          });
      },
    });
  };

  const getTemplate = async () => {
    let token = await getAccessTokenApi();

    getTemplatesApi({ token })
      .then((response) => {
        setTemplates(response.templates);
      })
      .catch(() => {});
  };

  const getService = async () => {
    let token = await getAccessTokenApi();

    getServicesApi(token)
      .then((response) => {
        setServices(response.services);
      })
      .catch(() => {});
  };

  const getDrug = async () => {
    let token = await getAccessTokenApi();

    getDrugApi(token)
      .then((response) => {
        setDrugs(response.drugs);
      })
      .catch(() => {});
  };

  const getTransactionTypes = async () => {
    let token = await getAccessTokenApi();

    getTransactionTypesApi(token)
      .then((response) => {
        setTransactionTypes(response.transactionsTypes);
      })
      .catch(() => {});
  };

  const getInsumo = async () => {
    let token = await getAccessTokenApi();

    getInsumoApi(token)
      .then((response) => {
        setInsumos(response.insumos);
      })
      .catch(() => {});
  };

  const getInfusion = async () => {
    let token = await getAccessTokenApi();

    getInfusionApi(token)
      .then((response) => {
        setInfusiones(response.infusiones);
      })
      .catch(() => {});
  };

  const getAction = async () => {
    let token = await getAccessTokenApi();

    getActionInfarmaryApi(token)
      .then((response) => {
        setActions(response.actions);
      })
      .catch(() => {});
  };

  const getProvider = async () => {
    let token = await getAccessTokenApi();

    getProvidersApi(token)
      .then((response) => {
        setProviders(response.providers);
      })
      .catch(() => {});
  };

  const updateBed = async (bedId, status, disable) => {
    let token = await getAccessTokenApi();

    updateBedStatus(token, bedId, status, disable)
      .then((response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          getAllBedsApi(token, false).then((response) => {
            setBeds(response.beds);
          });
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };

  const addProvider = (e, data = false) => {
    e.stopPropagation();
    setIsVisibleModalForm(true);
    setModalTitle('Nuevo proveedor');
    setModalContent(
      <ProviderForm
        setIsVisibleModal={setIsVisibleModalForm}
        getProvider={getProvider}
        data={data}
      />
    );
  };

  const showDeleteConfirmProvider = async (provider) => {
    const id = provider._id;
    let token = await getAccessTokenApi();

    ModalAntd.confirm({
      title: 'Eliminando proveedor',
      content: <span>Estas seguro que quieres eliminar {provider.name}?</span>,
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        await deleteProvideryApi(id, token);
        getProvider();
      },
    });
  };

  const addInsumoExtra = (indicationId = false) => {
    setIsVisibleModalForm(true);
    setModalTitle(
      'Agregar insumos utilizados de manera generica sin asociar a un paciente'
    );
    setModalContent(
      <InsumoIndicationForm
        userId={userId}
        setIsVisibleModal={setIsVisibleModalForm}
        getInsumos={getInsumo}
        indicationId={indicationId}
        isVisibleModal={isVisibleModalForm}
      />
    );
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <div>
      <Collapse>
        <Collapse.Panel
          key={1}
          header={`Obras sociales (${socialWorks && socialWorks?.length})`}
          extra={
            <Tooltip title="Agregar obra social">
              <Button
                type="link"
                size="small"
                onClick={(e) => addSocialWork(e)}
              >
                <PlusOutlined />
              </Button>
            </Tooltip>
          }
        >
          <>
            <Input.Search
              enterButton
              placeholder="Buscar obras sociales.."
              onSearch={(value) => setSearchSocialWorks(value)}
              allowClear
            />
            <ListData
              data={searchSocialWorks ? socialWorksFilters : socialWorks}
              editData={addSocialWork}
              deleteData={deleteSocialWork}
            />
          </>
        </Collapse.Panel>

        <Collapse.Panel
          key={3}
          header={`Ciudades (${cities && cities.length})`}
          extra={
            <Tooltip title="Agregar ciudad">
              <Button type="link" size="small" onClick={addCity}>
                <PlusOutlined />
              </Button>
            </Tooltip>
          }
        >
          <ListData data={cities} deleteData={deleteCity} />
        </Collapse.Panel>

        <Collapse.Panel
          key={8}
          header={`Templates (${templates?.length})`}
          extra={
            <Tooltip title="Agregar nuevo template">
              <Button type="link" size="small" onClick={(e) => addTemplate(e)}>
                <PlusOutlined />
              </Button>
            </Tooltip>
          }
        >
          <ListData
            data={templates}
            deleteData={deleteTemplate}
            editData={addTemplate}
          />
        </Collapse.Panel>

        <Collapse.Panel
          key={11}
          header={`Servicios (${services?.length})`}
          extra={
            <Tooltip title="Agregar nuevo servicio">
              <Button type="link" size="small" onClick={(e) => addService(e)}>
                <PlusOutlined />
              </Button>
            </Tooltip>
          }
        >
          <ListData
            data={services}
            deleteData={deleteService}
            editData={addService}
          />
        </Collapse.Panel>

        {drugStores && (
          <>
            <Collapse.Panel
              key={4}
              header={`Drogas (${drugs && drugs.length})`}
              extra={
                <PermissionsAsigner
                  requiredRole={ROLES.OWNER}
                  visible={ROLES.ADMININFARMARY}
                  type="Administrativo"
                >
                  <Tooltip title="Agregar droga">
                    <Button type="link" size="small" onClick={addDrug}>
                      <PlusOutlined />
                    </Button>
                  </Tooltip>
                </PermissionsAsigner>
              }
            >
              <>
                <Input.Search
                  enterButton
                  placeholder="Buscar drogas.."
                  onSearch={(value) => setSearchDrugs(value)}
                  allowClear
                />
                <ListData
                  data={searchDrugs ? drugsFilters : drugs}
                  deleteData={deleteDrug}
                  permission={true}
                />
              </>
            </Collapse.Panel>
            <Collapse.Panel
              key={9}
              header={`Insumos (${insumos && insumos.length})`}
              extra={
                <>
                  {organizationType === 'hospitalaria' && (
                    <Button onClick={() => addInsumoExtra()}>
                      Agrerar consumo
                    </Button>
                  )}
                  <PermissionsAsigner
                    requiredRole={ROLES.OWNER}
                    visible={ROLES.ADMININFARMARY}
                    type="Administrativo"
                  >
                    <Tooltip title="Agregar insumo">
                      <Button type="link" size="small" onClick={addInsumo}>
                        <PlusOutlined />
                      </Button>
                    </Tooltip>
                  </PermissionsAsigner>
                </>
              }
            >
              <>
                <Input.Search
                  enterButton
                  placeholder="Buscar insumos.."
                  onSearch={(value) => setSearchInsumos(value)}
                  allowClear
                />
                <ListData
                  data={searchInsumos ? insumosFilters : insumos}
                  deleteData={deleteInsumo}
                  editData={addInsumo}
                  stock={organizationType === 'hospitalaria' ? true : false}
                  permission={true}
                />
              </>
            </Collapse.Panel>
            <Collapse.Panel
              key={10}
              header={`Proveedores (${providers?.length})`}
              extra={
                <PermissionsAsigner
                  requiredRole={ROLES.OWNER}
                  type="Administrativo"
                >
                  <Button
                    type="link"
                    shape="circle"
                    onClick={(e) => addProvider(e)}
                    size="small"
                  >
                    <PlusOutlined />
                  </Button>
                </PermissionsAsigner>
              }
            >
              <div style={{ textAlign: 'right' }}></div>
              <List
                className="configurattions__list"
                itemLayout="horizontal"
                dataSource={providers}
                renderItem={(provider) => (
                  <Provider
                    provider={provider}
                    showDeleteConfirmProvider={showDeleteConfirmProvider}
                    addProvider={addProvider}
                  />
                )}
              />
            </Collapse.Panel>
          </>
        )}
        {cashRegisters?.length > 0 && (
          <Collapse.Panel
            key={12}
            header={`Tipos de transacción (${transactionTypes?.length || 0})`}
            extra={
              <Tooltip title="Agregar tipo de transacción">
                <Button
                  type="link"
                  size="small"
                  onClick={(e) => addTransactionType(e)}
                >
                  <PlusOutlined />
                </Button>
              </Tooltip>
            }
          >
            <ListData
              data={transactionTypes}
              deleteData={deleteTransactionType}
            />
          </Collapse.Panel>
        )}

        {organizationType === 'hospitalaria' && (
          <>
            <Collapse.Panel
              key={5}
              header={`Infusiones (${infusiones && infusiones.length})`}
              extra={
                <PermissionsAsigner
                  requiredRole={ROLES.OWNER}
                  visible={ROLES.ADMININFARMARY}
                  type="Administrativo"
                >
                  <Tooltip title="Agregar infusión">
                    <Button type="link" size="small" onClick={addInfusion}>
                      <PlusOutlined />
                    </Button>
                  </Tooltip>
                </PermissionsAsigner>
              }
            >
              <>
                <Input.Search
                  enterButton
                  placeholder="Buscar infusiones.."
                  onSearch={(value) => setSearchInfusiones(value)}
                  allowClear
                />
                <ListData
                  data={searchInfusiones ? infusionesFilters : infusiones}
                  deleteData={deleteInfusion}
                  permission={true}
                />
              </>
            </Collapse.Panel>
            <Collapse.Panel
              key={6}
              header={`Acciones de Enfermería (${actions && actions.length})`}
              extra={
                <PermissionsAsigner
                  requiredRole={ROLES.OWNER}
                  visible={ROLES.ADMININFARMARY}
                  type="Administrativo"
                >
                  <Tooltip title="Agregar acción">
                    <Button type="link" size="small" onClick={addAction}>
                      <PlusOutlined />
                    </Button>
                  </Tooltip>
                </PermissionsAsigner>
              }
            >
              <ListData
                data={actions}
                deleteData={deleteAction}
                permission={true}
              />
            </Collapse.Panel>
            <Collapse.Panel
              key={2}
              header={`Diagnosticos de alta (${
                diagnostics && diagnostics.length
              })`}
              extra={
                <Tooltip title="Agregar diagnostico">
                  <Button type="link" size="small" onClick={addDiagnostic}>
                    <PlusOutlined />
                  </Button>
                </Tooltip>
              }
            >
              <ListData data={diagnostics} deleteData={deleteDiagnostic} />
            </Collapse.Panel>
            <Collapse.Panel key={7} header={`Camas (${beds?.length})`}>
              <>
                <Input.Search
                  enterButton
                  placeholder="Buscar camas.."
                  onChange={(e) =>
                    e.target.value.length > 1 && setSearchBeds(e.target.value)
                  }
                  allowClear
                />
                <ListBeds
                  data={searchBeds ? bedFilters : beds}
                  updateData={updateBed}
                />
              </>
            </Collapse.Panel>
          </>
        )}
      </Collapse>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModalForm}
        setIsVisible={setIsVisibleModalForm}
        width="70%"
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function ListData(props) {
  const { data, deleteData, permission, editData, stock } = props;

  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      className="configuration-list"
      renderItem={(data) => (
        <List.Item
          actions={
            permission
              ? [
                  editData && (
                    <PermissionsAsigner
                      requiredRole={ROLES.SECRETARY}
                      visible={ROLES.ADMININFARMARY}
                      type="Administrativo"
                    >
                      <Tooltip title="Editar">
                        <Button type="link" onClick={(e) => editData(e, data)}>
                          <EditOutlined />
                        </Button>
                      </Tooltip>
                    </PermissionsAsigner>
                  ),
                  <PermissionsAsigner
                    requiredRole={ROLES.OWNER}
                    visible={ROLES.ADMININFARMARY}
                    type="Administrativo"
                  >
                    <Tooltip title="Eliminar">
                      <Button type="link" onClick={() => deleteData(data)}>
                        <DeleteOutlined style={{ color: 'red' }} />
                      </Button>
                    </Tooltip>
                  </PermissionsAsigner>,
                ]
              : [
                  editData && (
                    <Tooltip title="Editar">
                      <Button type="link" onClick={(e) => editData(e, data)}>
                        <EditOutlined />
                      </Button>
                    </Tooltip>
                  ),
                  <Tooltip title="Eliminar">
                    <Button type="link" onClick={() => deleteData(data)}>
                      <DeleteOutlined style={{ color: 'red' }} />
                    </Button>
                  </Tooltip>,
                ]
          }
        >
          <List.Item.Meta
            title={
              editData &&
              (data?.type ||
                data?.nomenclator ||
                data?.price ||
                (data?.stock && stock)) ? (
                <>
                  <span>{data.name} </span>
                  <Tag
                    color={
                      stock
                        ? data.stock <= 0 || !data.stock
                          ? 'red'
                          : data.stock <= 30
                          ? 'warning'
                          : 'success'
                        : data?.type === 'Ambulatorio'
                        ? 'geekblue'
                        : data?.type === 'Evolucion'
                        ? 'orange'
                        : 'default'
                    }
                  >
                    {stock
                      ? `En circulación: ${data.stock}`
                      : data?.type ||
                        (data?.nomenclator &&
                          `Nomenclador: ${data?.nomenclator}`) ||
                        (data?.price &&
                          `Precio: ${data?.price.toLocaleString('es-AR', {
                            style: 'currency',
                            currency: 'ARS',
                            minimumFractionDigits: 0,
                          })}`)}
                  </Tag>
                </>
              ) : (
                data.name
              )
            }
            description={data?.description}
            style={{ whiteSpace: 'pre-wrap' }}
          />
        </List.Item>
      )}
    />
  );
}

function Provider(props) {
  const { provider, showDeleteConfirmProvider, addProvider } = props;
  return (
    <List.Item
      actions={[
        <Tooltip title="Editar">
          <Button type="link" onClick={(e) => addProvider(e, provider)}>
            <EditOutlined />
          </Button>
        </Tooltip>,
        <Button
          type="link"
          onClick={() => showDeleteConfirmProvider(provider)}
          style={{ color: 'red' }}
        >
          <DeleteOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta
        title={provider.name}
        description={
          <ul>
            {provider?.businessName && (
              <li>Razon social: {provider?.businessName}</li>
            )}
            {provider?.businessName && <li>CUIT: {provider?.cuit}</li>}
            {provider?.businessName && (
              <li>Dirección: {provider?.direction}</li>
            )}

            <li>
              {`Teléfono: ${provider.phone ? provider.phone : ''}`}
              {provider.contact &&
                ` - Persona de Contacto: ${provider.contact}`}
            </li>
          </ul>
        }
      />
    </List.Item>
  );
}

function ListBeds(props) {
  const { data, updateData } = props;

  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      className="configuration-list"
      style={data.softDelte && { backgroundColor: 'red' }}
      renderItem={(data) => (
        <List.Item
          actions={[
            <PermissionsAsigner
              requiredRole={ROLES.OWNER}
              visible={ROLES.SECRETARY}
            >
              <Tooltip
                title={
                  data.status ? 'Marcar como Libre' : 'Marcar como Ocupada'
                }
              >
                <div className="configuration-list-switch">
                  <Switch
                    disabled={data?.softDelete}
                    checkedChildren={<CloseOutlined />}
                    unCheckedChildren={<CheckOutlined />}
                    defaultChecked={data.status}
                    onChange={(status) => updateData(data._id, status)}
                  />
                </div>
              </Tooltip>
            </PermissionsAsigner>,
            <PermissionsAsigner
              requiredRole={ROLES.OWNER}
              visible={ROLES.SECRETARY}
            >
              <Tooltip
                title={data.softDelete ? 'Habilitar Cama' : 'Deshabilitar Cama'}
              >
                <Checkbox
                  defaultChecked={data.softDelete}
                  onChange={(e) => updateData(data._id, e.target.checked, true)}
                ></Checkbox>
              </Tooltip>
            </PermissionsAsigner>,
          ]}
        >
          <List.Item.Meta
            title={
              <div>
                {data.name} - {data.type}
              </div>
            }
            description={
              <Tag
                color={
                  data.softDelete
                    ? 'warning'
                    : data.status
                    ? 'error'
                    : 'success'
                }
              >
                {data.softDelete
                  ? 'Fuera de Servicio'
                  : data.status
                  ? 'Ocupada'
                  : 'Libre'}
              </Tag>
            }
          />
        </List.Item>
      )}
    />
  );
}
