import { ON_SEARCH_VALUE_CHANGE } from '../actions/types';

const INITIAL_STATE = {
  count: 0,
  search: '',
  searchYear: undefined,
  page: 1,
  turnUser: null,
  turnType: null,
  cashRegister: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_SEARCH_VALUE_CHANGE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
