import React, { useEffect } from 'react';
import { Form, Row, Col, Input, Button, Radio, message } from 'antd';
import { addTransactionTypeApi } from '../../api/transactionType';
import { getAccessTokenApi } from '../../api/auth';

export default function TransactionTypeForm(props) {
  const { setIsVisibleModal, getTransactionTypes } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, []);

  const onFinish = async (values) => {
    let token = await getAccessTokenApi();
    const response = await addTransactionTypeApi(token, values);

    if (response?.code === 200) {
      message.success(response.message);
      getTransactionTypes();
      setIsVisibleModal(false);
      form.resetFields();
    } else {
      message.error(response.message);
    }
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: 'Debe cargar nombre del tipo.' },
              ]}
            >
              <Input placeholder="Nombre (*)" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="type"
              rules={[
                { required: true, message: 'Debe cargar seleccionar el tipo.' },
              ]}
            >
              <Radio.Group>
                <Radio value="revenue">Ingreso</Radio>
                <Radio value="expense">Egreso</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
