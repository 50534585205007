import React, { useState } from 'react';
import {
  Button,
  InputNumber,
  Row,
  Tag,
  Table,
  Tooltip, // Importar el componente Table
} from 'antd';
import BackButton from '../../components/BackButton';
import { Link } from 'react-router-dom';
import {
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Modal from '../Modal';
import BillingChargedForm from './BillingChargedForm';
import './Billing.scss';
import { useDispatch, useSelector } from 'react-redux';
import { onSearchValueChange } from '../../actions/BillingSearchActions';

export default function BillingList({ billingData, loading, type, setReload }) {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);

  const dispatch = useDispatch();

  const { searchYear } = useSelector((store) => store.searchData);

  const billingChargedModal = (billing) => {
    setIsVisibleModal(true);
    setModalTitle(`Agregar cobro al lote ${billing.description}`);
    setModalContent(
      <BillingChargedForm
        totalPrice={billing?.totalPrice - billing?.totalValueCharged}
        setIsVisibleModal={setIsVisibleModal}
        id={billing._id}
        quantityPractices={
          billing?.quantityPractices - billing?.totalQuantityCharged
        }
        setReload={setReload}
      />
    );
  };

  // Función para obtener los valores únicos del filtro
  const getFilteredData = (filteredData, key) => {
    return [
      ...new Set(
        filteredData?.map((item) => {
          const keys = key.split('.');
          let value = item;
          keys.forEach((k) => {
            value = value ? value[k] : null;
          });
          return value;
        })
      ),
    ]
      .filter(Boolean)
      .map((value) => ({
        text: value,
        value,
      }));
  };

  let columns = [
    ...(type === 'month'
      ? [
          {
            title: 'Prestador',
            dataIndex: 'organization',
            key: 'organization',
            align: 'center',
            render: (organization) => <strong>{organization}</strong>,
            filters: getFilteredData(billingData, 'organization'),
            onFilter: (value, record) => record.organization === value,
          },
        ]
      : []),
    ...(type !== 'batch'
      ? [
          {
            title: type === 'month' ? 'Mes' : 'Financiador',
            dataIndex: 'filteData',
            key: 'a',
            align: 'center',
            filters:
              type === 'socialWork'
                ? getFilteredData(billingData, 'socialWork') // Filtra por Financiador
                : null,
            onFilter: (value, record) =>
              type === 'socialWork' ? record.socialWork === value : false,
            render: (a, data) =>
              type === 'month'
                ? `${data?.month}/${data?.year}`
                : data.socialWork,
          },
        ]
      : []),
    ...(type === 'batch'
      ? [
          {
            title: 'Código',
            dataIndex: 'practice',
            key: 'practiceCode',
            align: 'center',
            sorter: (a, b) => a?.practice[0]?.code - b?.practice[0]?.code,
            render: (practice) => <span>{practice && practice[0]?.code}</span>,
            filters: getFilteredData(billingData, 'practice.0.code'), // Filtra por Práctica
            onFilter: (value, record) => record.practice[0]?.code === value,
          },
          {
            title: 'Práctica',
            dataIndex: 'practice',
            key: 'practice',
            align: 'center',
            render: (practice) => <span>{practice && practice[0]?.name}</span>,
            filters: getFilteredData(billingData, 'practice.0.name'), // Filtra por Práctica
            onFilter: (value, record) => record.practice[0]?.code === value,
          },
        ]
      : []),
    {
      title: 'Estado',
      dataIndex: 'realStatus',
      key: 'realStatus',
      align: 'center',
      render: (realStatus) => (
        <Tag color={realStatus.color}>{realStatus.status}</Tag>
      ),
      filters: getFilteredData(billingData, 'realStatus.status'), // Filtra por estado
      onFilter: (value, record) => record.realStatus?.status === value,
    },
    {
      title: 'Prácticas realizadas',
      dataIndex: 'quantityPractices',
      key: 'quantityPractices',
      align: 'center',
      sorter: (a, b) => a?.quantityPractices - b?.quantityPractices,
    },
    {
      title: 'Valor facturado',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      align: 'center',
      render: (text) =>
        text.toLocaleString('es-AR', {
          style: 'currency',
          currency: 'ARS',
          minimumFractionDigits: 2,
        }),
      sorter: (a, b) => a?.totalPrice - b?.totalPrice,
    },
    {
      title: 'Prácticas cobradas',
      dataIndex: 'totalQuantityCharged',
      key: 'totalQuantityCharged',
      align: 'center',
      sorter: (a, b) => a?.totalQuantityCharged - b?.totalQuantityCharged,
    },
    {
      title: 'Valor cobrado',
      dataIndex: 'totalValueCharged',
      key: 'totalValueCharged',
      align: 'center',
      render: (text) =>
        text.toLocaleString('es-AR', {
          style: 'currency',
          currency: 'ARS',
          minimumFractionDigits: 2,
        }),
      sorter: (a, b) => a?.totalValueCharged - b?.totalValueCharged,
    },
    {
      title: 'Saldo',
      key: 'saldo',
      align: 'center',
      render: (text, record) => {
        let percent =
          (record.totalPrice - record.totalValueCharged) / record.totalPrice;
        return (
          <span
            style={{
              color: percent >= 0.3 ? 'red' : percent >= 0.1 ? '#ffb703' : '',
            }}
          >
            {(record.totalPrice - record.totalValueCharged).toLocaleString(
              'es-AR',
              {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
              }
            )}
          </span>
        );
      },
    },
    {
      title: 'Deuda',
      key: 'deuda',
      align: 'center',
      render: (text, record) => {
        let percent =
          (record.totalPrice - record.totalValueCharged) / record.totalPrice;
        return (
          <span
            style={{
              color: percent >= 0.3 ? 'red' : percent >= 0.1 ? '#ffb703' : '',
            }}
          >
            {(percent * 100).toFixed(0)}%
          </span>
        );
      },
    },
    {
      title: 'Acciones',
      key: 'actions',
      align: 'center',
      render: (text, data) => {
        if (type === 'month' || type === 'socialWork') {
          return (
            <div>
              <Link
                to={{
                  pathname: `/billing-batch-details/${
                    type === 'month' ? 'socialWork' : 'batch'
                  }`,
                  search: `?year=${data?._id?.year}&month=${
                    data?._id?.month
                  }${`&organization=${data._id.organization}&socialWork=${data._id.socialWork}`}`,
                }}
              >
                <Tooltip title="Ver detalle">
                  <Button type="link">
                    <InfoCircleOutlined />
                  </Button>
                </Tooltip>
              </Link>
            </div>
          );
        } else {
          return (
            <div>
              {data.status !== 'Cobrado' && (
                <Tooltip title="Agregar cobro">
                  <Button type="link" onClick={() => billingChargedModal(data)}>
                    <PlusOutlined />
                  </Button>
                </Tooltip>
              )}
              <Link
                to={{
                  pathname: `/billing-detail/${data._id}`,
                  hash: '#view',
                }}
              >
                <Tooltip title="Ver lote">
                  <Button type="link">
                    <InfoCircleOutlined />
                  </Button>
                </Tooltip>
              </Link>
            </div>
          );
        }
      },
    },
  ];

  // Encabezado Prestador como título
  const prestadorHeader =
    type !== 'month' ? (
      <h3 style={{ textAlign: 'center', marginBottom: '16px' }}>
        Prestador: {billingData && billingData[0]?.organization}
      </h3>
    ) : null;

  return (
    <div>
      <div>
        <Row>
          <BackButton />
          <span
            style={{
              fontWeight: 600,
              fontSize: '12pt',
              marginRight: '5px',
            }}
          >
            {type === 'month' ? (
              <div style={{ marginBottom: '5px' }}>
                <span>Liquidaciones mensuales ambulatorias</span>{' '}
                <InputNumber
                  style={{ marginLeft: '2px' }}
                  defaultValue={moment().format('YYYY')}
                  size="small"
                  value={searchYear}
                  onChange={(value) => {
                    dispatch(onSearchValueChange({ searchYear: value }));
                  }}
                />
              </div>
            ) : (
              `Detalle ${
                type === 'socialWork'
                  ? 'por financiadores'
                  : `${billingData[0]?.socialWork}`
              }  - Periodo ${billingData[0]?.month}/${billingData[0]?.year}`
            )}
          </span>
        </Row>
      </div>

      {prestadorHeader}
      <Table
        columns={columns}
        dataSource={billingData}
        pagination={false}
        scroll={{ x: 500 }}
        loading={
          loading && {
            style: { width: '100%' },
            size: 'large',
            indicator: <LoadingOutlined />,
          }
        }
      />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'50%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
