import { basePath, apiVersion } from './config';

export async function getAuditsApi({ token, type, cashRegister }) {
  const url = `${basePath}/${apiVersion}/audits?type=${type}&cashRegister=${cashRegister}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}
