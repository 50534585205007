import React from 'react';
import { Chart } from 'react-google-charts';

export function BarChartHorizontal({
  width = '100%',
  height = '250px',
  customData, // Datos pasados directamente
  title, // Personalización del título
}) {
  return (
    <Chart
      width={width}
      height={height}
      options={{
        chart: {
          title: title || '',
          bar: { groupWidth: '40%' },
        },

        hAxis: {
          textPosition: 'none',
        },
        chartArea: {
          width: '80%', // Ajustar el área de las barras
          height: '80%', // Ajuste del área del gráfico
        },
        legend: { position: 'none' }, // Ocultar la leyenda
        tooltip: {
          isHtml: true, // Permite formatear las etiquetas emergentes
          trigger: 'focus', // Muestra el tooltip al hacer hover
          format: 'currency', // Formato de los tooltips en moneda
        },
        annotations: {
          alwaysOutside: false, // Asegura que las anotaciones estén fuera de las barras
          textStyle: {
            fontSize: 16,
            bold: true,
            color: '#000', // Color de texto de la anotación
          },
          stem: {
            color: 'none', // Quita la línea de las anotaciones
          },
        },
      }}
      chartType="BarChart"
      data={customData}
      rootProps={{ 'data-testid': '2' }}
    />
  );
}
