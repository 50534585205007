import React, { useEffect, useState } from 'react';
import { getBankAccountTransactionsApi } from '../../api/bankAccount';
import { getAccessTokenApi } from '../../api/auth';
import TransactionsTable from '../../components/CashRegister/TransactionsTable';
import Spin from '../../components/Spin';
import BackButton from '../../components/BackButton';
import { Descriptions, Tag } from 'antd';
import { useSelector } from 'react-redux';

export default function BankAccount(props) {
  const { bankAccount } = props.match.params;
  const [transactions, setTransactions] = useState([]);
  const [bankAccountDetail, setBankAccountDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTransactions, setTotalTransactions] = useState();

  const pageSize = 20;
  const { bankAccounts } = useSelector((store) => store.bankAccountData);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const token = await getAccessTokenApi();
      const response = await getBankAccountTransactionsApi({
        token,
        bankAccount,
        limit: pageSize,
        page: currentPage,
      });
      console.log('🚀 ~ getData ~ response:', response);

      setTransactions(response?.transactions);
      setTotalTransactions(response?.totalTransactions);
      setBankAccountDetail(
        bankAccounts?.find((ba) => ba?._id === bankAccount) || null
      );

      setLoading(false);
    };

    getData();
  }, [bankAccount, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) return <Spin />;

  return (
    <div>
      <BackButton />
      <Descriptions
        column={{ xs: 1, sm: 1, md: 2 }}
        title={`Banco: ${bankAccountDetail?.bankName}`}
        style={{ marginTop: '1%' }}
      >
        <Descriptions.Item label="Titular">
          {bankAccountDetail?.accountHolderName}
        </Descriptions.Item>
        <Descriptions.Item label="Nro de cuenta">
          {bankAccountDetail?.accountNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Tipo de cuenta">
          {bankAccountDetail?.accountType}
        </Descriptions.Item>
        <Descriptions.Item label="CBU">
          {bankAccountDetail?.cbu_iban}
        </Descriptions.Item>
        <Descriptions.Item label="Saldo">
          <span style={{ fontWeight: 'bold' }}>
            {bankAccountDetail?.balance.toLocaleString('es-AR', {
              style: 'currency',
              currency: 'ARS',
              minimumFractionDigits: 2,
            })}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Estado">
          <Tag
            color={
              bankAccountDetail?.accountStatus === 'activa'
                ? 'success'
                : bankAccountDetail?.accountStatus === 'suspendida'
                ? 'warning'
                : 'error'
            }
            style={{ textTransform: 'capitalize' }}
          >
            {bankAccountDetail?.accountStatus}
          </Tag>
        </Descriptions.Item>
      </Descriptions>
      <h3 style={{ textAlign: 'center' }}>Movimientos bancarios</h3>
      <TransactionsTable
        data={transactions}
        type="bank"
        onPageChange={handlePageChange}
        currentPage={currentPage}
        pageSize={pageSize}
        totalTransactions={totalTransactions}
      />
    </div>
  );
}
