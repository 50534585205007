import { basePath, apiVersion } from './config';

export async function getPdfApi(token, id, pdfName) {
  const url = `${basePath}/${apiVersion}/download/${id}&${pdfName}`;

  const params = {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/pdf',
    },
  };

  return await fetch(url, params)
    .then((response) => {
      return response;
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function uploadPdfApi(token, pdf, id, type) {
  const url = `${basePath}/${apiVersion}/upload/${id}&${type}`;

  const formData = new FormData();

  formData.append('pdf', pdf, pdf.name);

  const params = {
    method: 'PUT',
    body: formData,
    headers: {
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    return err.message;
  }
}

export function deletePdfApi(token, pdfName, id, type) {
  const url = `${basePath}/${apiVersion}/delete/${id}&${pdfName}&${type}`;

  const params = {
    method: 'PUT',
    headers: {
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getPdfSignedUrlApi({ token, id, pdfName }) {
  const url = `${basePath}/${apiVersion}/generate-signed-url?id=${id}&fileName=${pdfName}`;

  const params = {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/pdf',
    },
  };

  try {
    const response = await fetch(url, params);
    const result = await response.json();
    return result;
  } catch (err) {
    console.error('Error en getPdfApi:', err);
    return err.message;
  }
}
