import React, { useEffect, useState } from 'react';
import OpenShiftForm from '../../components/CashRegister/OpenShiftForm';
import Modal from '../../components/Modal';
import {
  Button,
  Card,
  Col,
  Row,
  Select,
  Modal as ModalAntd,
  message,
  Tooltip,
  Tag,
} from 'antd';
import {
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowUpOutlined,
  EditOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  BarChartHorizontal,
  KpiChart,
  TableChart,
} from '../../components/Charts';
import {
  closeCashRegisterShiftApi,
  getCashRegisterApi,
  getCloseCashRegisterApi,
  getOpenCashRegisterApi,
  getPaidTurnsApi,
} from '../../api/cashRegister';
import { getAccessTokenApi } from '../../api/auth';
import TransactionForm from '../../components/CashRegister/TransactionForm';
import { onSearchValueChange } from '../../actions/BillingSearchActions';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionApi } from '../../api/transaction';
import '../Phones/Phones.scss';
import moment from 'moment';
import Spin from '../../components/Spin';
import './CashRegister.scss';
import { getAuditsApi } from '../../api/audit';
import { paymentMethods } from '../../utils/constants';
import { onBankAccountRead } from '../../actions';
import { Link } from 'react-router-dom';
import TransactionsTable from '../../components/CashRegister/TransactionsTable';
import BankAccountForm from '../../components/CashRegister/BankAccountForm';
import { PdfCloseShift } from '../../components/PdfReports';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
const { Option } = Select;

export default function CashRegister() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [cashRegisters, setCashRegisters] = useState([]);
  const [cashRegisterData, setCashRegisterData] = useState();
  const [cancelPaymentData, setCancelPaymentData] = useState();
  const [historyCashRegister, setHistoryCashRegister] = useState(false);

  const [loading, setLoading] = useState();

  const dispatch = useDispatch();

  const { cashRegister } = useSelector((store) => store.searchData);

  const { bankAccounts } = useSelector((store) => store.bankAccountData);

  const { studies, tenant, tenantLogo, organization } = useSelector(
    (store) => store.tenantData
  );

  const { role, viewBillingOrganizations } = useSelector(
    (store) => store.userData.user
  );

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const token = await getAccessTokenApi();
      const response = await getOpenCashRegisterApi({ token, type: 'view' });

      setCashRegisters(response.data);

      if (cashRegister) {
        let responseCashData = await getCashRegisterApi(token, cashRegister);

        let responseCancelPaymentData = await getAuditsApi({
          token,
          type: 'CANCEL-PAYMENT',
          cashRegister,
        });

        setCancelPaymentData(responseCancelPaymentData?.audits);

        setCashRegisterData(responseCashData?.data);
      }

      setLoading(false);
    };

    !isVisibleModal && getData();
  }, [cashRegister, isVisibleModal]);

  useEffect(() => {
    !cashRegister && dispatch(onBankAccountRead('detailed'));
  }, [cashRegister]);

  const getTransactions = async (type) => {
    if (cashRegister !== null) {
      setLoading(true);
      const token = await getAccessTokenApi();

      const responseTransactionsData = await getTransactionApi(
        token,
        cashRegister
      );
      setLoading(false);
      return responseTransactionsData[type];
    }
  };

  const openCashRegisterShift = (cashRegisterData) => {
    setIsVisibleModal(true);
    setModalTitle('Arir nuevo turno de caja');
    setModalContent(
      <OpenShiftForm
        cashRegisterData={cashRegisterData}
        setIsVisibleModal={setIsVisibleModal}
      />
    );
  };

  const closeCashRegisterShift = async (cashRegisterData) => {
    let token = await getAccessTokenApi();
    const content = (
      <>
        <div
          style={{ color: '#cf1322', textAlign: 'center', marginTop: '10px' }}
        >
          {cashRegisterData.cashRegister} - Turno: {cashRegisterData.shift}
        </div>
        <div>¿ Estás seguro que quieres cerrar la caja ?</div>
      </>
    );

    const printCloseShift = async (values) => {
      message.loading('Generando documento. Por favor espere...', 0);
      const doc = (
        <PdfCloseShift
          cashRegisterData={cashRegisterData}
          tenant={tenant}
          tenantLogo={tenantLogo}
          organization={organization}
        />
      );

      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(
        blob,
        `Cierre caja ${cashRegisterData.cashRegister} - ${
          cashRegisterData.shift
        } ${moment().unix()}`
      );
      message
        .success('El documento se ha generado con exito.', 1)
        .then(() => message.destroy());
    };

    ModalAntd.confirm({
      title: 'Cerrar turno',
      content,
      okText: 'Aceptar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        try {
          const response = await closeCashRegisterShiftApi({
            token,
            id: cashRegisterData._id,
          });
          if (response.code === 200) {
            message.success(response.message);
            setCashRegisterData(null);
            printCloseShift();

            await dispatch(onSearchValueChange({ cashRegister: null }));
          } else {
            message.warning(response.message);
          }
        } catch {
          message.error('Error del servidor');
        }
      },
    });
  };

  const addNewTransaction = (type) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Cargar nuevo ${type === 'expense' ? 'egreso' : 'ingreso'} de caja`
    );
    setModalContent(
      <TransactionForm setIsVisibleModal={setIsVisibleModal} type={type} />
    );
  };

  const viewVoucherDetails = async (data, userId, sublot) => {
    const token = await getAccessTokenApi();

    const response = await getPaidTurnsApi({
      token,
      cashRegister,
      userId,
      paymentMethod: data?.method || '',
      cardType: data?.cardType || '',
    });

    if (response?.code === 200) {
      setIsVisibleModal(true);
      setModalTitle('Detalle de transacciones en turnos');
      setModalContent(
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <Button
              onClick={() => {
                viewDoctorDetail(sublot);
              }}
              type="link"
            >
              <ArrowLeftOutlined /> Atras
            </Button>
            {response?.paidTurns?.length === 0 && (
              <span>
                No hay ningun pago asociado a turnos, consulte en ingresos
              </span>
            )}
            <TableChart
              generate={response?.paidTurns}
              data={response?.paidTurns}
              sendColumns={[
                {
                  title: 'Paciente',
                  dataIndex: 'patientName',
                  key: 'patientName',
                  align: 'center',
                  render: (patientName) => (
                    <span style={{ textTransform: 'capitalize' }}>
                      {patientName}
                    </span>
                  ),
                  sorter: (a, b) => a.patientName.localeCompare(b.patientName),
                },
                {
                  title: 'Obra social',
                  dataIndex: 'socialWork',
                  key: 'socialWork',
                  align: 'center',
                  sorter: (a, b) => a.socialWork.localeCompare(b.socialWork),
                },
                {
                  title: 'Fecha de pago',
                  dataIndex: 'paymentDate',
                  key: 'paymentDate',
                  align: 'center',
                  render: (paymentDate) => (
                    <span>{moment(paymentDate).format('DD/MM HH:mm')}</span>
                  ),
                  sorter: (a, b) =>
                    moment(a.paymentDate).unix() - moment(b.paymentDate).unix(),
                },
                {
                  title: 'Forma de pago',
                  dataIndex: 'method',
                  key: 'method',
                  align: 'center',
                  render: (method, record) => (
                    <span>
                      {paymentMethods[method] || method}{' '}
                      {record?.cardType && ` - ${record.cardType}`}
                    </span>
                  ),
                  sorter: (a, b) => a.method.localeCompare(b?.method),
                },
                {
                  title: 'Monto',
                  dataIndex: 'amount',
                  key: 'amount',
                  align: 'center',
                  render: (amount) => (
                    <div>
                      <span>
                        {amount.toLocaleString('es-AR', {
                          style: 'currency',
                          currency: 'ARS',
                          minimumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  ),
                  sorter: (a, b) => a?.amount - b?.amount,
                },
                {
                  title: 'Efector',
                  dataIndex: 'doctor',
                  key: 'doctor',
                  align: 'center',
                  sorter: (a, b) => a?.doctor.localeCompare(b?.doctor),
                },
                {
                  title: 'Cuenta',
                  dataIndex: 'bankAccount',
                  key: 'bankAccount',
                  align: 'center',
                  render: (bankAccount, record) => (
                    <span>{record.method !== 'cash' && bankAccount?.name}</span>
                  ),
                  sorter: (a, b) =>
                    a?.bankAccount?.name.localeCompare(b?.bankAccount?.name),
                },
              ]}
              pageSize={20}
            />
          </Col>
        </Row>
      );
    }
  };

  const viewDoctorDetail = (sublot) => {
    setIsVisibleModal(true);
    setModalTitle(`Detalle ${sublot?.doctor}`);
    setModalContent(
      <div>
        <h4>
          Total:{' '}
          {sublot?.total?.toLocaleString('es-AR', {
            style: 'currency',
            currency: 'ARS',
            minimumFractionDigits: 2,
          })}
        </h4>
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <TableChart
              generate={sublot}
              data={sublot.payments}
              sendColumns={[
                {
                  title: 'Forma de pago',
                  dataIndex: 'method',
                  key: 'method',
                  align: 'center',
                  render: (method) => (
                    <span>{paymentMethods[method] || method}</span>
                  ),
                  sorter: (a, b) => a.method.localeCompare(b.method),
                },
                {
                  title: 'Tarjeta',
                  dataIndex: 'cardType',
                  key: 'cardType',
                  align: 'center',
                  sorter: (a, b) => a.cardType.localeCompare(b.cardType),
                },
                {
                  title: 'Monto',
                  dataIndex: 'amount',
                  key: 'amount',
                  align: 'center',
                  render: (amount) => (
                    <div>
                      <span>
                        {amount.toLocaleString('es-AR', {
                          style: 'currency',
                          currency: 'ARS',
                          minimumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  ),
                  sorter: (a, b) => a.amount - b.amount,
                },
                {
                  title: 'Acciones',
                  dataIndex: 'total',
                  render: (total, record) => (
                    <Tooltip title="Ver detalle">
                      <Button
                        type="link"
                        onClick={() =>
                          viewVoucherDetails(
                            record,
                            sublot?.doctorId || '',
                            sublot
                          )
                        }
                      >
                        <InfoCircleOutlined />
                      </Button>
                    </Tooltip>
                  ),
                  align: 'center',
                },
              ]}
              pageSize={20}
            />
          </Col>
        </Row>
      </div>
    );
  };

  const viewTransactionDetail = async (type) => {
    const data = await getTransactions(type);
    setIsVisibleModal(true);
    setModalTitle(
      `Detalle ${
        type === 'expenses' ? 'egresos' : 'ingresos por fuera de los turnos'
      }`
    );
    setModalContent(
      loading ? (
        <LoadingOutlined />
      ) : (
        <div>
          <h4>
            Total:{' '}
            {data?.totalAmount?.toLocaleString('es-AR', {
              style: 'currency',
              currency: 'ARS',
              minimumFractionDigits: 2,
            })}
          </h4>
          <TransactionsTable data={data?.transactions} type={type} />
        </div>
      )
    );
  };

  const getPaymentAmount = (method) => {
    const breakdownAmount =
      cashRegisterData?.breakdown?.find((b) => b.method === method)?.amount ||
      0;

    return Math.round(breakdownAmount);
  };

  const newBankAccount = (accountBankData = {}) => {
    setIsVisibleModal(true);
    setModalTitle(
      <div>
        <Button
          onClick={() => {
            openBankAccounts();
          }}
          type="link"
        >
          <ArrowLeftOutlined /> Atras
        </Button>
        {accountBankData?._id ? 'Editar' : 'Crear'} cuenta bancaria
      </div>
    );
    setModalContent(
      <BankAccountForm
        accountBankData={accountBankData}
        setIsVisibleModal={setIsVisibleModal}
        openBankAccounts={openBankAccounts}
      />
    );
  };

  const openBankAccounts = () => {
    setIsVisibleModal(true);
    setModalTitle(
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>Cuentas Bancarias</span>
        <Button
          type="default"
          style={{ marginRight: '25px' }}
          disabled={role !== 2}
          onClick={() => {
            newBankAccount();
          }}
        >
          Nueva cuenta
        </Button>
      </div>
    );
    setModalContent(
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <TableChart
            generate={bankAccounts}
            data={bankAccounts}
            sendColumns={[
              {
                title: 'Banco',
                dataIndex: 'bankName',
                key: 'bankName',
                align: 'center',

                sorter: (a, b) => a.bankName.localeCompare(b.bankName),
              },
              {
                title: 'Titular',
                dataIndex: 'accountHolderName',
                key: 'accountHolderName',
                align: 'center',
                sorter: (a, b) =>
                  a?.accountHolderName.localeCompare(b?.accountHolderName),
              },
              {
                title: 'Nro de cuenta',
                dataIndex: 'accountNumber',
                key: 'accountNumber',
                align: 'center',
                sorter: (a, b) =>
                  a?.accountNumber.localeCompare(b?.accountNumber),
              },
              {
                title: 'Estado',
                dataIndex: 'accountStatus',
                key: 'accountStatus',
                align: 'center',
                sorter: (a, b) =>
                  a?.accountStatus.localeCompare(b?.accountStatus),
                render: (accountStatus) => (
                  <Tag
                    color={
                      accountStatus === 'activa'
                        ? 'success'
                        : accountStatus === 'suspendida'
                        ? 'warning'
                        : 'error'
                    }
                    style={{ textTransform: 'capitalize' }}
                  >
                    {accountStatus}
                  </Tag>
                ),
              },
              {
                title: 'Saldo',
                dataIndex: 'balance',
                key: 'balance',
                align: 'center',
                render: (balance) => (
                  <div>
                    <span>
                      {balance.toLocaleString('es-AR', {
                        style: 'currency',
                        currency: 'ARS',
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </div>
                ),
                sorter: (a, b) => a.balance - b.balance,
              },
              {
                title: 'Acciones',
                dataIndex: 'total',
                render: (total, record) => (
                  <div>
                    <Tooltip title="Ver detalle">
                      <Link to={`/bank-account/${record._id}`}>
                        <Button type="link">
                          <InfoCircleOutlined />
                        </Button>
                      </Link>
                    </Tooltip>
                    <Tooltip title="Editar datos">
                      <Button
                        type="link"
                        onClick={() => {
                          newBankAccount(record);
                        }}
                      >
                        <EditOutlined />
                      </Button>
                    </Tooltip>
                  </div>
                ),
                align: 'center',
              },
            ]}
            pageSize={20}
          />
        </Col>
      </Row>
    );
  };

  const barData = [
    ['Forma de pago', 'Monto ($)', { role: 'style' }, { role: 'annotation' }],
    [
      'Efectivo',
      getPaymentAmount('cash'),
      'color: #3f8600 ; opacity: 0.6',
      getPaymentAmount('cash').toLocaleString('es-AR', {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 0,
      }),
    ],
    [
      'Transferencia',
      getPaymentAmount('transfer'),
      'color: #76A7FA; opacity: 0.6',
      getPaymentAmount('transfer').toLocaleString('es-AR', {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 0,
      }),
    ],
    [
      'Tarjeta de débito',
      getPaymentAmount('debitCard'),
      'color: #F0DE89; opacity: 0.6',
      getPaymentAmount('debitCard').toLocaleString('es-AR', {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 0,
      }),
    ],
    [
      'Tarjeta de crédito',
      getPaymentAmount('creditCard'),
      'color: #cf1322; opacity: 0.6',
      getPaymentAmount('creditCard').toLocaleString('es-AR', {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 0,
      }),
    ],
  ];

  if (loading && isVisibleModal) return <Spin />;

  const historyCashRegisters = async () => {
    const token = getAccessTokenApi();

    const response = await getCloseCashRegisterApi({
      token,
      organizations: viewBillingOrganizations,
    });

    setIsVisibleModal(true);
    setModalTitle('Ultimos 30 turnos de caja');
    setModalContent(
      <ul>
        {response?.data?.map((register) => (
          <li>
            {register.cashRegister} - Turno {register.shift} (
            {moment(register?.openTime).format('DD/MM HH:mm')} -
            {moment(register?.closeTime).format('DD/MM HH:mm')}) {'  '}
            <Tooltip title="Ver detalle">
              <Button
                type="link"
                onClick={() => {
                  dispatch(onSearchValueChange({ cashRegister: register._id }));
                  setIsVisibleModal(false);
                  setHistoryCashRegister(true);
                }}
              >
                <EyeOutlined />
              </Button>
            </Tooltip>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="phones">
      {historyCashRegister ? (
        <Button
          onClick={() => {
            dispatch(onSearchValueChange({ cashRegister: null }));
            setHistoryCashRegister(false);
          }}
          type="link"
        >
          <ArrowLeftOutlined /> Atras
        </Button>
      ) : (
        <div className="phones-header">
          <Select
            placeholder="Selecciona una turno abierto"
            allowClear
            onChange={(value) =>
              dispatch(onSearchValueChange({ cashRegister: value }))
            }
            value={cashRegister}
            notFoundContent="No hay cajas abiertas para este usuario"
          >
            {cashRegisters?.map(
              (register) =>
                viewBillingOrganizations.find(
                  (vb) => vb === register.cashRegister
                ) && (
                  <Option key={register._id} value={register._id}>
                    {register.cashRegister} - Turno {register.shift}
                  </Option>
                )
            )}
          </Select>
          {cashRegister ? (
            <div>
              <Button
                type="link"
                onClick={() => {
                  openCashRegisterShift(cashRegisterData);
                }}
              >
                Autorizar usuarios
              </Button>
              <Button
                type="text"
                style={{ backgroundColor: '#cf1322', color: ' #fff' }}
                onClick={() => {
                  closeCashRegisterShift(cashRegisterData);
                }}
              >
                Cerrar turno
              </Button>
            </div>
          ) : (
            <div>
              {role === 2 && (
                <Button
                  type="link"
                  onClick={() => {
                    historyCashRegisters();
                  }}
                >
                  Historico
                </Button>
              )}
              <Button
                type="link"
                onClick={() => {
                  openBankAccounts();
                }}
              >
                Cuentas bancaria
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  openCashRegisterShift();
                }}
              >
                Nuevo turno
              </Button>
            </div>
          )}
        </div>
      )}

      {cashRegisterData && cashRegister && (
        <div>
          <h3 style={{ textAlign: 'center' }}>
            Responsable de caja: {cashRegisterData?.responsibleName} - Apertura:{' '}
            {moment(cashRegisterData?.openTime).format('DD/MM HH:mm:ss')}
          </h3>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={8}>
              <KpiChart
                title="Ingresos"
                value={(cashRegisterData?.totalCollected || 0)?.toLocaleString(
                  'es-AR',
                  {
                    style: 'currency',
                    currency: 'ARS',
                    minimumFractionDigits: 2,
                  }
                )}
                valueStyle={{ color: '#1764AA' }}
                prefix={<ArrowUpOutlined />}
                actions={[
                  <Button
                    type="link"
                    onClick={() => addNewTransaction('revenue')}
                  >
                    Nuevo ingreso
                  </Button>,
                  <Button
                    type="link"
                    onClick={() => viewTransactionDetail('revenues')}
                  >
                    Ver ingresos
                  </Button>,
                ]}
              />
            </Col>
            <Col xs={24} md={8}>
              <KpiChart
                title="Saldo"
                value={(
                  (cashRegisterData?.totalCash || 0) +
                  (cashRegisterData?.totalCollected || 0) -
                  (cashRegisterData.totalExpenses || 0)
                )?.toLocaleString('es-AR', {
                  style: 'currency',
                  currency: 'ARS',
                  minimumFractionDigits: 2,
                })}
                valueStyle={{ color: '#3f8600' }}
                actions={[
                  <ul>
                    <li>
                      Saldo apertura caja:{' '}
                      {(cashRegisterData?.totalCash || 0)?.toLocaleString(
                        'es-AR',
                        {
                          style: 'currency',
                          currency: 'ARS',
                          minimumFractionDigits: 2,
                        }
                      )}
                    </li>
                    <li>
                      Recaudación:{' '}
                      {(cashRegisterData?.totalCollected || 0)?.toLocaleString(
                        'es-AR',
                        {
                          style: 'currency',
                          currency: 'ARS',
                          minimumFractionDigits: 2,
                        }
                      )}
                    </li>
                  </ul>,
                ]}
              />
            </Col>
            <Col xs={24} md={8}>
              <KpiChart
                title="Egresos"
                value={(
                  -1 * cashRegisterData?.totalExpenses || 0
                ).toLocaleString('es-AR', {
                  style: 'currency',
                  currency: 'ARS',
                  minimumFractionDigits: 2,
                })}
                valueStyle={{ color: '#cf1322' }}
                prefix={<ArrowDownOutlined />}
                actions={[
                  <Button
                    type="link"
                    onClick={() => addNewTransaction('expense')}
                  >
                    Nuevo egreso
                  </Button>,
                  <Button
                    type="link"
                    onClick={() => viewTransactionDetail('expenses')}
                  >
                    Ver egresos
                  </Button>,
                ]}
              />
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Card
                className="card"
                title="Ingresos por forma de cobro"
                extra={
                  <Button
                    type="link"
                    style={{ color: '#fff' }}
                    onClick={() => {
                      let data = {
                        payments: cashRegisterData.breakdown,
                        doctor: 'caja',
                        total: cashRegisterData?.totalCollected,
                      };
                      viewDoctorDetail(data);
                    }}
                  >
                    Ver detalle
                  </Button>
                }
              >
                <BarChartHorizontal customData={barData} />
              </Card>
            </Col>
          </Row>

          <TableChart
            data={cashRegisterData?.sublots}
            generate={cashRegisterData?.sublots}
            sendColumns={[
              {
                title: 'Medico',
                dataIndex: 'doctor',
                key: 'doctor',
                align: 'center',
                render: (doctor, record) => {
                  let { name } =
                    !doctor &&
                    studies?.find((studie) => studie.id === record.doctorId);
                  return <span>{doctor || name}</span>;
                },
                sorter: (a, b) => a.doctor.localeCompare(b.doctor),
              },
              {
                title: 'Monto total',
                dataIndex: 'total',
                key: 'total',
                align: 'center',
                render: (total) => (
                  <span>
                    {total.toLocaleString('es-AR', {
                      style: 'currency',
                      currency: 'ARS',
                      minimumFractionDigits: 2,
                    })}
                  </span>
                ),
                sorter: (a, b) => a.total - b.total,
              },
              {
                title: 'Acciones',
                dataIndex: 'total',
                render: (total, record) => (
                  <Button type="link" onClick={() => viewDoctorDetail(record)}>
                    Ver detalle
                  </Button>
                ),
                align: 'center',
              },
            ]}
            pageSize={20}
          />

          <h3 style={{ textAlign: 'center' }}>Anulaciones</h3>
          <TableChart
            data={cancelPaymentData}
            generate={cancelPaymentData}
            sendColumns={[
              {
                title: 'Responsable',
                dataIndex: 'user',
                key: 'user',
                align: 'center',
                sorter: (a, b) => a.user.localeCompare(b.user),
              },
              {
                title: 'Metodo / Monto',
                dataIndex: 'data',
                key: 'data',
                align: 'center',
                render: (data) => (
                  <div>
                    {data?.paymentMethods?.map((d) => (
                      <p>
                        {paymentMethods[d?.method] || d?.method} /{' '}
                        {d?.amount?.toLocaleString('es-AR', {
                          style: 'currency',
                          currency: 'ARS',
                          minimumFractionDigits: 2,
                        })}
                      </p>
                    ))}
                  </div>
                ),
              },
              {
                title: 'Motivo',
                dataIndex: 'data',
                key: 'data',
                align: 'center',
                render: (data) => <span>{data?.reason}</span>,
                sorter: (a, b) => a.user.localeCompare(b.user),
              },
            ]}
            pageSize={20}
          />
        </div>
      )}
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width="90%"
      >
        {modalContent}
      </Modal>
    </div>
  );
}
