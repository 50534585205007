import { Button, Col, message, Row, Table, Tooltip } from 'antd';
import React from 'react';
import moment from 'moment';
import { paymentMethods } from '../../utils/constants';
import { PrinterOutlined } from '@ant-design/icons';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { PdfVoucherTransaction } from '../PdfReports';
import { useSelector } from 'react-redux';

export default function TransactionsTable({
  data,
  type,
  onPageChange,
  currentPage,
  pageSize,
  totalTransactions,
}) {
  const getFilteredData = (filteredData, key) => {
    return [
      ...new Set(
        filteredData?.map((item) => {
          const keys = key.split('.');
          let value = item;
          keys.forEach((k) => {
            value = value ? value[k] : null;
          });
          return value;
        })
      ),
    ]
      .filter(Boolean)
      .map((value) => ({
        text:
          key === 'type' ? (value === 'revenue' ? 'Ingreso' : 'Egreso') : value,
        value,
      }));
  };

  const { tenant, tenantLogo, organization } = useSelector(
    (store) => store.tenantData
  );

  const printVoucher = async (values) => {
    message.loading(
      'Generando documento. Por favor espere... Esta acción puede tardar...',
      0
    );

    const doc = (
      <PdfVoucherTransaction
        transactionData={values}
        tenant={tenant}
        tenantLogo={tenantLogo}
        organization={organization}
        type={type}
      />
    );

    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Comprobante ${moment(values.date).unix()}`);
    message
      .success('El documento se ha generado con exito.', 1)
      .then(() => message.destroy());
  };

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24}>
        <Table
          dataSource={data}
          columns={[
            ...(type === 'bank'
              ? [
                  {
                    title: 'Movimiento',
                    dataIndex: 'type',
                    key: 'type',
                    align: 'center',
                    render: (type) => (
                      <strong
                        style={
                          type === 'revenue'
                            ? { color: 'green' }
                            : { color: 'red' }
                        }
                      >
                        {type === 'revenue' ? 'Ingreso' : 'Egreso'}
                      </strong>
                    ),
                    filters: getFilteredData(data, 'type'),
                    onFilter: (value, record) => record.type === value,
                  },
                ]
              : []),
            {
              title: 'Hora',
              dataIndex: 'date',
              key: 'date',
              align: 'center',
              render: (date) => (
                <span>
                  {moment(date).format(
                    type === 'bank' ? 'DD/MM/YY HH:mm' : 'DD/MM HH:mm'
                  )}
                </span>
              ),
              sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
              defaultSortOrder: 'descend',
            },
            {
              title: 'Tipo',
              dataIndex: 'transactionType',
              key: 'transactionType',
              align: 'center',
              sorter: (a, b) =>
                a.transactionType.localeCompare(b.transactionType),
            },
            {
              title: 'Forma de pago',
              dataIndex: 'method',
              key: 'method',
              align: 'center',
              render: (method) => (
                <span>{paymentMethods[method] || method}</span>
              ),
              sorter: (a, b) => a.method.localeCompare(b.method),
            },
            {
              title: 'Monto',
              dataIndex: 'amount',
              key: 'amount',
              align: 'center',
              render: (amount) => (
                <div>
                  <span>
                    {amount.toLocaleString('es-AR', {
                      style: 'currency',
                      currency: 'ARS',
                      minimumFractionDigits: 2,
                    })}
                  </span>
                </div>
              ),
              sorter: (a, b) => a.amount - b.amount,
            },
            ...(type !== 'bank'
              ? [
                  {
                    title: 'Cuenta',
                    dataIndex: 'bankAccount',
                    key: 'bankAccount',
                    align: 'center',
                    render: (bankAccount) => <span>{bankAccount?.name}</span>,
                    sorter: (a, b) =>
                      a?.bankAccount?.name.localeCompare(b?.bankAccount?.name),
                  },
                ]
              : []),
            {
              title: 'Descripción',
              dataIndex: 'description',
              key: 'description',
              align: 'center',
            },
            {
              title: 'Profesional asociado',
              dataIndex: 'linkedUser',
              key: 'linkedUser',
              align: 'center',
              sorter: (a, b) => a?.linkedUser?.localeCompare(b?.linkedUser),
            },
            ...(type !== 'bank'
              ? [
                  {
                    title: 'Responsable',
                    dataIndex: 'responsible',
                    key: 'responsible',
                    align: 'center',
                    sorter: (a, b) =>
                      a.responsible.localeCompare(b.responsible),
                  },
                  {
                    render: (data, record) => (
                      <Tooltip title="Descargar comprobante">
                        <Button
                          type="link"
                          onClick={() => printVoucher(record)}
                        >
                          <PrinterOutlined />
                        </Button>
                      </Tooltip>
                    ),
                  },
                ]
              : []),
          ]}
          pagination={{
            current: currentPage,
            pageSize,
            onChange: onPageChange,
            total: totalTransactions,
          }}
        />
      </Col>
    </Row>
  );
}
