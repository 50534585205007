import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { styleBody } from './styles';
import { PdfHeader } from './PdfHeader';
import { paymentMethods } from '../../utils/constants';

// Componente PDF para generar el comprobante
export function PdfVoucherTransaction({
  transactionData,
  tenant,
  tenantLogo,
  organization,
  type,
}) {
  return (
    <Document>
      <Page style={styleBody.body}>
        <PdfHeader
          organization={`${organization} - Comprobante`}
          tenant={tenant}
          tenantLogo={tenantLogo}
        />
        <Text style={styleBody.title}>
          Comprobante de {type === 'expenses' ? 'Egreso' : 'Ingreso'}
        </Text>
        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Fecha:</Text>
          <Text style={styleBody.text}>
            {new Date(transactionData.date).toLocaleString()}
          </Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Responsable:</Text>
          <Text style={styleBody.text}>{transactionData.responsible}</Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Tipo de Transacción:</Text>
          <Text style={styleBody.text}>{transactionData.transactionType}</Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Descripción:</Text>
          <Text style={styleBody.text}>{transactionData.description}</Text>
        </View>
        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Método de Pago:</Text>
          <Text style={styleBody.text}>
            {paymentMethods[transactionData.method]}
          </Text>
        </View>
        <View
          render={() =>
            transactionData?.bankAccount ? (
              <View style={styleBody.data}>
                <Text style={styleBody.textTitle}>Cuenta asociada:</Text>
                <Text style={styleBody.text}>
                  {transactionData.bankAccount.name}
                </Text>
              </View>
            ) : (
              ''
            )
          }
        />
        <View
          render={() =>
            transactionData?.linkedUser ? (
              <View style={styleBody.data}>
                <Text style={styleBody.textTitle}>Receptor:</Text>
                <Text style={styleBody.text}>{transactionData.linkedUser}</Text>
              </View>
            ) : (
              ''
            )
          }
        />
        <View style={styleBody.data}>
          <Text style={styleBody.textTitle}>Monto:</Text>
          <Text style={styleBody.text}>
            ${transactionData.amount.toFixed(2)}
          </Text>
        </View>

        {/* Espacio para las firmas */}
        <View style={styleBody.signatureContainer}>
          {/* Firma del responsable de generar la transacción (a la izquierda) */}
          <View style={styleBody.signatureLeft}>
            <Text style={styleBody.signatureText}>Firma Responsable</Text>
            <View style={styleBody.signatureLine}></View>
            <Text style={styleBody.signatureNote}>
              (Firma quien genera la transacción)
            </Text>
          </View>

          {/* Firma del responsable de recibir la transacción (a la derecha) */}
          <View style={styleBody.signatureRight}>
            <Text style={styleBody.signatureText}>Firma Receptor</Text>
            <View style={styleBody.signatureLine}></View>
            <Text style={styleBody.signatureNote}>
              (Firma quien recibe el monto)
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
