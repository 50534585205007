import React, { useEffect, useState } from 'react';
import { Form, Select, Button, Input, message } from 'antd';
import {
  editCashRegisterShiftApi,
  openCashRegisterShiftApi,
} from '../../api/cashRegister';
import { getAccessTokenApi } from '../../api/auth';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersActiveApi } from '../../api/user';
import { onSearchValueChange } from '../../actions/BillingSearchActions';

const { Option } = Select;

const OpenShiftForm = ({ setIsVisibleModal, cashRegisterData }) => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);

  const dispatch = useDispatch();

  const { _id, viewBillingOrganizations } = useSelector(
    (store) => store.userData?.user
  );
  const { cashRegisters } = useSelector((store) => store.tenantData);

  useEffect(() => {
    const getData = async () => {
      const token = await getAccessTokenApi();
      getUsersActiveApi(token, true).then((response) => {
        setUsers(response.users);
      });
    };

    cashRegisterData
      ? form.setFieldsValue({
          shift: cashRegisterData.shift,
          cashRegister: cashRegisterData.cashRegister,
          totalCash: cashRegisterData.totalCash,
          authorizedUsers: cashRegisterData.authorizedUsers.find(
            (a) => a !== cashRegisterData.responsible
          ),
        })
      : form.resetFields();

    getData();
  }, [cashRegisterData]);

  const handleFinish = async (values) => {
    const token = await getAccessTokenApi();
    cashRegisterData
      ? editCashRegisterShiftApi({
          token,
          data: values,
          id: cashRegisterData._id,
        }).then((response) => {
          if (response.code === 200) {
            setIsVisibleModal(false);
            message.success(response.message);
          } else {
            message.error(response.message);
          }
        })
      : openCashRegisterShiftApi({ token, data: values }).then(
          async (response) => {
            if (response.code === 200) {
              setIsVisibleModal(false);
              await dispatch(
                onSearchValueChange({ cashRegister: response.cash })
              );

              message.success(response.message);
            } else {
              message.error(response.message);
            }
          }
        );
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      <Form.Item
        label="Turno"
        name="shift"
        rules={[{ required: true, message: 'Por favor selecciona un turno' }]}
      >
        <Select placeholder="Selecciona un turno" disabled={cashRegisterData}>
          <Option value="1">Turno 1</Option>
          <Option value="2">Turno 2</Option>
          <Option value="3">Turno 3</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Caja"
        name="cashRegister"
        rules={[{ required: true, message: 'Por favor selecciona una caja' }]}
      >
        <Select placeholder="Selecciona una caja" disabled={cashRegisterData}>
          {cashRegisters?.map(
            (register) =>
              viewBillingOrganizations.find((vb) => vb === register) && (
                <Option key={register} value={register}>
                  {register}
                </Option>
              )
          )}
        </Select>
      </Form.Item>

      <Form.Item
        label="Total en efectivo"
        name="totalCash"
        rules={[
          { required: true, message: 'Por favor ingresa el total de efectivo' },
        ]}
      >
        <Input
          disabled={cashRegisterData}
          min={0}
          type="number"
          prefix="$"
          style={{ width: '100%' }}
          placeholder="Total en efectivo"
        />
      </Form.Item>

      <Form.Item label="Usuarios autorizados" name="authorizedUsers">
        <Select
          showSearch
          style={{ width: '100%' }}
          placeholder="Seleccione usaurios"
          optionFilterProp="children"
          allowClear
          mode="multiple"
          notFoundContent="No hay usaurios."
        >
          {users?.map((user, index) => {
            return (
              (cashRegisterData
                ? user._id !== cashRegisterData?.responsible
                : user._id !== _id) && (
                <Select.Option key={index} value={user._id}>
                  {user.name} {user.lastname}
                </Select.Option>
              )
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
          {cashRegisterData ? 'Autorizar usuarios' : 'Abrir Turno de Caja'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OpenShiftForm;
